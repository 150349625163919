.filter {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease;

    &.-active {
        max-height: none;
        overflow: visible;
        padding-bottom: 1rem;
    }

    @media (--screen-jumbotron) {
        margin-bottom: 3rem;
        max-height: none;
        overflow: visible;
    }
}

.filter__toggle {
    margin-bottom: 2rem;
    margin-right: 2rem;

    @media (--screen-jumbotron) {
        display: none;
    }
}

.filter__checkbox {
    display: none;

    &:checked + .dropdown__link {
        background-color: var(--color-purple);
        color: var(--color-general);
    }
}

.filter__entry {
    margin: 0 1rem 1rem 0;
    white-space: nowrap;
    display: inline-block;

    @media (--screen-medium-large) {
        margin: 0 1.5rem 1.5rem 0;
    }

    @media (--screen-mini-jumbotron) {
        &.-ja {
            order: 3;
        }

        &.-free {
            order: 3;
        }

        &.-genre {
            order: 1;
            margin-right: 1rem !important;
        }

        &.-month {
            order: 2;
        }
    }

    &:last-child {
        margin-right: 0;
    }
}

.filter__col {
    margin: 0 0 1rem 0;

    @media (--screen-mini-jumbotron) {
        display: flex;
        flex-wrap: wrap;
    }

    @media (--screen-medium-large) {
        margin: 0 2rem 2rem 0;
    }

    &.-auto {
        flex-basis: 100%;
        order: -1;

        @media (--screen-jumbotron) {
            position: absolute;
            right: 0;
            order: 2;
            margin-right: 0;
        }
    }
}

.filter__options {
    clear: both;
    width: 100%;

    @media (--screen-mini-jumbotron) {
        .filter__col & {
            display: none;
        }
    }

    &.-bottom {
        margin-bottom: 2rem;

        @media (--screen-jumbotron) {
            display: none;
        }
    }
}

.filter__label {
    display: inline-flex;
    background: var(--color-general);
    border: 0;
    color: var(--color-purple);
    font-size: 1.8rem;
    line-height: 1.5;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    border-radius: 3rem;
    padding: 1.5rem 2rem;
    text-decoration: none;
    transition: all .3s ease;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    align-items: center;

    &.-switch {
        padding: 0;
    }

    .icon.-arrow-down {
        margin-left: 1rem;
    }

    .icon.-heart {
        margin-right: .6rem;
    }

    .filter__label__on, .filter__label__off {
        padding: 1.5rem;
        border-radius: 3rem;
        transition: all .3s ease;

        @media (--screen-medium-large) {
            padding: 1.5rem 2rem;
        }
    }

    .filter__label__off {
        background: var(--color-purple);
        color: var(--color-general);

        &:hover {
            background: var(--color-purple-hover);
        }
    }

    .filter__checkbox:checked + & {
        background: var(--color-purple);
        color: var(--color-general);

        &.-switch {
            background: var(--color-general);
            color: var(--color-purple);

            .filter__label__on {
                background: var(--color-purple);
                color: var(--color-general);

                &:hover {
                    background: var(--color-purple-hover);
                }
            }

            .filter__label__off {
                background: var(--color-general);
                color: var(--color-purple);

                &:hover {
                    background: var(--color-general-hover);
                }
            }
        }
    }

    &:hover, &:focus, .dropdown__trigger:hover & {
        background: var(--color-general-hover);
    }
}

.filter__link {
    display: inline-block;
    padding: 0;
    margin: 0 1.5rem 0 0;
    color: var(--color-purple);
    border: 0;
    background: none;
    text-decoration: none;
    transition: opacity .3s ease;
    cursor: pointer;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    line-height: 1.5;
    font-size: 1.6rem;
    opacity: .4;

    &.-all {
        opacity: 1;

        .filter__options & {
            display: none;

            @media (--screen-jumbotron) {
                display: inline-block;
            }
        }
    }

    &.-mobile {
        @media (--screen-jumbotron) {
            display: none;
        }
    }

    &:hover, &:focus {
        opacity: 1;

        &.-all {
            opacity: .55;
        }
    }

    .icon {
        margin-right: .3rem;
    }
}
