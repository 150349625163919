.page-search {
    background-color: var(--color-purple);
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9;
    box-sizing: border-box;
    max-height: 0;
    overflow: hidden;
    transition: all .3s ease;
    transform: translateY(-40rem);

    &::after {
        z-index: -1;
        content: '';
        background-color: var(--color-purple);
        width: 100%;
        height: 200vh;
        opacity: .3;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &.-active, &.-inline {
        height: auto;
        transform: translateY(0);
        max-height: 40rem;
        overflow: visible;
    }

    &.-inline {
        position: relative;
        z-index: 2;
        background: none;

        &:after {
            display: none;
        }
    }

    .site-container {
        position: relative;
    }
}

.page-search__container, .page-search__container.site-container {
    max-width: 52rem;
    margin: 0 auto;
    padding: 10rem 0;

    .page-search.-inline & {
        margin: 0 auto;
        padding: 0 0 10rem;
    }
}

.page-search__title {
    display: block;
    font-size: 2.1rem;
    font-family: var(--font-general);
    color: var(--color-general);
    text-align: center;
    margin-bottom: 3rem;

    @media (--screen-large) {
        font-size: 3.5rem;
    }

    .page-search.-inline & {
        display: none;
    }
}

.page-search__button {
    width: 5rem;
    height: 5rem;
    border: .1rem solid var(--color-general);
    border-radius: 50%;
    background: none;
    outline: none;
    cursor: pointer;
    transition: background-color .3s ease, opacity .3s ease;

    &::before {
        background-color: var(--color-general) !important;
    }

    &:hover,
    &:focus {
        background-color: var(--color-general);

        &::before {
            background: var(--color-purple) !important;

            .page-search.-inline & {
                background-color: var(--color-general) !important;
            }
        }
    }

    .page-search.-inline & {
        background-color: var(--color-purple);

        &:hover,
        &:focus {
            opacity: .6;
        }
    }
}


.page-search__close {
    display: block;
    border: 0;
    padding: 0;
    background: none;
    font-size: 1.8rem;
    color: var(--color-general);
    font-family: var(--font-general);
    cursor: pointer;
    transition: opacity .3s ease;
    opacity: .6;
    position: absolute;
    top: 3rem;
    right: var(--side-padding);
    outline: none;
    height: 2.2rem;

    &::before {
        background: var(--color-general) !important;
        margin-right: 1.2rem;
    }

    &:hover,
    &:focus {
        opacity: 1;

        &::before {
            background: var(--color-general) !important;
        }
    }

    .main & {
        display: none;
    }
}

.page-search__autocomplete {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0;
    background: var(--color-purple);
    transition: all .3s ease;

    ul {
        display: block;
        max-width: 52rem;
        margin: 2rem auto !important;
        padding: 0 var(--side-padding);
        box-sizing: border-box;

        li {
            margin: 0;
            padding: .5rem 0;
            text-align: left;
            list-style: none;

            &::before {
                display: none;
            }
        }
    }

    a {
        font-size: 1.8rem;
        color: var(--color-general);
        font-family: var(--font-general);
        text-decoration: none;
        transition: all .3s ease;

        &:hover, &:focus {
            opacity: .6;
        }
    }

    .page-search.-inline & {
        border: .1rem dotted var(--color-border-dark);
        background: var(--color-white);

        a {
            color: var(--color-purple);
        }
    }
}
