figcaption {
    margin-block-start: .8rem;
    font-size: 1.1rem;
    line-height: 1.6;
    color: var(--color-purple);
}

@media (--screen-from-sm) {
    figcaption {
        margin-block-start: 1rem;
        font-size: 1.2rem;
    }
}
