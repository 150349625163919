/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --color-white:              #FFFFFF;
    --color-white-hover:        rgba(255, 255, 255, .4);
    --color-black:              #000000;

    --color-purple:             #300029;
    --color-purple-hover:       rgba(48, 0, 41, .75);
    --color-general:            #FFEDED;
    --color-general-hover:      #FFF2F3;
    --color-general-bg:         rgba(255, 237, 237, .25);
    --color-well-informed:      #C1D0D6;
    --color-well-informed-bg:   rgba(193, 208, 214, .25);
    --color-family:             #FF796E;
    --color-family-bg:          rgba(255, 121, 110, .25);
    --color-fun:                #E0EA18;
    --color-fun-bg:             rgba(224, 234, 24, .25);
    --color-rebellious:         #EA2EDA;
    --color-rebellious-bg:      rgba(234, 46, 218, .25);
    --color-border:             rgba(255, 237, 237, .4);
    --color-border-dark:        rgba(48, 0, 41, .4);
    --color-submenu:            #D6BDBD;
    --color-infocard:           #FFF8F8;
    --color-error:              #EA2EDA;
    --color-facebook:           #1877F2;
    --color-spotify:            #1DB954;
    --color-sticky-purple:      #7F8AFE;
    --color-sticky-green:       #01FF6B;
    --color-title:              #A59CAD;
    --color-faq:                #968092;

    --font-general:             'Graebenbach';
    --font-well-informed:       'Souvenir';
    --font-family:              'Spoof';
    --font-fun:                 'MediaSans';
    --font-rebellious:          'Harbour';

    --font-weight-thin:         100;
    --font-weight-extra-light:  200;
    --font-weight-light:        300;
    --font-weight-regular:      400;
    --font-weight-medium:       500;
    --font-weight-semi-bold:    600;
    --font-weight-bold:         700;
    --font-weight-extra-bold:   800;
    --font-weight-black:        900;

    --side-padding:             2rem;
}

@custom-media --screen-all          (width >= 1440px);
@custom-media --screen-full         (width >= 1280px);
@custom-media --screen-jumbotron    (width >= 1024px);
@custom-media --screen-large        (width >= 850px);

@custom-media --screen-medium-large (width >= 630px);
@custom-media --screen-medium       (width >= 630px) and (width < 850px);

@custom-media --screen-small-large  (width >= 420px);
@custom-media --screen-small-medium (width >= 420px) and (width < 850px);
@custom-media --screen-small        (width >= 420px) and (width < 630px);

@custom-media --screen-mini-jumbotron   (width < 1024px);
@custom-media --screen-mini-medium      (width < 850px);
@custom-media --screen-mini-small       (width < 630px);
@custom-media --screen-mini             (width < 420px);

@custom-selector :--focus :hover, :focus, :active;

$breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

@each $minName, $minSize in $breakpoints {
    @custom-media --screen-from-#{$minName} (width >= #{$minSize});
    @custom-media --screen-up-to-#{$minName} (width < #{$minSize});

    @each $maxName, $maxSize in $breakpoints {
        @custom-media --screen-#{$minName}-#{$maxName} (width >= #{$minSize}) and (width < #{$maxSize});
    }
}
