.slide {
    position: relative;

    display: block;

    aspect-ratio: 16 / 9;
    inline-size: 100%;
    min-block-size: 50rem;
    max-block-size: calc(100vh - 8rem);

    list-style: none;
}

.splide__track {
    flex: 1;
}

.slide__image {
    display: block;

    inline-size: 100%;
    block-size: 100%;
    max-height: 100% !important;

    object-fit: cover;
    object-position: top;
}

@media (--screen-from-md) {
    .slide {
        // only in pagebuilder
        [data-gjs-type='wrapper'] & {
            max-inline-size: 50%;
        }
    }
}
