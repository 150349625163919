.page-footer {
    flex-shrink: 0;
    color: var(--color-general);
    font-size: 1.8rem;
    line-height: 1.5;
    z-index: 1;
}

.page-footer__social {
    background-color: var(--color-general);
    padding: 4rem 0;
    text-align: center;

    &.-family {
        background-color: var(--color-family);
    }

    &.-fun {
        background-color: var(--color-fun);
    }

    &.-rebellious {
        background-color: var(--color-rebellious);
    }

    &.-well-informed {
        background-color: var(--color-well-informed);
    }
}

.page-footer__social-item {
    margin: 1.5rem;
    transition: opacity .3s ease;

    @media (--screen-small-large) {
        margin: 2rem;
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover,
    &:focus {
        opacity: .6;
    }

}

.page-footer__top {
    background-color: var(--color-purple);
    border-bottom: .1rem solid var(--color-border);
    padding: 8rem 0;
    text-align: center;

    @media (--screen-large) {
        text-align: left;
    }

    .site-container {
        @media (--screen-large) {
            display: flex;
            justify-content: center;
        }
    }

    .logo {
        @media (--screen-large) {
            flex-shrink: 0;
            margin-right: 4.5rem;
        }

        @media (--screen-full) {
            margin-right: 14.5rem;
        }
    }
}

.page-footer__newsletter {
    font-size: 2.1rem;
    margin-top: 3.7rem;
    flex-basis: 32.5rem;
}

.page-footer__newsletter__input {
    position: relative;
    margin: 1.5rem auto 1rem;
    max-width: 32rem;

    input {
        padding-right: 5rem;
    }
}

.page-footer__newsletter__button {
    position: absolute;
    top: 1.2rem;
    right: 0;
    width: 3rem;
    height: 3rem;
    border: .1rem solid var(--color-general);
    border-radius: 50%;
    background: var(--color-purple);
    outline: none;
    cursor: pointer;
    transition: background-color .3s ease;

    &::before {
        background-color: var(--color-general) !important;
        width: 1.3rem !important;
        height: 1.3rem !important;
        background-size: 1.3rem 1.3rem !important;

        @supports (mask-repeat: no-repeat) {
            mask-size: 1.3rem 1.3rem !important;
        }
    }

    &:hover,
    &:focus {
        background-color: var(--color-general);

        &::before {
            background: var(--color-purple) !important;
        }
    }
}

.page-footer__newsletter__info {
    font-size: 1.4rem;
    display: inline-block;

    a {
        color: var(--color-general);
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &.-success {
        color: var(--color-rebellious);
    }
}

.page-footer__partners {
    background-color: var(--color-purple);
    border-bottom: .1rem solid var(--color-border);
    padding: 1.5rem 0;
}

.page-footer__credits {
    background-color: var(--color-purple);
    padding: 1.5rem 0;
    font-size: 1.4rem;
    color: var(--color-general);
}

.page-footer__credits__list {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
        padding: .5rem 1.5rem;
        display: inline-block;
    }
}

.page-footer__credits-item {
    opacity: .6;
    color: var(--color-general);
    text-decoration: none;
    transition: opacity .3s ease;

    img {
        vertical-align: middle;
        margin-left: .3rem;
        inline-size: revert-layer;
        block-size: revert-layer;
    }

    &.-inventis img {
        margin-top: -.4rem;
    }

    &:hover,
    &:focus {
        opacity: 1;
    }
}
