.dropdown {
    margin: 0;
    padding: 0;
    list-style: none;

    .page-header &, &.-inline {
        display: inline-block;
    }

    .content-cards__container & {
        margin-bottom: 3rem;
    }

    .filter__entry & {
        margin: 0;
    }

    &.-language {
        text-transform: uppercase;
        margin: 0 1rem 0 1.5rem;
        display: none;

        @media (--screen-large) {
            display: inline-block;
        }
    }
}

.dropdown__panel {
    position: absolute;
    background: var(--color-white);
    border-radius: .5rem;
    margin: 0;
    padding: 1rem 0;
    list-style: none;
    min-width: 15rem;
    max-width: 26rem;
    max-height: 0;
    overflow: hidden;
    left: 0;
    top: 0;
    opacity: 0;
    transition: opacity .3s ease, max-height .3s ease;
    z-index: -1;
    border: .1rem solid var(--color-border);
    transform: translateY(3rem);

    .page-header & {
        right: 0;
        left: auto;

        @media (--screen-all) {
            left: 0;
        }
    }

    @media (--screen-all) {
        left: 0;
    }

    &.-button {
        transform: translateY(6rem);
    }

    .-language & {
        min-width: 7rem;
    }

    li.-hidden {
        display: none;
    }

    &.-open {
        @media (any-hover: none), (hover: none), (hover: hover) and (pointer: coarse) {
            opacity: 1;
            z-index: 3;
            max-height: none;
        }
    }
}

.dropdown__trigger {
    position: relative;
    cursor: pointer;

    &:after {
        content: '';
        width: 100%;
        height: 3rem;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    a {
        .page-header & {
            margin-right: 0;
            padding-right: 0;
        }
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            &:after {
                height: 6rem;
            }

            .dropdown__panel {
                opacity: 1;
                z-index: 3;
                max-height: none;
            }
        }
    }

    .icon::before {
        margin-left: .4rem;
    }
}

.dropdown__link {
    display: block;
    padding: 1rem 1.5rem;
    text-decoration: none;
    font-size: 1.6rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);
    cursor: pointer;

    &:hover,
    &:focus {
        background: var(--color-general);
    }
}

.dropdown__group {
    background: var(--color-purple);
    color: var(--color-general);
    font-size: 1.6rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    text-align: center;
    padding: 1rem 1.5rem;
    cursor: default;
}
