.jumbotron__container {
    position: relative;

    @media (--screen-mini-jumbotron) {
        background-color: var(--color-purple);
    }

    @media (--screen-jumbotron) {
        margin-bottom: 12rem;
    }
}

.jumbotron__bg {
    background-color: var(--color-general);
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color .3s ease;

    @media (--screen-mini-jumbotron) {
        max-height: 73rem;
    }

    &::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: -100rem;
        left: 50%;
        margin-left: -250rem;
        width: 500rem;
        height: 200rem;
        background-image: url('../images/general.png');
        background-size: 500rem 200rem;
        animation-duration: 12s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: center;
        will-change: transform;
        transform: translateZ(0);
        transition: background-image .3s ease;
        background-position: center center;
        background-repeat: no-repeat;
        animation-name: scale;
    }

    .jumbotron__container.-fun & {
        background-color: var(--color-fun);

        &::after {
            background-image: url('../images/fun.png');
        }
    }

    .jumbotron__container.-family & {
        background-color: var(--color-family);

        &::after {
            background-image: url('../images/family.png');
        }
    }

    .jumbotron__container.-rebellious & {
        background-color: var(--color-rebellious);

        &::after {
            background-image: url('../images/rebellious.png');
        }
    }

    .jumbotron__container.-well-informed & {
        background-color: var(--color-well-informed);

        &::after {
            background-image: url('../images/well-informed.png');
        }
    }
}

@keyframes scale {
    from {
        transform: scale(.75);
    }
    to {
        transform: scale(.85);
    }
}

.jumbotron {
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: column;

    @media (--screen-jumbotron) {
        flex-direction: row;
        max-height: 64rem;
    }
}

.jumbotron__events {
    margin-bottom: 2rem;

    @media (--screen-jumbotron) {
        margin: 0 0 -9rem 0;
        width: calc(100% - 37rem);
        max-width: 87rem;
    }

    .splide__track {
        height: 100%;
    }
}

.jumbotron__events__list {
    margin: 0;
    height: 100% !important;
    background-color: var(--color-general-bg);

    .jumbotron__container.-fun & {
        background-color: var(--color-fun);
    }

    .jumbotron__container.-family & {
        background-color: var(--color-family);
    }

    .jumbotron__container.-rebellious & {
        background-color: var(--color-rebellious);
    }

    .jumbotron__container.-well-informed & {
        background-color: var(--color-well-informed);
    }
}

.jumbotron__event {
    font: 0/0 sans-serif;
    display: flex;
    flex-direction: column;
    background-color: var(--color-purple);
    position: relative;
    text-decoration: none;
    height: 100%;
    transition: background-color .3s ease;

    &:hover,
    &:focus {
        img {
            opacity: .6;
        }
    }
}

.jumbotron__event__img {
    flex: 1 1;
    display: block;
    position: relative;
    min-height: 35rem;

    @media (--screen-jumbotron) {
        min-height: auto;
    }

    img {
        width: 100%;
        height: 100%;
        transition: opacity .3s ease;
        position: absolute;
        object-fit: cover;
        min-height: 35rem;
    }
}

.jumbotron__event__info {
    color: var(--color-infocard);
    font-size: 2.2rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    flex-basis: 100%;
    width: 100%;
    box-sizing: border-box;
    transition: opacity .3s ease;
    background-color: var(--color-purple);

    @media (--screen-jumbotron) {
        padding-bottom: 9rem;
        flex: 0 1;
    }

    .jumbotron__event:hover &,
    .jumbotron__event:focus & {
        opacity: .6;
    }
}

.jumbotron__event__title {
    font-size: 4.2rem;
    line-height: 1.15;
    display: block;
    padding: 2rem;
    white-space: normal;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-general);
    max-width: 76rem;

    .jumbotron__event.-fun & {
        font-family: var(--font-fun);
        color: var(--color-fun);
        letter-spacing: .05rem;
    }

    .jumbotron__event.-family & {
        font-family: var(--font-family);
        color: var(--color-family);
        letter-spacing: -.3rem;
    }

    .jumbotron__event.-rebellious & {
        font-family: var(--font-rebellious);
        color: var(--color-rebellious);
        letter-spacing: -.4rem;
    }

    .jumbotron__event.-well-informed & {
        font-family: var(--font-well-informed);
        color: var(--color-well-informed);
    }

    @media (--screen-jumbotron) {
        padding: 3rem;
        font-size: 6.4rem;
    }

    em {
        display: block;
        font-style: normal;
        font-family: var(--font-general);
        font-weight: var(--font-weight-regular);
        font-size: 2.8rem;
        padding-top: 1rem;
        letter-spacing: 0;
    }
}

.jumbotron__event__date {
    display: block;
    height: 9rem;
    line-height: 9rem;
    box-sizing: border-box;
    padding: 0 2rem;
    font-size: 2.2rem;
    color: var(--color-general);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    position: relative;
    text-transform: uppercase;

    .jumbotron__event.-fun & {
        color: var(--color-fun);
    }

    .jumbotron__event.-family & {
        color: var(--color-family);
    }

    .jumbotron__event.-rebellious & {
        color: var(--color-rebellious);
    }

    .jumbotron__event.-well-informed & {
        color: var(--color-well-informed);
    }

    @media (--screen-jumbotron) {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 3rem;
    }
}

.jumbotron__event__tickets {
    display: block;
    height: 9rem;
    line-height: 9rem;
    box-sizing: border-box;
    padding: 0 2rem;
    background-color: var(--color-general);
    color: var(--color-purple);
    font-size: 2.2rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    position: relative;
    transition: background-color .3s ease;

    .jumbotron__event.-fun & {
        background-color: var(--color-fun);
    }

    .jumbotron__event.-family & {
        background-color: var(--color-family);
    }

    .jumbotron__event.-rebellious & {
        background-color: var(--color-rebellious);
    }

    .jumbotron__event.-well-informed & {
        background-color: var(--color-well-informed);
    }

    @media (--screen-jumbotron) {
        font-size: 3.2rem;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0 5rem;
    }

    .icon {
        @media (--screen-mini-medium) {
            position: absolute;
            right: 2rem;
            top: 3.5rem;
        }
    }
}

.jumbotron__aside {
    font: 0/0 sans-serif;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (--screen-medium-large) {
        flex-direction: row;
    }

    @media (--screen-jumbotron) {
        min-width: 32rem;
        max-width: 37rem;
        flex-direction: column;
    }

    @media (--screen-full) {
        min-width: 37rem;
    }

    @media (--screen-mini-jumbotron) {
        padding-bottom: 2rem;
    }

    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(48, 0, 41, 0.3), rgba(48, 0, 41, 0.3));
        z-index: 1;
    }
}

.jumbotron__aside__item {
    flex-basis: 50%;
    margin: 0 0 2rem 0;
    position: relative;
    background-color: var(--color-purple);

    @media (--screen-medium-large) {
        margin: 0 0 2rem 2rem;

        &:first-child {
            margin-left: 0;
        }
    }

    @media (--screen-jumbotron) {
        margin: 0;
        max-height: 32rem;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity .3s ease;
        min-height: 28rem;
    }

    &:hover,
    &:focus {
        img {
            opacity: .6;
        }
    }
}

.jumbotron__aside__item__info {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    padding: 3rem;
    transition: opacity .3s ease;

    .jumbotron__aside__item:hover &,
    .jumbotron__aside__item:focus & {
        opacity: .6;
    }
}

.jumbotron__aside__item__title {
    display: block;
    font-size: 3.5rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    line-height: 1.15;
    color: var(--color-general);
    padding: 2rem 0;
    word-break: break-word;

    &.-small {
        font-size: 2.8rem;
    }

    &.-smaller {
        font-size: 2.1rem;
    }
}

.jumbotron__aside__item__extra {
    display: block;
    font-size: 1.6rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    line-height: 1.15;
    color: var(--color-general);

    &.-upper {
        text-transform: uppercase;
    }
}

.jumbotron__arrows {
    position: absolute;
    top: 2rem;
    left: 2rem;
    display: none;

    @media (--screen-jumbotron) {
        display: block;
    }
}

.jumbotron__arrows .jumbotron__arrow {
    background: var(--color-purple);
    border-radius: 50%;
    width: 4.2rem;
    height: 4.2rem;
    display: inline-block;
    cursor: pointer;
    transition: opacity .3s ease;
    outline: none;
    border: 0;
    opacity: 1;
    position: static;
    transform: translate(0);
    margin-right: .3rem;

    &:--focus {
        opacity: .5 !important;
        background: var(--color-purple);
    }

    &::before {
        display: none;
    }
}

.splide__arrows.-hide {
    display: none;
}
