.text-over-image {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    block-size: 72rem;
    margin-block: 1rem;
    padding: 2rem;

    [data-alignment='left'] & {
        justify-content: left;
    }

    [data-alignment='right'] & {
        justify-content: right;
    }
}

.text-over-image__image {
    position: absolute;
    inset: 0;

    img {
        display: block;
        inline-size: 100%;
        block-size: 100%;
        object-fit: cover;
    }
}

.text-over-image__content {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    max-inline-size: 90%;

    [data-alignment='left'] & {
        align-items: flex-start;
    }

    [data-alignment='right'] & {
        align-items: flex-end;
    }
}

.text-over-image__subtitle {
    margin-block-end: 2.6rem;

    font-size: 1.8rem;
    font-weight: var(--font-weight-normal);
    line-height: 1.55;
    text-align: center;

    [data-alignment='left'] & {
        text-align: start;
    }

    [data-alignment='right'] & {
        text-align: end;
    }
}

.text-over-image__title {
    margin: 0;
    font-size: 4.6rem;
    line-height: 1.15;
    text-align: center;

    [data-alignment='left'] & {
        text-align: start;
    }

    [data-alignment='right'] & {
        text-align: end;
    }
}

.text-over-image__description {
    margin-block-start: 2.6rem;
    text-align: center;

    [data-alignment='left'] & {
        text-align: start;
    }

    [data-alignment='right'] & {
        text-align: end;
    }
}

.text-over-image__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;

    max-inline-size: 100%;
    margin-block-start: 2.6rem;

    [data-alignment='left'] & {
        justify-content: left;
    }

    [data-alignment='right'] & {
        justify-content: right;
    }
}

@media (--screen-from-sm) {
    .text-over-image {
        padding: 6rem;
    }
}

@media (--screen-from-md) {
    .text-over-image {
        padding: 7rem 14rem;
    }
}
