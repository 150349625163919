.schedule {
    background-color: var(--color-purple);
    display: block;
    margin: 0 auto;

    width: 100%;
    height: 100%;

    padding: 10vh 0 0 0;
    box-sizing: border-box;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    &.-family::after {
        background-image: url('../../images/schedule-family.png');
        background-size: cover;
    }

    &.-fun::after {
        background-image: url('../../images/schedule-fun.png');
        background-size: cover;
    }

    &.-rebellious::after {
        background-image: url('../../images/schedule-rebellious.png');
        background-size: cover;
    }

    &.-well-informed::after {
        background-image: url('../../images/schedule-wellinformed.png');
        background-size: cover;
    }

    .carrousel {
        background: var(--color-purple);
    }
}

.schedule__masthead {
    text-align: center;
    padding: 2.5rem;
    height: 10%;
    box-sizing: border-box;
    overflow: hidden;

    p {
        margin-top: 0;
    }

    h1 {
        margin: 0;
        font-family: var(--font-general);
        font-weight: var(--font-weight-regular);
        font-size: 7rem;
        color: var(--color-purple);
        text-align: center;
        position: relative;
        display: inline-block;

        .schedule.-family & {
            font-family: var(--font-family);
            color: var(--color-family);
            letter-spacing: -.3rem;
        }

        .schedule.-fun & {
            font-family: var(--font-fun);
            color: var(--color-fun);
            letter-spacing: .05rem;
        }

        .schedule.-rebellious & {
            font-family: var(--font-rebellious);
            color: var(--color-rebellious);
            letter-spacing: -.4rem;
        }

        .schedule.-well-informed & {
            font-family: var(--font-well-informed);
            color: var(--color-well-informed);
        }
    }
}

.schedule__carrousel {
    height: 99%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .carrousel {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.schedule-item {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    width: 74%;
    margin: 0 0 0 20rem;
    padding: 2.5rem 0;
    overflow: hidden;
    border-bottom: .1rem dotted var(--color-border-dark);

    .schedule.-family & {
        border-bottom: .7rem dotted var(--color-family);
    }

    .schedule.-fun & {
        border-bottom: .7rem dotted var(--color-fun);
    }

    .schedule.-rebellious & {
        border-bottom: .7rem dotted var(--color-rebellious);
    }

    .schedule.-well-informed & {
        border-bottom: .7rem dotted var(--color-well-informed);
    }


    &:last-child {
        border: 0;
    }
}

.schedule-item__content {
    float: left;
    width: 74%;

    box-sizing: border-box;
    font-size: 0;
    line-height: 1;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.schedule-item__title {
    margin: 0;
    display: inline;
    font-family: var(--font-general);
    font-size: 5rem;
    line-height: 1;

    font-weight: var(--font-weight-regular);

    .schedule.-family & {
        color: var(--color-family);
    }

    .schedule.-fun & {
        color: var(--color-fun);
    }

    .schedule.-rebellious & {
        color: var(--color-rebellious);
    }

    .schedule.-well-informed & {
        color: var(--color-well-informed);
    }
}

.schedule-item__time {
    float: left;
    width: 15%;
    line-height: 1;

    text-align: left;
    box-sizing: border-box;

    font-family: var(--font-general);
    font-size: 5rem;
    font-weight: var(--font-weight-regular);
    color: var(--color-black);

    display: flex;
    flex-direction: column;
    justify-content: center;

    .schedule.-family & {
        color: var(--color-family);
    }

    .schedule.-fun & {
        color: var(--color-fun);
    }

    .schedule.-rebellious & {
        color: var(--color-rebellious);
    }

    .schedule.-well-informed & {
        color: var(--color-well-informed);
    }
}
