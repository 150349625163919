.content-cards__container {
    padding: 4rem 0;

    &.-purple {
        background: var(--color-purple);

        @media (--screen-large) {
            padding: 10rem 0;
        }
    }

    .site-container {
        position: relative;
    }

    &.-np {
        padding-top: 0;
    }
}

.content-cards__title {
    color: var(--color-purple);
    font-size: 2.8rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    display: block;
    margin-bottom: 1rem;

    .content-cards__container & {
        margin-bottom: 3rem;
    }

    .content-cards__container.-full & {
        padding-left: var(--side-padding);

        @media (--screen-large) {
            padding-left: 0;
        }
    }

    .content-cards__container.-purple & {
        color: var(--color-general);
    }

    @media (--screen-large) {
        font-size: 3.5rem;
        padding: 0;
    }
}

.content-card__list {
    &:not(.splide__list) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    margin: 0;
    padding: 0;
    list-style: none;

    @media (--screen-large) {
        margin: 0 -1.5rem;
    }

    li:not(.splide__slide) {
        margin: 1.5rem;
        flex-basis: 100%;

        @media (--screen-medium) {
            flex-basis: calc(50% - 3rem);
        }

        @media (--screen-large) {
            flex-basis: calc(100% / 3 - 3rem);
        }
    }

    &.-rows {
        flex-direction: column;
        margin: 0 0 3rem 0;

        @media (--screen-large) {
            margin: 0;
        }

        + .content-card__list.-overview {
            margin-top: 2rem;
        }
    }

    &.splide__list {
        @media (--screen-large) {
            margin-bottom: -3rem !important;
        }
    }
}

.content-card__list.splide__list {
    @media (--screen-large) {
        display: flex !important;
        flex-direction: row;
        padding: 0;
        gap: 3rem;
        list-style: none;
        margin: 0 -1.5rem;
        margin-bottom: -3rem !important;
    }

    li {
        @media (--screen-large) {
            flex-basis: calc(100% / 3 - 2rem);
        }
    }
}

.content-card__list__row, .content-card__list li.content-card__list__row {
    padding: 3rem 0;
    border-bottom: .1rem dotted var(--color-border-dark);
    margin: 0;
    width: 100%;

    &:first-child {
        border-top: .1rem dotted var(--color-border-dark);
    }

    .content-cards__container.-purple & {
        border-bottom: .1rem dotted var(--color-border);

        &:first-child {
            border-top: .1rem dotted var(--color-border);
        }
    }
}

.content-card {
    display: block;
    overflow: hidden;
    text-decoration: none;
    margin: 0 auto;
    padding: 0;
    max-width: 39rem;

    @media (--screen-large) {
        max-width: 100%;

        .splide__slide & {
            padding: 1.5rem 0;
        }
    }

    .-rows & {
        padding: 0;
        max-width: 45rem;

        @media (--screen-large) {
            max-width: 100%;
            display: flex;
        }
    }
}

.content-card__media {
    position: relative;
    flex-basis: 30%;
    min-height: 5rem;

    @media (--screen-all) {
        flex-basis: 35%;
        min-height: 6rem;
    }

    .-rows & {
        max-width: 45rem;

        @media (--screen-large) {
            max-width: 100%;
        }
    }
}

.content-card__info {
    position: relative;
    padding: 1.5rem 2rem;
    transition: opacity .3s ease;
    flex-basis: 70%;
    box-sizing: border-box;

    @media (--screen-all) {
        flex-basis: 65%;
    }

    .-rows & {
        padding: 1.5rem 0 0 0;

        @media (--screen-large) {
            padding: 0 0 0 3rem;
        }
    }

    :last-child {
        margin-bottom: 0;
    }

    .-my-tickets & {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.content-card__like {
    display: block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: var(--color-purple);
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    text-decoration: none;
    text-align: center;
    line-height: 5rem;
    transition: opacity .3s ease;

    .icon::before {
        background: var(--color-general);
    }

    &.-liked {
        .icon::before {
            background: var(--color-rebellious);
        }
    }

    &[href]:hover,
    &[href]:focus {
        opacity: .55;
    }
}

.content-card__media__label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--color-purple);
    font-size: 2.3rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    padding: 1.5rem;
    text-transform: uppercase;
    line-height: 1;
    transition: .3s ease;
    z-index: 1;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--color-general);
        opacity: .7;
        z-index: -1;
    }

    &.-purple {
        color: var(--color-general);

        &::after {
            background: var(--color-purple);
        }
    }

    a.content-card.-family:hover &, a.content-card.-family:focus & {
        background: var(--color-family);
    }

    a.content-card.-fun:hover &, a.content-card.-fun:focus & {
        background: var(--color-fun);
    }

    a.content-card.-rebellious:hover &, a.content-card.-rebellious:focus & {
        background: var(--color-rebellious);
    }

    a.content-card.-well-informed:hover &, a.content-card.-well-informed:focus & {
        background: var(--color-well-informed);
    }

    a.content-card.-general:hover &, a.content-card.-general:focus & {
        background: var(--color-general);
    }
}

.content-card__img {
    display: block;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        display: block;
        min-height: 14rem;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../images/general.svg');
        background-size: 250rem 250rem;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: top left;
        will-change: transform;
        opacity: 0;
        transition: all .3s ease;
        background-color: var(--color-general);

        @supports (mask-repeat: no-repeat) {
            background: var(--color-general);
            mask-image: url('../images/general.svg');
            mask-repeat: no-repeat;
            mask-size: 250rem 250rem;
            mask-position: -100% -100%;
        }

        a.content-card:hover &,
        a.content-card:focus &, {
            animation-name: spin;
            opacity: 1;
        }

        .content-cards__container.-purple & {
            background-color: var(--color-general);
        }

        .content-card.-family & {
            background-color: var(--color-family);
            background-image: url('../images/family.svg');

            @supports (mask-repeat: no-repeat) {
                background: var(--color-family);
                mask-image: url('../images/family.svg');
                mask-repeat: no-repeat;
                mask-size: 250rem 250rem;
            }
        }

        a.content-card.-family:hover &,
        a.content-card.-family:focus &, {
            animation-name: spin-family;
            opacity: 1;
        }

        .content-card.-fun & {
            background-color: var(--color-fun);
            background-image: url('../images/fun.svg');

            @supports (mask-repeat: no-repeat) {
                background: var(--color-fun);
                mask-image: url('../images/fun.svg');
                mask-repeat: no-repeat;
                mask-size: 250rem 250rem;
            }
        }

        a.content-card.-fun:hover &,
        a.content-card.-fun:focus &, {
            animation-name: spin-fun;
            opacity: 1;
        }

        .content-card.-rebellious & {
            background-color: var(--color-rebellious);
            background-image: url('../images/rebellious.svg');
            animation-duration: 2s;

            @supports (mask-repeat: no-repeat) {
                background: var(--color-rebellious);
                mask-image: url('../images/rebellious.svg');
                mask-repeat: no-repeat;
                mask-size: 250rem 250rem;
            }
        }

        a.content-card.-rebellious:hover &,
        a.content-card.-rebellious:focus &, {
            animation-name: spin-rebellious;
            opacity: 1;
        }

        .content-card.-well-informed & {
            background-color: var(--color-well-informed);
            background-image: url('../images/well-informed.svg');

            @supports (mask-repeat: no-repeat) {
                background: var(--color-well-informed);
                mask-image: url('../images/well-informed.svg');
                mask-repeat: no-repeat;
                mask-size: 250rem 250rem;
            }
        }

        a.content-card.-well-informed:hover &,
        a.content-card.-well-informed:focus &, {
            animation-name: spin-well-informed;
            opacity: 1;
        }
    }
}

@keyframes spin {
    from {
        background-position: -43% -43%;
        mask-position: 80% 80%;
    }
    to {
        background-position: -43.85% -43.85%;
        mask-position: 79.15% 79.15%;
    }
}

@keyframes spin-family {
    from {
        background-position: -43% -43%;
        mask-position: 80% 80%;
    }
    to {
        background-position: -43.85% -43.85%;
        mask-position: 79.15% 79.15%;
    }
}

@keyframes spin-fun {
    from {
        background-position: -43% -50%;
        mask-position: 80% 80%;
    }
    to {
        background-position: -44.1% -50.7%;
        mask-position: 79.15% 79.15%;
    }
}

@keyframes spin-rebellious {
    from {
        background-position: -38% -38%;
        mask-position: 80% 80%;
    }
    to {
        background-position: -38.85% -38.85%;
        mask-position: 78% 78%;
    }
}

@keyframes spin-well-informed {
    from {
        background-position: -43% -43%;
        mask-position: 70% 70%;
    }
    to {
        background-position: -43.85% -43.85%;
        mask-position: 69.15% 69.15%;
    }
}

.content-card__title {
    display: block;
    text-decoration: none;
    font-size: 3rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);
    line-height: 1.15;

    .content-cards__container.-purple & {
        color: var(--color-general);
    }

    @media (--screen-large) {
        max-width: 50%;
    }

    .content-card__list.-overview & {
        max-width: 100%;
    }

    .content-card__list.-events & {
        margin-bottom: 3rem;

        @media (--screen-large) {
            margin-bottom: 0;
        }
    }

    em {
        display: block;
        font-style: normal;
        font-size: 2.1rem;
        padding-top: 1rem;
    }

    & + .button {
        margin-top: 2rem;
    }

    a.content-card:hover &,
    a.content-card:focus & {
        opacity: .55;
    }
}

.content-card__info__extra {
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 1.7rem;
    color: var(--color-purple);
    opacity: .6;

    .content-cards__container.-purple & {
        color: var(--color-general);
        opacity: 1;
    }

    @media (--screen-large) {
        font-size: 2.1rem;
    }
}

.content-cards__arrows {
    position: absolute;
    top: .2rem;
    right: 2rem;
    display: flex;

    @media (--screen-large) {
        display: none;

        &.-desktop {
            display: flex;
        }
    }
}

.content-cards__arrow {
    background: var(--color-purple);
    border-radius: 50%;
    width: 4.2rem;
    height: 4.2rem;
    display: inline-block;
    cursor: pointer;
    transition: opacity .3s ease;
    outline: none;
    border: 0;
    opacity: 1;
    position: static;
    transform: translate(0);
    margin-left: .7rem;

    &:hover,
    &:focus {
        opacity: .5;
    }

    .content-cards__container.-purple & {
        background: var(--color-general);
    }

    .icon {
        display: none;
    }

    &.splide__arrow {
        position: relative;
        left: auto;
        right: auto;
    }
}


.content-cards__button {
    position: absolute;
    top: .6rem;
    right: 2rem;

    @media (--screen-mini-small) {
        display: none;
    }

    &.-desktop {
        display: none;

        @media (--screen-large) {
            display: block;
        }
    }

    &.-mobile {
        position: relative;
        top: auto;
        right: auto;
        display: inline-block;

        @media (--screen-large) {
            display: none;
        }
    }
}

.content-card__icon {
    width: 7rem;
    height: 7rem;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3.5rem 0 0 -3.5rem;
    background: var(--color-purple);
    border-radius: 50%;
    text-align: center;
    line-height: 7rem;
    z-index: 1;
}

.content-card__info__summary {
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 2.1rem;

    @media (--screen-large) {
        max-width: 60%;
    }

    @media (--screen-all) {
        max-width: 70%;
    }

    .content-card__list.-overview & {
        max-width: 100%;
    }

    .content-cards__container.-purple & {
        color: var(--color-general);
    }

    .content-card__list.-events & {
        display: none;

        @media (--screen-large) {
            display: block;
        }
    }

    p:first-child {
        margin-top: 0;
    }
}

.content-card__info__ticketlink {
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 2.5rem;

    @media (--screen-large) {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 20rem;
    }

    @media (--screen-jumbotron) {
        max-width: 100%;
    }

    .content-cards__container.-purple & {
        color: var(--color-general);
    }

    .icon {
        transform: translateY(-.2rem);
        margin-right: 1rem;
    }

    a.content-card:hover &, a.content-card:focus & {
        text-decoration: underline;
    }

    &.-no-icon .icon {
        display: none;
    }

    .button + & {
        clear: both;
        margin-left: 2rem;
    }
}

.content-card__info__downloadlink {
    @media (--screen-large) {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.content-card__info__refundlink {
    margin-top: 1.5rem;
}

.content-cards__action {
    display: block;
    text-align: center;
    padding-top: 1rem;

    @media (--screen-medium-large) {
        display: none;
    }

    &.-mobile {
        display: block;

        @media (--screen-large) {
            display: none;
        }
    }

    &.-all {
        display: block;

        @media (--screen-medium-large) {
            padding-top: 4rem;
        }
    }

    &.-button {
        padding-top: 3rem;
    }

    .-mobile {
        display: block;
        padding-bottom: 1rem;

        @media (--screen-medium-large) {
            display: none;
        }
    }
}

.content-card__info__program {
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 1.8rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 28rem;

    dt {
        flex: 1 1 calc(100% - 6rem);
    }

    dd {
        margin: 0;
        flex: 0 0 6rem;
        font-weight: var(--font-weight-medium);
        text-align: right;
    }

    a.content-card:hover &,
    a.content-card:focus & {
        opacity: .55;
    }
}

.content-card__info__refund {
    margin-top: -1.6rem;

    dt {
        display: block;
        padding-bottom: 1rem;
    }
}

.content-card__info__drinktokens {
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 1.6rem;
    color: var(--color-purple);
    opacity: .6;
    margin: 3.2rem 0 0;

    .content-cards__container.-purple & {
        color: var(--color-general);
        opacity: 1;
    }

    @media (--screen-large) {
        font-size: 1.8rem;
        justify-self: flex-end;
        margin: 0;
    }
}
