.poll {
    margin: 0 -2rem 4rem;

    background: var(--color-well-informed);
    padding: 5rem 0;

    background-repeat: no-repeat;
    background-position: 50% 50%;

    background-color: var(--color-well-informed);
    background-image: url('poll.svg');

    @media (--screen-full) {
        background-size: 125%;
    }
}

.poll__content {
    width: 100%;
    max-width: 86rem;
    margin: 0 auto;
    padding: 0 4rem;
    box-sizing: border-box;
    text-align: center;

    .alert + .button {
        margin-top: 5rem;
    }
}

.poll__answers {
    @media (--screen-large) {
        display: flex;
        justify-content: center;
    }

    .form__entry {
        margin: 1.5rem;

        @media (--screen-large) {
            flex-basis: 33%;
        }
    }

    & + .button {
        margin-top: 5rem;
    }
}

.poll__tiebreaker {
    padding: 5rem 0;

    .form__field {
        border-bottom-style: dashed;
        text-align: center;
        display: block;
        padding: .5rem .1rem;
        width: 6rem;
        margin: 0 auto;

        @media (--screen-large) {

            display: inline-block;
            margin-left: 1rem;
        }
    }

    & + .alert {
        margin-top: 0;
    }

    input:disabled,
    input[disabled] {
        opacity: .4;
        pointer-events: none;
    }
}

.poll__title {
    font-size: 3.5rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
}

.poll__anwser {
    display: inline-block;
    background: var(--color-white-hover);
    border: 0;
    color: var(--color-purple);
    font-size: 1.8rem;
    line-height: 1.5;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    border-radius: 30rem;
    padding: 1.5rem 2rem;
    text-decoration: none;
    transition: all .3s ease;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    outline: none;

    &:disabled,
    &[disabled] {
        pointer-events: none;
    }

    &:hover, &:focus {
        background: var(--color-white);
    }

    .form__radio:checked + & {
        background: var(--color-white);
        color: var(--color-purple);
    }
}

.poll__privacy {
    display: block;
    text-align: center;
    padding-top: 1.5rem;
    opacity: .4;
    font-size: 1.2rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
}
