.form__label{
    order: 2;

    display: none;
    cursor: pointer;

    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    opacity: .5;
    font-size: 1.6rem;
    line-height: 1;
    padding: 0 0 .5rem;

    transition: all .2s ease;


    &.-placeholder-label {
        order: 1;

        transform: translateY(2rem);
        position: absolute;
        top: 0;

        box-sizing: border-box;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.-active {
            font-size: 1.2rem;
            transform: translateY(-.5rem);
        }
    }

    &.-radio,
    &.-radio-button,
    &.-radio-group,
    &.-checkbox, .form__entry:has(.form__checkbox) & {
        display: block;
        padding: 0 0 0 3.2rem;

        color: var(--color-purple);
        font-family: var(--font-general);
        font-weight: var(--font-weight-regular);
        font-size: 1.6rem;
        line-height: 1.4;

        text-align: left;

        opacity: 1;
    }

    .form__entry:has(.form__checkbox) & {
        order: 2;
        position: relative;
        transform: unset;
        top: unset;
    }

    &.-radio-group {
        order: -1;
        padding: 0 0 1rem;
    }

    &.-select {
        pointer-events: none;
    }

    &.-no-style {
        display: initial;
        font: inherit;
        color: inherit;
        line-height: inherit;
        margin: 0;
        padding: 0;
    }

    &.-pointer {
        cursor: pointer;
    }

    .form__field + & {
        display: block;
    }

    .form__entry .form__entry div + & {
        display: block;
        position: relative;
        color: var(--color-purple);
        font-family: var(--font-general);
        font-weight: var(--font-weight-regular);
        font-size: 1.8rem;
        opacity: 1;
        transform: none;
        margin: 2rem 0;
    }

    .page-footer__newsletter__input & {
        color: var(--color-general);
        font-size: 1.4rem;
        margin-bottom: 0;

        a {
            color: var(--color-general);
            text-decoration: underline;
        }
    }

    .newsletter__input & {
        margin-top: 1rem;
        font-size: 1.4rem;

        a {
            color: var(--color-purple);
        }
    }

    .form__entry .form__entry .form__select + & {
        font-weight: var(--font-weight-medium);
        font-size: 1.9rem;
        margin: 0;
    }
}

[readonly] + label {
    cursor: default;
}
