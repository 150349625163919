.event-detail__header {
    position: relative;

    .site-container {
        position: relative;
        z-index: 1;
    }
}

.event-detail__bg {
    background-color: var(--color-general);
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color .3s ease;

    &::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: -250rem;
        left: 50%;
        margin-left: -250rem;
        width: 500rem;
        height: 500rem;
        background-image: url('../images/general.svg');
        background-size: 500rem 500rem;
        animation-duration: 12s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: center;
        will-change: transform;
        transition: background-image .3s ease;
        background-position: center center;
        animation-name: scale;
    }

    .event-detail__header.-fun & {
        background-color: var(--color-fun);

        &::after {
            background-image: url('../images/fun.svg');
        }
    }

    .event-detail__header.-family & {
        background-color: var(--color-family);

        &::after {
            background-image: url('../images/family.svg');
        }
    }

    .event-detail__header.-rebellious & {
        background-color: var(--color-rebellious);

        &::after {
            background-image: url('../images/rebellious.svg');
        }
    }

    .event-detail__header.-well-informed & {
        background-color: var(--color-well-informed);

        &::after {
            background-image: url('../images/well-informed.svg');
        }
    }
}

@keyframes scale {
    from {
        transform: scale(.75);
    }
    to {
        transform: scale(.85);
    }
}

.event-detail__header__info {
    position: relative;
    z-index: 1;
    font: 0/0 sans-serif;

    img {
        width: 100%;
        min-height: 35rem;
        max-height: 60rem;
        object-fit: cover;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(48, 0, 41, 0) 45.49%, rgba(48, 0, 41, 0.5) 96.74%);
    }
}

.event-detail__header__info__content {
    padding: 2rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 2.8rem;
    color: var(--color-white);
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;

    @media (--screen-large) {
        max-width: calc(100% - 34rem);
    }

    @media (--screen-full) {
        padding: 5rem 4rem;
    }

    h1 {
        color: var(--color-white) !important;
        font-family: var(--font-general);
        font-size: 4.2rem !important;
        line-height: 1 !important;
        margin: 0 0 1rem !important;

        @media (--screen-large) {
            font-size: 6.8rem !important;
            margin: 0 0 2rem !important;
        }

        .event-detail__header.-fun & {
            font-family: var(--font-fun);
            letter-spacing: .05rem;
        }

        .event-detail__header.-family & {
            font-family: var(--font-family);
            letter-spacing: -.3rem;
        }

        .event-detail__header.-rebellious & {
            font-family: var(--font-rebellious);
            letter-spacing: -.4rem;
        }

        .event-detail__header.-well-informed & {
            font-family: var(--font-well-informed)
        }
    }

    h2 {
        color: var(--color-white) !important;
        font-family: var(--font-general);
        font-weight: var(--font-weight-regular) !important;
        font-size: 2.1rem !important;
        line-height: 1.15 !important;
        margin: 0 !important;

        @media (--screen-large) {
            font-size: 2.8rem !important;
        }
    }

    h3 {
        color: var(--color-white) !important;
        text-transform: uppercase !important;
        font-family: var(--font-general);
        font-weight: var(--font-weight-regular) !important;
        font-size: 1.8rem !important;
        line-height: 1.15 !important;
        margin: 0 0 1rem !important;

        @media (--screen-large) {
            font-size: 3rem !important;
            margin: 0 0 2rem !important;
        }
    }
}

.event-detail__header__label {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 1.7rem;
    color: var(--color-general);
    padding: .8rem 1.2rem;
    line-height: 1.2;
    text-decoration: none;
    transition: opacity .3s ease;
    display: none;

    @media (--screen-large) {
        display: block;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
        opacity: .6;
    }
}

.event-detail__label {
    .button {
        margin-top: .5rem;
    }

    @media (--screen-large) {
        display: none;
    }
}

.event-detail__content {
    padding-bottom: 4rem;

    @media (--screen-large) {
        padding-bottom: 10rem;
    }

    .site-container {
        display: flex;
        flex-direction: column;

        @media (--screen-large) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

.event-detail__content__info {
    width: 100%;
    max-width: 75rem;
    margin: 0 auto;
    order: 2;
    box-sizing: border-box;

    .event-detail__content.-project & {
        @media (--screen-mini-medium) {
            padding: 2rem 0 0;
        }
    }

    @media (--screen-large) {
        order: 1;
        padding: 6rem 0 0;
    }

    @media (--screen-full) {
        padding: 6rem 2rem 0;
        margin: 0 0 0 4rem;
    }

    a {
        color: var(--color-purple);
        text-decoration: underline;
        font-family: var(--font-general);
        font-weight: var(--font-weight-medium);

        &:hover,
        &:focus {
            text-decoration: none;
        }

        .event-detail__content.-fun & {
            color: var(--color-fun);
        }

        .event-detail__content.-family & {
            color: var(--color-family);
        }

        .event-detail__content.-rebellious & {
            color: var(--color-rebellious);
        }

        .event-detail__content.-well-informed & {
            color: var(--color-well-informed);
        }
    }
}

.event-detail__content__aside {
    width: 100%;
    max-width: 85rem;
    margin: 0 auto 2rem auto;
    z-index: 1;
    background: var(--color-general);
    order: 1;

    .event-detail__content.-family & {
        background: var(--color-family-bg);
    }

    .event-detail__content.-fun & {
        background: var(--color-fun-bg);
    }

    .event-detail__content.-rebellious & {
        background: var(--color-rebellious-bg);
    }

    .event-detail__content.-well-informed & {
        background: var(--color-well-informed-bg);
    }

    @media (--screen-large) {
        position: sticky;
        top: 9rem;
        margin: -6.5rem 0 0 7rem;
        align-self: flex-start;
        order: 2;
        max-width: 34rem;
    }

    @media (--screen-full) {
        top: 9rem;
        margin: -9rem 0 0 7rem;
    }

    &.-no-button {
        margin-top: 0;
    }
}

.event-detail__ticket-block {
    padding: .5rem 2rem;
    font-size: 1.8rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);

    @media (--screen-full) {
        padding: 1.5rem 4.5rem;
    }

    small {
        font-size: 1.6rem;
        opacity: .4;

        &.-info {
            font-size: 1.2rem;
            padding-left: 2.7rem;
            display: block;
        }
    }

    dl {
        margin: 0;
        padding: 1.5rem 0;
        border-bottom: .1rem dotted var(--color-border-dark);
        display: flex;
        flex-wrap: wrap;

        &:last-child {
            border-bottom: 0;
        }

        dt {
            flex: 1 1 calc(100% - 6rem);

            &.-full {
                width: 100%;
            }

            &.-info {
                font-weight: var(--font-weight-medium);
            }

            .icon {
                width: 2.7rem;
                top: -.3rem;
            }
        }

        dd {
            margin: 0;
            flex: 0 0 6rem;
            font-weight: var(--font-weight-medium);
            text-align: right;
        }
    }
}

.event-detail__ticket-button {
    height: 6.5rem;
    background: var(--color-purple);
    border: 0;
    text-align: center;
    line-height: 6.5rem;
    display: block;
    color: var(--color-general);
    font-size: 2.5rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    text-decoration: none;
    transition: opacity .3s ease;

    .event-detail__content.-family & {
        color: var(--color-family);
    }

    .event-detail__content.-fun & {
        color: var(--color-fun);
    }

    .event-detail__content.-rebellious & {
        color: var(--color-rebellious);
    }

    .event-detail__content.-well-informed & {
        color: var(--color-well-informed);
    }

    @media (--screen-full) {
        height: 9rem;
        line-height: 9rem;
        font-size: 3.2rem;
    }

    &:hover,
    &:focus {
        opacity: .6;
    }

    &.-not-available {
        pointer-events: none;
    }

    &.-small {
        font-size: 2.1rem;

        @media (--screen-medium-large) {
            font-size: 2.3rem;
        }
    }

    &.-smaller {
        font-size: 1.8rem;

        @media (--screen-medium-large) {
            font-size: 2.1rem;
        }
    }
}

.event-detail__ticket-block__location {
    color: var(--color-purple);
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.event-detail__slogan {
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    line-height: 1.15;
    font-size: 2.6rem;

    @media (--screen-large) {
        font-size: 3.5rem;
    }
}

.event-detail__intro {
    max-height: 16rem;
    position: relative;
    overflow: hidden;
    margin-bottom: 1.5rem;

    &::after {
        transition: all 1s ease;
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';
        display: block;
        opacity: 1;
        width: 100%;
        height: 3rem;
        background: linear-gradient(to top, #fff, rgba(255,255,255,.3));
    }
    &.-active {
        max-height: none;

        &::after {
            opacity: 0;
            display: none;
        }
    }
}

.event-detail__logos {
    display: flex;
    align-items: center;
    margin: 2.5rem 0;
    flex-wrap: wrap;

    img {
        inline-size: auto;
        max-width: 100%;
        max-height: 7.5rem;
        margin: 0 1rem 1rem 0;
    }
}

.event-detail__content {
    #relive {
        display: block;
        padding-top: 8rem;
        margin-top: -8rem;
    }

    #media, #artists, #photos {
        padding-top: 14rem;
        margin-top: -14rem;

        &.-top {
            margin-top: -8rem;
        }
    }
}
