.row[data-block-type='form'] {
    padding-block: 5rem;
    background-color: var(--color-white);
    background-position: center;
    background-size: cover;

    @media (--screen-large) {
        padding-block: 10rem;
    }

    &[data-theme='fun'] {
        background-image: url('../../images/bg-fun.png');
    }

    &[data-theme='family'] {
        background-image: url('../../images/bg-family.png');
    }

    &[data-theme='rebellious'] {
        background-image: url('../../images/bg-rebellious.png');
    }

    &[data-theme='well-informed'] {
        background-image: url('../../images/bg-well-informed.png');
    }

    .row__inner {
        box-sizing: border-box;
    }

    &[data-theme='fun'] .row__inner,
    &[data-theme='family'] .row__inner,
    &[data-theme='rebellious'] .row__inner,
    &[data-theme='well-informed'] .row__inner {
        background-color: var(--color-white);
        padding: 2.5rem;
    }
}

.form {
    .myab-login & {
        margin-top: 4rem;
    }

    fieldset {
        border: 0;
        margin: 0 0 1rem;
        padding: 0;

        legend {
            color: var(--color-purple);
            font-family: var(--font-general);
            font-weight: var(--font-weight-medium);
            font-size: 1.9rem;
            text-align: left;
            padding: 0;
            margin: 0 0 2rem;
        }
    }

    .special-password {
        display: none;
    }
}

.form__link {
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 1.8rem;
    transition: opacity .3s ease;
    display: inline-block;

    &:hover,
    &:focus {
        opacity: .6;
    }

    .button + & {
        margin-top: 1rem;
    }
}

.form__title {
    margin: 0 0 3rem;
    font-size: 3.5rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);

    @media (--screen-large) {
        font-size: 4.3rem;
    }
}
