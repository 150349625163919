@use '../../node_modules/@inventis/cookieconsent/src/cookie-consent.scss';

dialog::backdrop {
    display: none;
}

.cookie-consent {
    background-color: var(--color-infocard);
    border: 0;
    border-radius: .4rem;
    z-index: 9999;
    padding: 2rem;
    position: fixed;
    bottom: 0;
    transform: none;
    margin: 0;
    left: auto;
    top: auto;
    border: .3rem solid var(--color-purple);

    @media (--screen-large) {
        padding: 2em;
    }
}

.cookie-consent__title {
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);
    font-size: 2.5rem;
    line-height: 1.15;
    margin-bottom: 2rem;

    @media (--screen-medium) {
        font-size: 2.8rem;
    }

    @media (--screen-large) {
        font-size: 3.5rem;
        margin-bottom: 3rem;
    }
}

.cookie-consent__inner, .cookie-consent__options .form__label.-checkbox {
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);
    font-size: 1.6rem;
    line-height: 1.5;

    @media (--screen-medium) {
        font-size: 1.8rem;
    }

    @media (--screen-large) {
        font-size: 2.1rem;
    }

    a {
        outline: none;
        font-weight: var(--font-weight-regular);
        color: var(--color-purple);
        text-decoration: underline !important;

        &:hover {
            text-decoration: none !important;
        }
    }

    p {
        margin-bottom: 2rem;

        @media (--screen-large) {
            margin-bottom: 3.2rem;
        }
    }
}

.cookie-consent__options .form__label.-checkbox::before,
.cookie-consent__options .form__label.-checkbox::after {
    top: .6rem;
}

.cookie-consent__buttons {
    display: flex;
    justify-content: center;
    flex-direction: row;

    .button {
        margin: .5rem;
        font-size: 1.6rem;

        @media (--screen-medium-large) {
            margin: 1rem;
            font-size: 1.8rem;
        }
    }

    &[hidden] {
        display: none;
    }
}

.cookie-consent__option-description {
    order: 3;
    font-size: 1.8rem;
    padding: 1rem 0 0 3.2rem;
}

.h-hidden {
    display: none;
}

template.-has-iframe {
    display: block;
    width: 100%;
    padding-top: 56.25%;
    background-color: var(--color-infocard);
    cursor: pointer;
    position: relative;
    margin-bottom: 3rem;

    @media (--screen-mini-small) {
        width: calc(100% + 4rem);
    }

    .page-detail__content__info &, .event-detail__content & {
        max-width: 75rem;
        margin: 0 auto 3rem;

        @media (--screen-mini-small) {
            margin-left: -2rem;
            margin-right: -2rem;
        }

        @media (--screen-large) {
            padding-top: calc(75rem * (9/16));
        }
    }

    &::before {
        content: attr(title);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 3rem;
        box-sizing: border-box;
        line-height: 1.5;
        color: var(--color-purple);
        font-size: 1.8rem;
        text-transform: none;
        font-family: var(--font-general);
        font-weight: var(--font-weight-regular);
    }
}
