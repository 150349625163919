.page-footer__nav {
    flex-basis: 41.5rem;
    margin-right: 3rem;
    display: none;

    @media (--screen-large) {
        display: block;
    }
}

.page-footer__nav-title {
    opacity: .6;
}

.page-footer__nav-list {
    margin: 1rem 0 0;
    padding: 0;
    list-style: none;
    columns: 2;

    li {
        margin: 0 0 1rem;
        padding: 0;
    }
}

.page-footer__nav-item {
    display: inline-block;
    color: var(--color-general);
    font-size: 2.1rem;
    line-height: 1.5;
    text-decoration: none;
    transition: opacity .3s ease;

    &:hover,
    &:focus {
        opacity: .6;
    }
}
