.page-header {
    display: block;
    height: 7rem;
    width: 100%;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    box-sizing: border-box;
    background-color: var(--color-general);
    transition: background-color .3s ease;
    z-index: 3;

    .site-container {
        display: flex;
        align-items: center;
        height: 100%;
    }

    html:not([data-scroll='0']) &, html.-fixed & {
        position: fixed;
    }

    &.-fun {
        background-color: var(--color-fun);
    }

    &.-family {
        background-color: var(--color-family);
    }

    &.-rebellious {
        background-color: var(--color-rebellious);
    }

    &.-well-informed {
        background-color: var(--color-well-informed);
    }

    &.-myab {
        background-color: var(--color-purple);
    }
}

.page-header__right {
    margin-left: auto;
}

.page-header__link {
    font-family: var(--font-general);
    font-size: 1.8rem;
    color: var(--color-purple);
    text-decoration: none;
    margin: 0 1.5rem;
    transition: opacity .3s ease;

    &:last-child {
        margin-right: 0;
    }

    &.-search {
        font: 0/0 sans-serif;
        vertical-align: middle;
    }

    &.-program, &.-search {
        display: none;

        @media (--screen-large) {
            display: inline-block;
        }
    }

    &:hover,
    &:focus {
        opacity: .6;
    }

    .page-header.-myab & {
        color: var(--color-general);
    }
}

span.page-header__link {
    margin-right: 0;
}

.page-header__logo {
    position: absolute;
    top: .7rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    .page-header.-myab & {
        background: var(--color-purple);
        border-radius: 50%;
        padding: .5rem;
        box-shadow:
            inset 0 0 0 .7rem var(--color-purple),
            inset 0 0 0 1.4rem var(--color-general);
    }
}

.page-header__left {
    @media (--screen-large) {
        display: flex;
        align-items: center;
    }
}

.page-header__liveurope {
    display: none;
    opacity: .6;
    margin-left: 1.5rem;

    @media (--screen-large) {
        display: inline-block;
    }

    img {
        inline-size: revert-layer;
        block-size: revert-layer;
    }
}
