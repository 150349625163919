@use 'figure';

figure.image {
    margin-block: 1.5rem 3rem;

    img {
        display: block;
        inline-size: 100%;
    }
}

img {
    inline-size: 100%;
    block-size: auto;
}

@media (--screen-from-sm) {
    figure.image {
        margin-block: 2rem 4rem;
    }
}

@media (--screen-from-xxl) {
    figure.image {
        margin-block: 3rem 6rem;
    }
}
