.tags-list {
    margin: 6rem 0 2.5rem;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        margin: 0 1.5rem 1.5rem 0;
    }

    &.-top {
        margin-top: 0;
    }
}

.tag {
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular) !important;
    font-size: 1.4rem;
    color: var(--color-purple) !important;
    text-decoration: none !important;
    text-transform: uppercase;
    letter-spacing: .3rem;
    padding: .8rem .9rem .8rem 1.2rem;
    border: .1rem solid var(--color-general);
    transition: background-color .3s ease;

    .event-detail__content.-family & {
        border-color: var(--color-family);
    }

    .event-detail__content.-fun & {
        border-color: var(--color-fun);
    }

    .event-detail__content.-rebellious & {
        border-color: var(--color-rebellious);
    }

    .event-detail__content.-well-informed & {
        border-color: var(--color-well-informed);
    }

    &:hover,
    &:focus {
        background-color: var(--color-general);

        .event-detail__content.-family & {
            background-color: var(--color-family);
        }

        .event-detail__content.-fun & {
            background-color: var(--color-fun);
        }

        .event-detail__content.-rebellious & {
            background-color: var(--color-rebellious);
        }

        .event-detail__content.-well-informed & {
            background-color: var(--color-well-informed);
        }
    }
}
