.myab__header {
    background-color: var(--color-purple);
    padding: 6rem 0 0;
    margin-bottom: 4rem;

    @media (--screen-medium-large) {
        padding: 10rem 0 0;
        margin-bottom: 8rem;
    }

    &.-artists {
        padding: 3rem 0 0;
        margin-bottom: 2rem;

        @media (--screen-medium-large) {
            padding: 5rem 0 0;
            margin-bottom: 4rem;
        }
    }
}

.myab {
    &.-bottom {
        margin-bottom: 4rem;

        @media (--screen-medium-large) {
            margin-bottom: 8rem;
        }
    }
}

.myab__header__title {
    display: block;
    color: var(--color-general);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 4.2rem;
    text-align: center;
    margin: 0 0 3rem;

    @media (--screen-large) {
        font-size: 6.8rem;
    }

    &::after {
        content: '';
        width: 4.2rem;
        height: 4.8rem;
        background-image:url(hand.svg);
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        margin-left: 1.5rem;
    }
}

.myab__nav {
    padding: .5rem 0;
    text-align: center;
}

.myab__nav__item {
    font-family: var(--font-general);
    font-weight: var(--font-weight-medium);
    font-size: 1.8rem;
    color: var(--color-purple);
    opacity: .3;
    transition: opacity .3s ease;
    text-decoration: none;
    margin: 1.5rem;
    display: inline-block;

    @media (--screen-medium-large) {
        font-size: 2.4rem;
    }

    &.-active,
    &:hover,
    &:focus {
        opacity: 1;
    }
}

.myab__alert {
    margin: 2rem 0;
    border: .1rem dotted var(--color-border-dark);
    padding: 2rem;
    text-align: center;

    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 1.8rem;
    line-height: 1.5;

    @media (--screen-medium-large) {
        padding: 5rem;
        margin: 4rem 0;
    }

    * {
        max-width: 60rem;
        margin-left: auto;
        margin-right: auto;
    }

    &.-top {
        margin-top: 0;
    }

    &.-nb {
        margin-top: 0;
        padding: 0;
        border: 0;
    }

    a {
        outline: none;
        font-weight: var(--font-weight-regular);
        color: var(--color-purple);
        text-decoration: underline !important;

        &:hover {
            text-decoration: none !important;
        }

        &.button {
            text-decoration: none !important;
        }
    }
}

.myab__alert__title {
    font-size: 2.8rem;
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    display: block;
    margin-bottom: 2.5rem;
}

.myab__title {
    font-size: 4.2rem;
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
}

.myab__search {
    @media (--screen-large) {
        display: flex;
        align-items: center;
    }

    .form {
        width: 100%;
        max-width: 45rem;
        position: relative;
        z-index: 1;
        margin-top: 2rem;

        @media (--screen-large) {
            margin-left: auto;
            margin-top: 0;
        }
    }
}

.myab__search__input {
    border: .1rem solid var(--color-border-dark);
    border-radius: 7rem;
    padding: 2rem 2rem 2rem 5.6rem;
    box-sizing: border-box;
    width: 100%;
    outline: none;

    font-size: 1.6rem;
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);

    background-image: url(search.svg);
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    background-position: 2rem 2rem;
}
