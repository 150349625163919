.quote {
    margin-block: 3rem !important;
}

.quote__quote {
    margin: 0;

    font-size: 2.5rem;
    font-weight: var(--font-weight-semi-bold);
    line-height: 1.44;
    text-align: center;
    letter-spacing: .04rem;

    &::before {
        content: '\201C';
    }

    &::after {
        content: '\201D';
    }
}

.quote__author {
    margin-block-start: 2rem;
    font-size: 2.1rem;
    line-height: 1.2;
    text-align: center;
    color: var(--color-title);
}
