.logo {
    position: relative;
    vertical-align: middle;
    font: 0/0 sans-serif;
    display: inline-block;

    width: 13.5rem;
    height: 13.5rem;

    color: var(--color-general);

    .page-header & {
        width: 8rem;
        height: 8rem;

        @media (--screen-medium-large) {
            width: 13.5rem;
            height: 13.5rem;
        }
    }

    .page-footer & {
        margin-top: -12rem;

        @media (--screen-large) {
            margin: 0;
            width: 20rem;
            height: 20rem;
        }
    }

    .page-header.-myab & {
        width: 10rem;
        height: 10rem;

        @media (--screen-medium-large) {
            width: 13.5rem;
            height: 13.5rem;
        }
    }

    &::before, &:after {
        display: none;
        content: '';

        transition: all .3s ease;

        width: 13.5rem;
        height: 13.5rem;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        background-color: var(--color-purple);
        background-image: url(stroke-general.svg);

        background-size: 13.5rem 13.5rem;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-purple);
            mask-repeat: no-repeat;
            mask-image: url(stroke-general.svg);
            mask-size: 13.5rem 13.5rem;
        }

        .page-header & {
            width: 8rem;
            height: 8rem;

            background-size: 8rem 8rem;

            @supports (mask-repeat: no-repeat) {
                mask-size: 8rem 8rem;
            }

            @media (--screen-medium-large) {
                width: 13.5rem;
                height: 13.5rem;

                background-size: 13.5rem 13.5rem;

                @supports (mask-repeat: no-repeat) {
                    mask-size: 13.5rem 13.5rem;
                }
            }
        }

        .page-header.-myab & {
            width: 8rem;
            height: 8rem;
        }

        .page-footer & {
            @media (--screen-large) {
                width: 20rem;
                height: 20rem;

                background-size: 20rem 20rem;

                @supports (mask-repeat: no-repeat) {
                    mask-size: 20rem 20rem;
                }
            }
        }
    }

    &::after {
        z-index: 0;
        background-color: var(--color-general);
        background-image: url(bg-general.svg);
        transform: scale(.99) translate(-50%, -50%);

        .page-footer & {
            background-color: var(--color-purple) !important;
        }

        @supports (mask-repeat: no-repeat) {
            background: var(--color-general);
            mask-image: url(bg-general.svg);

            .page-footer & {
                background: var(--color-purple) !important;
            }
        }
    }
}

.logo.-stroke::before {
    display: inline-block;
}

.logo.-bg::after,
.page-footer .logo::after {
    display: inline-block;
}

.logo__ab {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    transition: background .3s ease;

    display: inline-block;
    width: 9.7rem;
    height: 9.7rem;

    background-color: var(--color-purple);
    background-image: url(ab.svg);

    background-size: 9.7rem 9.7rem;

    @supports (mask-repeat: no-repeat) {
        background: var(--color-purple);
        mask-repeat: no-repeat;
        mask-image: url(ab.svg);
        mask-size: 9.7rem 9.7rem;
    }

    .page-header & {
        width: 6rem;
        height: 6rem;

        background-size: 6rem 6rem;

        @supports (mask-repeat: no-repeat) {
            mask-size: 6rem 6rem;
        }

        @media (--screen-medium-large) {
            width: 9.7rem;
            height: 9.7rem;

            background-size: 9.7rem 9.7rem;

            @supports (mask-repeat: no-repeat) {
                mask-size: 9.7rem 9.7rem;
            }
        }
    }

    .page-footer & {
        @media (--screen-large) {
            width: 13rem;
            height: 13rem;

            background-size: 13rem 13rem;

            @supports (mask-repeat: no-repeat) {
                mask-size: 13rem 13rem;
            }
        }
    }
}

$types: (
    'general',
    'well-informed',
    'family',
    'fun',
    'rebellious',
    'purple',
);

@each $name in $types {
    .logo.-#{$name} {
        &::before {
            background-color: var(--color-#{$name});
            background-image: url(stroke-#{$name}.svg);

            @supports (mask-repeat: no-repeat) {
                background: var(--color-#{$name});
                mask-image: url(stroke-#{$name}.svg);
            }
        }

        &::after {
            background-color: var(--color-#{$name});
            background-image: url(bg-#{$name}.svg);

            .page-header & {
                background-color: var(--color-purple);
            }

            @supports (mask-repeat: no-repeat) {
                background: var(--color-#{$name});
                mask-image: url(bg-#{$name}.svg);

                .page-header & {
                    background: var(--color-purple);
                }
            }
        }

        .logo__ab {
            background-color: var(--color-#{$name});

            @supports (mask-repeat: no-repeat) {
                background: var(--color-#{$name});
            }
        }
    }
}
