@charset "UTF-8";
/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
  --color-white: #FFFFFF;
  --color-white-hover: rgba(255, 255, 255, .4);
  --color-black: #000000;
  --color-purple: #300029;
  --color-purple-hover: rgba(48, 0, 41, .75);
  --color-general: #FFEDED;
  --color-general-hover: #FFF2F3;
  --color-general-bg: rgba(255, 237, 237, .25);
  --color-well-informed: #C1D0D6;
  --color-well-informed-bg: rgba(193, 208, 214, .25);
  --color-family: #FF796E;
  --color-family-bg: rgba(255, 121, 110, .25);
  --color-fun: #E0EA18;
  --color-fun-bg: rgba(224, 234, 24, .25);
  --color-rebellious: #EA2EDA;
  --color-rebellious-bg: rgba(234, 46, 218, .25);
  --color-border: rgba(255, 237, 237, .4);
  --color-border-dark: rgba(48, 0, 41, .4);
  --color-submenu: #D6BDBD;
  --color-infocard: #FFF8F8;
  --color-error: #EA2EDA;
  --color-facebook: #1877F2;
  --color-spotify: #1DB954;
  --color-sticky-purple: #7F8AFE;
  --color-sticky-green: #01FF6B;
  --color-title: #A59CAD;
  --color-faq: #968092;
  --font-general: "Graebenbach";
  --font-well-informed: "Souvenir";
  --font-family: "Spoof";
  --font-fun: "MediaSans";
  --font-rebellious: "Harbour";
  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-black: 900;
  --side-padding: 2rem;
}

@custom-media --screen-all          (width >= 1440px);
@custom-media --screen-full         (width >= 1280px);
@custom-media --screen-jumbotron    (width >= 1024px);
@custom-media --screen-large        (width >= 850px);
@custom-media --screen-medium-large (width >= 630px);
@custom-media --screen-medium       (width >= 630px) and (width < 850px);
@custom-media --screen-small-large  (width >= 420px);
@custom-media --screen-small-medium (width >= 420px) and (width < 850px);
@custom-media --screen-small        (width >= 420px) and (width < 630px);
@custom-media --screen-mini-jumbotron   (width < 1024px);
@custom-media --screen-mini-medium      (width < 850px);
@custom-media --screen-mini-small       (width < 630px);
@custom-media --screen-mini             (width < 420px);
@custom-selector :--focus :hover, :focus, :active;
@custom-media --screen-from-xs (width >= 0);
@custom-media --screen-up-to-xs (width < 0);
@custom-media --screen-xs-xs (width >= 0) and (width < 0);
@custom-media --screen-xs-sm (width >= 0) and (width < 576px);
@custom-media --screen-xs-md (width >= 0) and (width < 768px);
@custom-media --screen-xs-lg (width >= 0) and (width < 992px);
@custom-media --screen-xs-xl (width >= 0) and (width < 1200px);
@custom-media --screen-xs-xxl (width >= 0) and (width < 1400px);
@custom-media --screen-from-sm (width >= 576px);
@custom-media --screen-up-to-sm (width < 576px);
@custom-media --screen-sm-xs (width >= 576px) and (width < 0);
@custom-media --screen-sm-sm (width >= 576px) and (width < 576px);
@custom-media --screen-sm-md (width >= 576px) and (width < 768px);
@custom-media --screen-sm-lg (width >= 576px) and (width < 992px);
@custom-media --screen-sm-xl (width >= 576px) and (width < 1200px);
@custom-media --screen-sm-xxl (width >= 576px) and (width < 1400px);
@custom-media --screen-from-md (width >= 768px);
@custom-media --screen-up-to-md (width < 768px);
@custom-media --screen-md-xs (width >= 768px) and (width < 0);
@custom-media --screen-md-sm (width >= 768px) and (width < 576px);
@custom-media --screen-md-md (width >= 768px) and (width < 768px);
@custom-media --screen-md-lg (width >= 768px) and (width < 992px);
@custom-media --screen-md-xl (width >= 768px) and (width < 1200px);
@custom-media --screen-md-xxl (width >= 768px) and (width < 1400px);
@custom-media --screen-from-lg (width >= 992px);
@custom-media --screen-up-to-lg (width < 992px);
@custom-media --screen-lg-xs (width >= 992px) and (width < 0);
@custom-media --screen-lg-sm (width >= 992px) and (width < 576px);
@custom-media --screen-lg-md (width >= 992px) and (width < 768px);
@custom-media --screen-lg-lg (width >= 992px) and (width < 992px);
@custom-media --screen-lg-xl (width >= 992px) and (width < 1200px);
@custom-media --screen-lg-xxl (width >= 992px) and (width < 1400px);
@custom-media --screen-from-xl (width >= 1200px);
@custom-media --screen-up-to-xl (width < 1200px);
@custom-media --screen-xl-xs (width >= 1200px) and (width < 0);
@custom-media --screen-xl-sm (width >= 1200px) and (width < 576px);
@custom-media --screen-xl-md (width >= 1200px) and (width < 768px);
@custom-media --screen-xl-lg (width >= 1200px) and (width < 992px);
@custom-media --screen-xl-xl (width >= 1200px) and (width < 1200px);
@custom-media --screen-xl-xxl (width >= 1200px) and (width < 1400px);
@custom-media --screen-from-xxl (width >= 1400px);
@custom-media --screen-up-to-xxl (width < 1400px);
@custom-media --screen-xxl-xs (width >= 1400px) and (width < 0);
@custom-media --screen-xxl-sm (width >= 1400px) and (width < 576px);
@custom-media --screen-xxl-md (width >= 1400px) and (width < 768px);
@custom-media --screen-xxl-lg (width >= 1400px) and (width < 992px);
@custom-media --screen-xxl-xl (width >= 1400px) and (width < 1200px);
@custom-media --screen-xxl-xxl (width >= 1400px) and (width < 1400px);
.alert {
  text-align: left;
  background-color: var(--color-purple);
  color: var(--color-general);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 2.1rem;
  display: block;
  margin: 6rem 0;
  border-radius: 10rem;
  padding: 1rem 1rem 1rem 8rem;
  position: relative;
  transition: all 0.3s ease, transform 0.6s ease, all 1s ease;
}
.alert.-hidden {
  opacity: 0;
  margin: 0;
  padding: 0;
  transform: scale(0);
}
.alert.-family {
  background-color: var(--color-family);
  color: var(--color-purple);
}
.alert.-fun {
  background-color: var(--color-fun);
  color: var(--color-purple);
}
.alert.-rebellious {
  background-color: var(--color-rebellious);
  color: var(--color-purple);
}
.alert.-error {
  padding: 2rem;
}
.poll__content .alert.-error {
  padding: 1.5rem;
  margin-bottom: 0;
}
.alert.-button {
  padding: 1.5rem;
  margin: 0 0 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 1rem;
  text-align: center;
}
@media (--screen-large) {
  .alert.-button {
    border-radius: 10rem;
    padding: 1rem 1rem 1rem 3rem;
    flex-direction: row;
    justify-content: left;
    text-align: left;
  }
}
.alert.-button .button {
  margin-top: 1rem;
}
@media (--screen-large) {
  .alert.-button .button {
    margin-top: 0;
    margin-left: auto;
  }
}
.alert.-button span {
  color: var(--color-white);
}
.alert.-button:has(.alert__close) {
  padding-left: 8rem;
}

.alert__close {
  position: absolute;
  top: 1.6rem;
  left: 2.8rem;
  width: 2rem;
  height: 2rem;
  border: 0;
  font: 0/0 sans-serif;
  cursor: pointer;
  background-color: var(--color-general);
  background-image: url(cross.svg);
  background-size: 2rem 2rem;
  transition: opacity 0.3s ease;
}
.alert.-family .alert__close, .alert.-fun .alert__close, .alert.-rebellious .alert__close {
  background-color: var(--color-purple);
}
@supports (mask-repeat: no-repeat) {
  .alert__close {
    background: var(--color-general);
    mask-image: url(cross.svg);
    mask-repeat: no-repeat;
    mask-size: 2rem 2rem;
  }
  .alert.-family .alert__close, .alert.-fun .alert__close, .alert.-rebellious .alert__close {
    background: var(--color-purple);
  }
}
.alert__close:hover, .alert__close:focus {
  opacity: 0.6;
}

.artist__list {
  border-top: 0.1rem dotted var(--color-border-dark);
  list-style: none;
  margin: 0;
  padding: 0;
}
.artist__list li {
  border-bottom: 0.1rem dotted var(--color-border-dark);
}
.artist__list.-search {
  margin: 4rem 0;
}

.artist {
  padding: 2rem 0 1rem;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}
@media (--screen-large) {
  .artist {
    padding: 2rem 0;
  }
}

.artist__favorite {
  background: var(--color-black);
  width: 4rem;
  height: 4rem;
  display: block;
  border-radius: 50%;
  text-align: center;
  line-height: 4rem;
  margin-right: 1rem;
  flex: 0 0 4rem;
  position: relative;
  top: -0.1rem;
  cursor: pointer;
  outline: none;
  border: none;
}
@media (--screen-large) {
  .artist__favorite {
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
    flex: 0 0 5rem;
    margin-right: 2rem;
  }
}
.event-detail__content.-family .artist__favorite.-favorite .icon.-heart::before, .event-detail__content.-family .artist__favorite:hover .icon.-heart::before, .event-detail__content.-family .artist__favorite:focus .icon.-heart::before {
  background: var(--color-family);
}
.event-detail__content.-fun .artist__favorite.-favorite .icon.-heart::before, .event-detail__content.-fun .artist__favorite:hover .icon.-heart::before, .event-detail__content.-fun .artist__favorite:focus .icon.-heart::before {
  background: var(--color-fun);
}
.event-detail__content.-rebellious .artist__favorite.-favorite .icon.-heart::before, .event-detail__content.-rebellious .artist__favorite:hover .icon.-heart::before, .event-detail__content.-rebellious .artist__favorite:focus .icon.-heart::before {
  background: var(--color-rebellious);
}
.event-detail__content.-well-informed .artist__favorite.-favorite .icon.-heart::before, .event-detail__content.-well-informed .artist__favorite:hover .icon.-heart::before, .event-detail__content.-well-informed .artist__favorite:focus .icon.-heart::before {
  background: var(--color-well-informed);
}
.event-detail__content.-general .artist__favorite.-favorite .icon.-heart::before, .event-detail__content.-general .artist__favorite:hover .icon.-heart::before, .event-detail__content.-general .artist__favorite:focus .icon.-heart::before {
  background: var(--color-general);
}
.artist__list.-search .artist__favorite {
  background: var(--color-purple);
  transition: background-color 0.3s ease;
}
.artist__list.-search .artist__favorite:hover, .artist__list.-search .artist__favorite:focus {
  background: var(--color-general);
}
.artist-toggle .artist__favorite {
  transition: opacity 0.3s ease;
}
.artist-toggle .artist__favorite:hover, .artist-toggle .artist__favorite:focus {
  opacity: 0.6;
}

.artist__name {
  font-size: 1.7rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  flex: 1 1;
}
@media (--screen-large) {
  .artist__name {
    font-size: 2.8rem;
  }
}

.artist__socials {
  padding: 0;
  list-style: none;
  background: var(--color-white);
  margin: 0 0 0 5rem;
  flex-basis: 100%;
}
@media (--screen-large) {
  .artist__socials {
    margin: 0 0 0 7rem;
  }
}
@media (--screen-full) {
  .artist__socials {
    position: absolute;
    right: 0;
    border-left: 0.1rem dotted var(--color-border-dark);
    margin: 0 0 0 2rem;
  }
}
.artist__socials li {
  border: 0;
  display: inline-block;
  padding: 1rem 2rem 1rem 0;
}
@media (--screen-large) {
  .artist__socials li {
    padding: 2rem 3rem 0 0;
  }
}
@media (--screen-full) {
  .artist__socials li {
    padding: 2rem 3rem 2rem 0;
  }
}
.artist__socials li:first-child {
  padding-left: 0;
}
@media (--screen-full) {
  .artist__socials li:first-child {
    padding-left: 3rem;
  }
}
.artist__socials::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -6.1rem;
  bottom: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
  width: 6rem;
  height: 100%;
}

.artist__socials__item {
  transition: opacity 0.3s ease;
}
.artist__socials__item:hover, .artist__socials__item:focus {
  opacity: 0.6;
}

.audio-player {
  display: block;
  width: 5rem;
  height: 5rem;
  background: var(--color-general);
  position: absolute;
  bottom: 0;
  right: 0;
  text-decoration: none;
  text-align: center;
  line-height: 5rem;
  border: 0;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  z-index: 1;
}
.event-detail__header__info .audio-player {
  background: var(--color-purple);
  transition: opacity 0.3s ease;
}
.audio-player.-top {
  top: 0;
  bottom: auto;
}
.audio-player .icon.-audio {
  display: inline-block;
}
.audio-player .icon.-cross {
  display: none;
}
.audio-player.-playing .icon.-cross {
  display: inline-block;
}
.audio-player.-playing .icon.-audio {
  display: none;
}
.audio-player:hover, .audio-player:focus {
  background: var(--color-purple);
}
.content-card.-family .audio-player:hover, .content-card.-family .audio-player:focus {
  background: var(--color-family);
}
.content-card.-fun .audio-player:hover, .content-card.-fun .audio-player:focus {
  background: var(--color-fun);
}
.content-card.-rebellious .audio-player:hover, .content-card.-rebellious .audio-player:focus {
  background: var(--color-rebellious);
}
.content-card.-well-informed .audio-player:hover, .content-card.-well-informed .audio-player:focus {
  background: var(--color-well-informed);
}
.event-detail__header__info .audio-player:hover, .event-detail__header__info .audio-player:focus {
  opacity: 0.6;
}

body {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
  margin: 0 auto;
  background-color: var(--color-white);
  word-wrap: break-word;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.8rem;
  line-height: 1.5;
  overflow-x: hidden;
  color: var(--color-purple);
  -webkit-font-smoothing: antialiased;
}
body.-no-overflow {
  position: fixed;
  overflow: hidden;
  height: 100%;
  /* Prevent iOS safari from still showing main content */
}
@supports (-webkit-overflow-scrolling: touch) {
  body.-no-overflow main {
    opacity: 0;
  }
}

.browser-update, .no-script {
  background: #CCCCCC;
  color: #000000;
  display: none;
  margin: 0;
  padding: 1rem 2rem;
  height: 5rem;
  line-height: 1.5;
  text-align: center;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  z-index: 9999;
  /* stylelint-disable csstools/media-use-custom-media */
  /* styleline-enable */
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .browser-update, .no-script {
    display: block;
  }
}

.no-script {
  display: block;
}

noscript ~ .browser-update {
  top: 5rem;
}

.row[data-block-type=button-row] {
  padding-block: 5rem;
  background-position: top center;
}
.row[data-block-type=button-row][data-theme=fun] {
  background-image: url("../../images/bg-fun.png");
}
.row[data-block-type=button-row][data-theme=family] {
  background-image: url("../../images/bg-family.png");
}
.row[data-block-type=button-row][data-theme=rebellious] {
  background-image: url("../../images/bg-rebellious.png");
}
.row[data-block-type=button-row][data-theme=well-informed] {
  background-image: url("../../images/bg-well-informed.png");
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: flex-start;
  margin: 0 0 3.6rem;
}
.button-row.-centered, [data-alignment=centered] .button-row {
  justify-content: center;
}
.row[data-block-type=button-row] .button-row {
  margin-bottom: 0;
}
.button-row > * {
  flex: 0 0 auto;
}

.row[data-block-type=call-to-action] {
  display: flex;
  align-items: center;
  min-height: 40rem;
  background-color: var(--color-general);
  background-position: center;
  background-size: cover;
}
.row[data-block-type=call-to-action][data-theme=fun] {
  background-image: url("fun.png");
}
.row[data-block-type=call-to-action][data-theme=family] {
  background-image: url("family.png");
}
.row[data-block-type=call-to-action][data-theme=rebellious] {
  background-image: url("rebellious.png");
}
.row[data-block-type=call-to-action][data-theme=well-informed] {
  background-image: url("well-informed.png");
}

.call-to-action {
  margin-block: 3.5rem;
  padding: 3rem 1.9rem;
}

.call-to-action__title {
  text-align: center;
}

.call-to-action__content {
  font-size: 1.8rem;
  line-height: 1.55;
  text-align: center;
}
.call-to-action__content p {
  text-align: center !important;
}
.call-to-action__content:last-child {
  margin-block-end: 0;
}

.call-to-action__actions {
  display: flex;
  justify-content: center;
  margin-block-start: 3rem;
}

@media (--screen-from-sm) {
  .call-to-action {
    margin-block: 4rem;
    padding: 5.5rem 7rem;
  }
}
@media (--screen-from-lg) {
  .call-to-action {
    margin-block: 6rem;
    padding: 5.5rem 3rem;
  }
}
@media (--screen-from-xxl) {
  .call-to-action {
    padding: 9rem 6rem;
  }
}
.card {
  flex: 0 1 45rem;
  text-decoration: none !important;
  box-sizing: border-box;
}
[data-theme=fun] .card, [data-theme=family] .card, [data-theme=rebellious] .card, [data-theme=well-informed] .card {
  padding: 3rem;
  background-color: var(--color-white);
}
.card:not([href]), .card[href=""], .card[href="#"] {
  pointer-events: none;
}
[data-gjs-type=wrapper] .card:not([href]), [data-gjs-type=wrapper] .card[href=""], [data-gjs-type=wrapper] .card[href="#"] {
  pointer-events: auto;
}

.card__figure {
  transition: opacity 0.3s ease;
}
a.card:--focus .card__figure {
  opacity: 0.55;
}

.card__info {
  padding: 1.6rem 0 0 0;
  text-align: center;
  transition: opacity 0.3s ease;
}
a.card:--focus .card__info {
  opacity: 0.55;
}

.card__title, h3.card__title {
  margin: 0;
  font-weight: var(--font-weight-bold);
}

.card__description {
  margin-block: 1.6rem 0;
}

.card__button {
  margin-block: 3.2rem 0;
}
[data-block-type=child-pages] .card__button {
  display: none;
}

@media (--screen-from-md) {
  .card {
    flex: 0 1 36.7rem;
  }
}
:root {
  --outer-container-max-width: 192rem;
  --inner-container-max-width: 160rem;
  --site-padding: 2rem;
}

/* Max site width without padding */
/* Max content width with side padding */
.row[data-block-type=card-grid] {
  background-color: var(--color-general);
  background-position: center;
  background-size: cover;
}
.row[data-block-type=card-grid][data-theme=fun] {
  background-image: url("../../images/bg-fun.png");
}
.row[data-block-type=card-grid][data-theme=family] {
  background-image: url("../../images/bg-family.png");
}
.row[data-block-type=card-grid][data-theme=rebellious] {
  background-image: url("../../images/bg-rebellious.png");
}
.row[data-block-type=card-grid][data-theme=well-informed] {
  background-image: url("../../images/bg-well-informed.png");
}

.card-grid {
  padding-block: 3rem;
}
@media (--screen-from-lg) {
  .card-grid {
    padding-block: 7rem;
  }
}

.card-grid__inner {
  inline-size: 100%;
  margin-inline: auto;
  max-inline-size: 128rem;
  padding-inline: var(--side-padding);
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
  box-sizing: border-box;
}
@media (--screen-from-lg) {
  .card-grid__inner {
    gap: 5rem;
  }
}
.card-grid__inner.-empty {
  justify-content: flex-start;
}

.card-rounded {
  display: flex;
  flex: 0 1 20em;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none !important;
}
[data-theme=fun] .card-rounded, [data-theme=family] .card-rounded, [data-theme=rebellious] .card-rounded, [data-theme=well-informed] .card-rounded {
  padding: 3rem;
  background-color: var(--color-white);
}
.card-rounded:not([href]), .card-rounded[href=""], .card-rounded[href="#"] {
  pointer-events: none;
}
[data-gjs-type=wrapper] .card-rounded:not([href]), [data-gjs-type=wrapper] .card-rounded[href=""], [data-gjs-type=wrapper] .card-rounded[href="#"] {
  pointer-events: auto;
}

.card-rounded__figure {
  overflow: hidden;
  inline-size: 20rem;
  block-size: 20rem;
  margin-block: 2.2rem;
  border-radius: 50%;
}

.card-rounded__info {
  padding: 1.6rem 0 0 0;
  text-align: center;
}

.card-rounded__title {
  margin: 0;
}

.card-rounded__description {
  margin-block: 3.2rem 0;
}

.card-rounded__button {
  margin-block: 3.2rem 0;
}

.content-cards__container {
  padding: 4rem 0;
}
.content-cards__container.-purple {
  background: var(--color-purple);
}
@media (--screen-large) {
  .content-cards__container.-purple {
    padding: 10rem 0;
  }
}
.content-cards__container .site-container {
  position: relative;
}
.content-cards__container.-np {
  padding-top: 0;
}

.content-cards__title {
  color: var(--color-purple);
  font-size: 2.8rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  display: block;
  margin-bottom: 1rem;
}
.content-cards__container .content-cards__title {
  margin-bottom: 3rem;
}
.content-cards__container.-full .content-cards__title {
  padding-left: var(--side-padding);
}
@media (--screen-large) {
  .content-cards__container.-full .content-cards__title {
    padding-left: 0;
  }
}
.content-cards__container.-purple .content-cards__title {
  color: var(--color-general);
}
@media (--screen-large) {
  .content-cards__title {
    font-size: 3.5rem;
    padding: 0;
  }
}

.content-card__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.content-card__list:not(.splide__list) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (--screen-large) {
  .content-card__list {
    margin: 0 -1.5rem;
  }
}
.content-card__list li:not(.splide__slide) {
  margin: 1.5rem;
  flex-basis: 100%;
}
@media (--screen-medium) {
  .content-card__list li:not(.splide__slide) {
    flex-basis: calc(50% - 3rem);
  }
}
@media (--screen-large) {
  .content-card__list li:not(.splide__slide) {
    flex-basis: calc(33.3333333333% - 3rem);
  }
}
.content-card__list.-rows {
  flex-direction: column;
  margin: 0 0 3rem 0;
}
@media (--screen-large) {
  .content-card__list.-rows {
    margin: 0;
  }
}
.content-card__list.-rows + .content-card__list.-overview {
  margin-top: 2rem;
}
@media (--screen-large) {
  .content-card__list.splide__list {
    margin-bottom: -3rem !important;
  }
}

@media (--screen-large) {
  .content-card__list.splide__list {
    display: flex !important;
    flex-direction: row;
    padding: 0;
    gap: 3rem;
    list-style: none;
    margin: 0 -1.5rem;
    margin-bottom: -3rem !important;
  }
}
@media (--screen-large) {
  .content-card__list.splide__list li {
    flex-basis: calc(33.3333333333% - 2rem);
  }
}

.content-card__list__row, .content-card__list li.content-card__list__row {
  padding: 3rem 0;
  border-bottom: 0.1rem dotted var(--color-border-dark);
  margin: 0;
  width: 100%;
}
.content-card__list__row:first-child, .content-card__list li.content-card__list__row:first-child {
  border-top: 0.1rem dotted var(--color-border-dark);
}
.content-cards__container.-purple .content-card__list__row, .content-cards__container.-purple .content-card__list li.content-card__list__row {
  border-bottom: 0.1rem dotted var(--color-border);
}
.content-cards__container.-purple .content-card__list__row:first-child, .content-cards__container.-purple .content-card__list li.content-card__list__row:first-child {
  border-top: 0.1rem dotted var(--color-border);
}

.content-card {
  display: block;
  overflow: hidden;
  text-decoration: none;
  margin: 0 auto;
  padding: 0;
  max-width: 39rem;
}
@media (--screen-large) {
  .content-card {
    max-width: 100%;
  }
  .splide__slide .content-card {
    padding: 1.5rem 0;
  }
}
.-rows .content-card {
  padding: 0;
  max-width: 45rem;
}
@media (--screen-large) {
  .-rows .content-card {
    max-width: 100%;
    display: flex;
  }
}

.content-card__media {
  position: relative;
  flex-basis: 30%;
  min-height: 5rem;
}
@media (--screen-all) {
  .content-card__media {
    flex-basis: 35%;
    min-height: 6rem;
  }
}
.-rows .content-card__media {
  max-width: 45rem;
}
@media (--screen-large) {
  .-rows .content-card__media {
    max-width: 100%;
  }
}

.content-card__info {
  position: relative;
  padding: 1.5rem 2rem;
  transition: opacity 0.3s ease;
  flex-basis: 70%;
  box-sizing: border-box;
}
@media (--screen-all) {
  .content-card__info {
    flex-basis: 65%;
  }
}
.-rows .content-card__info {
  padding: 1.5rem 0 0 0;
}
@media (--screen-large) {
  .-rows .content-card__info {
    padding: 0 0 0 3rem;
  }
}
.content-card__info :last-child {
  margin-bottom: 0;
}
.-my-tickets .content-card__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-card__like {
  display: block;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: var(--color-purple);
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  text-decoration: none;
  text-align: center;
  line-height: 5rem;
  transition: opacity 0.3s ease;
}
.content-card__like .icon::before {
  background: var(--color-general);
}
.content-card__like.-liked .icon::before {
  background: var(--color-rebellious);
}
.content-card__like[href]:hover, .content-card__like[href]:focus {
  opacity: 0.55;
}

.content-card__media__label {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-purple);
  font-size: 2.3rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  padding: 1.5rem;
  text-transform: uppercase;
  line-height: 1;
  transition: 0.3s ease;
  z-index: 1;
}
.content-card__media__label::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-general);
  opacity: 0.7;
  z-index: -1;
}
.content-card__media__label.-purple {
  color: var(--color-general);
}
.content-card__media__label.-purple::after {
  background: var(--color-purple);
}
a.content-card.-family:hover .content-card__media__label, a.content-card.-family:focus .content-card__media__label {
  background: var(--color-family);
}
a.content-card.-fun:hover .content-card__media__label, a.content-card.-fun:focus .content-card__media__label {
  background: var(--color-fun);
}
a.content-card.-rebellious:hover .content-card__media__label, a.content-card.-rebellious:focus .content-card__media__label {
  background: var(--color-rebellious);
}
a.content-card.-well-informed:hover .content-card__media__label, a.content-card.-well-informed:focus .content-card__media__label {
  background: var(--color-well-informed);
}
a.content-card.-general:hover .content-card__media__label, a.content-card.-general:focus .content-card__media__label {
  background: var(--color-general);
}

.content-card__img {
  display: block;
  position: relative;
  overflow: hidden;
}
.content-card__img img {
  width: 100%;
  display: block;
  min-height: 14rem;
}
.content-card__img::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../images/general.svg");
  background-size: 250rem 250rem;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: top left;
  will-change: transform;
  opacity: 0;
  transition: all 0.3s ease;
  background-color: var(--color-general);
}
@supports (mask-repeat: no-repeat) {
  .content-card__img::before {
    background: var(--color-general);
    mask-image: url("../images/general.svg");
    mask-repeat: no-repeat;
    mask-size: 250rem 250rem;
    mask-position: -100% -100%;
  }
}
a.content-card:hover .content-card__img::before, a.content-card:focus .content-card__img::before {
  animation-name: spin;
  opacity: 1;
}
.content-cards__container.-purple .content-card__img::before {
  background-color: var(--color-general);
}
.content-card.-family .content-card__img::before {
  background-color: var(--color-family);
  background-image: url("../images/family.svg");
}
@supports (mask-repeat: no-repeat) {
  .content-card.-family .content-card__img::before {
    background: var(--color-family);
    mask-image: url("../images/family.svg");
    mask-repeat: no-repeat;
    mask-size: 250rem 250rem;
  }
}
a.content-card.-family:hover .content-card__img::before, a.content-card.-family:focus .content-card__img::before {
  animation-name: spin-family;
  opacity: 1;
}
.content-card.-fun .content-card__img::before {
  background-color: var(--color-fun);
  background-image: url("../images/fun.svg");
}
@supports (mask-repeat: no-repeat) {
  .content-card.-fun .content-card__img::before {
    background: var(--color-fun);
    mask-image: url("../images/fun.svg");
    mask-repeat: no-repeat;
    mask-size: 250rem 250rem;
  }
}
a.content-card.-fun:hover .content-card__img::before, a.content-card.-fun:focus .content-card__img::before {
  animation-name: spin-fun;
  opacity: 1;
}
.content-card.-rebellious .content-card__img::before {
  background-color: var(--color-rebellious);
  background-image: url("../images/rebellious.svg");
  animation-duration: 2s;
}
@supports (mask-repeat: no-repeat) {
  .content-card.-rebellious .content-card__img::before {
    background: var(--color-rebellious);
    mask-image: url("../images/rebellious.svg");
    mask-repeat: no-repeat;
    mask-size: 250rem 250rem;
  }
}
a.content-card.-rebellious:hover .content-card__img::before, a.content-card.-rebellious:focus .content-card__img::before {
  animation-name: spin-rebellious;
  opacity: 1;
}
.content-card.-well-informed .content-card__img::before {
  background-color: var(--color-well-informed);
  background-image: url("../images/well-informed.svg");
}
@supports (mask-repeat: no-repeat) {
  .content-card.-well-informed .content-card__img::before {
    background: var(--color-well-informed);
    mask-image: url("../images/well-informed.svg");
    mask-repeat: no-repeat;
    mask-size: 250rem 250rem;
  }
}
a.content-card.-well-informed:hover .content-card__img::before, a.content-card.-well-informed:focus .content-card__img::before {
  animation-name: spin-well-informed;
  opacity: 1;
}

@keyframes spin {
  from {
    background-position: -43% -43%;
    mask-position: 80% 80%;
  }
  to {
    background-position: -43.85% -43.85%;
    mask-position: 79.15% 79.15%;
  }
}
@keyframes spin-family {
  from {
    background-position: -43% -43%;
    mask-position: 80% 80%;
  }
  to {
    background-position: -43.85% -43.85%;
    mask-position: 79.15% 79.15%;
  }
}
@keyframes spin-fun {
  from {
    background-position: -43% -50%;
    mask-position: 80% 80%;
  }
  to {
    background-position: -44.1% -50.7%;
    mask-position: 79.15% 79.15%;
  }
}
@keyframes spin-rebellious {
  from {
    background-position: -38% -38%;
    mask-position: 80% 80%;
  }
  to {
    background-position: -38.85% -38.85%;
    mask-position: 78% 78%;
  }
}
@keyframes spin-well-informed {
  from {
    background-position: -43% -43%;
    mask-position: 70% 70%;
  }
  to {
    background-position: -43.85% -43.85%;
    mask-position: 69.15% 69.15%;
  }
}
.content-card__title {
  display: block;
  text-decoration: none;
  font-size: 3rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  line-height: 1.15;
}
.content-cards__container.-purple .content-card__title {
  color: var(--color-general);
}
@media (--screen-large) {
  .content-card__title {
    max-width: 50%;
  }
}
.content-card__list.-overview .content-card__title {
  max-width: 100%;
}
.content-card__list.-events .content-card__title {
  margin-bottom: 3rem;
}
@media (--screen-large) {
  .content-card__list.-events .content-card__title {
    margin-bottom: 0;
  }
}
.content-card__title em {
  display: block;
  font-style: normal;
  font-size: 2.1rem;
  padding-top: 1rem;
}
.content-card__title + .button {
  margin-top: 2rem;
}
a.content-card:hover .content-card__title, a.content-card:focus .content-card__title {
  opacity: 0.55;
}

.content-card__info__extra {
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.7rem;
  color: var(--color-purple);
  opacity: 0.6;
}
.content-cards__container.-purple .content-card__info__extra {
  color: var(--color-general);
  opacity: 1;
}
@media (--screen-large) {
  .content-card__info__extra {
    font-size: 2.1rem;
  }
}

.content-cards__arrows {
  position: absolute;
  top: 0.2rem;
  right: 2rem;
  display: flex;
}
@media (--screen-large) {
  .content-cards__arrows {
    display: none;
  }
  .content-cards__arrows.-desktop {
    display: flex;
  }
}

.content-cards__arrow {
  background: var(--color-purple);
  border-radius: 50%;
  width: 4.2rem;
  height: 4.2rem;
  display: inline-block;
  cursor: pointer;
  transition: opacity 0.3s ease;
  outline: none;
  border: 0;
  opacity: 1;
  position: static;
  transform: translate(0);
  margin-left: 0.7rem;
}
.content-cards__arrow:hover, .content-cards__arrow:focus {
  opacity: 0.5;
}
.content-cards__container.-purple .content-cards__arrow {
  background: var(--color-general);
}
.content-cards__arrow .icon {
  display: none;
}
.content-cards__arrow.splide__arrow {
  position: relative;
  left: auto;
  right: auto;
}

.content-cards__button {
  position: absolute;
  top: 0.6rem;
  right: 2rem;
}
@media (--screen-mini-small) {
  .content-cards__button {
    display: none;
  }
}
.content-cards__button.-desktop {
  display: none;
}
@media (--screen-large) {
  .content-cards__button.-desktop {
    display: block;
  }
}
.content-cards__button.-mobile {
  position: relative;
  top: auto;
  right: auto;
  display: inline-block;
}
@media (--screen-large) {
  .content-cards__button.-mobile {
    display: none;
  }
}

.content-card__icon {
  width: 7rem;
  height: 7rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -3.5rem 0 0 -3.5rem;
  background: var(--color-purple);
  border-radius: 50%;
  text-align: center;
  line-height: 7rem;
  z-index: 1;
}

.content-card__info__summary {
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 2.1rem;
}
@media (--screen-large) {
  .content-card__info__summary {
    max-width: 60%;
  }
}
@media (--screen-all) {
  .content-card__info__summary {
    max-width: 70%;
  }
}
.content-card__list.-overview .content-card__info__summary {
  max-width: 100%;
}
.content-cards__container.-purple .content-card__info__summary {
  color: var(--color-general);
}
.content-card__list.-events .content-card__info__summary {
  display: none;
}
@media (--screen-large) {
  .content-card__list.-events .content-card__info__summary {
    display: block;
  }
}
.content-card__info__summary p:first-child {
  margin-top: 0;
}

.content-card__info__ticketlink {
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 2.5rem;
}
@media (--screen-large) {
  .content-card__info__ticketlink {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 20rem;
  }
}
@media (--screen-jumbotron) {
  .content-card__info__ticketlink {
    max-width: 100%;
  }
}
.content-cards__container.-purple .content-card__info__ticketlink {
  color: var(--color-general);
}
.content-card__info__ticketlink .icon {
  transform: translateY(-0.2rem);
  margin-right: 1rem;
}
a.content-card:hover .content-card__info__ticketlink, a.content-card:focus .content-card__info__ticketlink {
  text-decoration: underline;
}
.content-card__info__ticketlink.-no-icon .icon {
  display: none;
}
.button + .content-card__info__ticketlink {
  clear: both;
  margin-left: 2rem;
}

@media (--screen-large) {
  .content-card__info__downloadlink {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.content-card__info__refundlink {
  margin-top: 1.5rem;
}

.content-cards__action {
  display: block;
  text-align: center;
  padding-top: 1rem;
}
@media (--screen-medium-large) {
  .content-cards__action {
    display: none;
  }
}
.content-cards__action.-mobile {
  display: block;
}
@media (--screen-large) {
  .content-cards__action.-mobile {
    display: none;
  }
}
.content-cards__action.-all {
  display: block;
}
@media (--screen-medium-large) {
  .content-cards__action.-all {
    padding-top: 4rem;
  }
}
.content-cards__action.-button {
  padding-top: 3rem;
}
.content-cards__action .-mobile {
  display: block;
  padding-bottom: 1rem;
}
@media (--screen-medium-large) {
  .content-cards__action .-mobile {
    display: none;
  }
}

.content-card__info__program {
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.8rem;
  display: flex;
  flex-wrap: wrap;
  max-width: 28rem;
}
.content-card__info__program dt {
  flex: 1 1 calc(100% - 6rem);
}
.content-card__info__program dd {
  margin: 0;
  flex: 0 0 6rem;
  font-weight: var(--font-weight-medium);
  text-align: right;
}
a.content-card:hover .content-card__info__program, a.content-card:focus .content-card__info__program {
  opacity: 0.55;
}

.content-card__info__refund {
  margin-top: -1.6rem;
}
.content-card__info__refund dt {
  display: block;
  padding-bottom: 1rem;
}

.content-card__info__drinktokens {
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.6rem;
  color: var(--color-purple);
  opacity: 0.6;
  margin: 3.2rem 0 0;
}
.content-cards__container.-purple .content-card__info__drinktokens {
  color: var(--color-general);
  opacity: 1;
}
@media (--screen-large) {
  .content-card__info__drinktokens {
    font-size: 1.8rem;
    justify-self: flex-end;
    margin: 0;
  }
}

:root {
  --cookie-consent-max-width: 42em;
  --cookie-consent-color: inherit;
  --cookie-consent-background-color: white;
  --cookie-consent-options-color: inherit;
  --cookie-consent-options-background-color: lightgray;
  --cookie-consent-iframe-template-background-color: lightgray;
  --cookie-consent-iframe-template-max-width: 100%;
}

.cookie-consent {
  position: fixed;
  z-index: var(--cookie-consent-z-index, 999);
  inset-block-end: var(--cookie-consent-bottom, 0);
  transform: var(--cookie-consent-transform, none);
  overflow-y: auto;
  box-sizing: border-box;
  inline-size: 100%;
  max-inline-size: var(--cookie-consent-max-width);
  max-block-size: 100%;
  margin-block: var(--cookie-consent-margin-block, auto 0);
  margin-inline: var(--cookie-consent-margin-inline, auto 0);
  padding: var(--cookie-consent-padding, 2em);
  color: var(--cookie-consent-color);
  background-color: var(--cookie-consent-background-color);
  border: 0;
}
.cookie-consent a:not([class]) {
  color: var(--cookie-consent-link-color, inherit);
}
.cookie-consent.-is-modal {
  --cookie-consent-margin-inline: auto;
}
@media (min-width: 45rem), (min-height: 31rem) {
  .cookie-consent.-is-modal {
    --cookie-consent-bottom: 50%;
    --cookie-consent-transform: translate3d(0, calc(50% + .5px), 0);
  }
}
@media (max-width: 45rem), (max-height: 31rem) {
  .cookie-consent:not(.-is-modal) {
    --cookie-consent-max-width: none;
  }
}

.cookie-consent__title {
  margin-block-start: 0;
  color: inherit;
}

.cookie-consent__options {
  margin: 0 0 2em;
  padding: 1em;
  color: var(--cookie-consent-options-color);
  background: var(--cookie-consent-options-background-color);
}

.cookie-consent__option-description {
  display: block;
  margin-block: 0;
  font-size: 90%;
}

.cookie-consent__buttons:not([hidden]) {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.cookie-consent__buttons:not([hidden]) .-general {
  margin-block-start: 1em;
}

template.-pending-consent {
  cursor: pointer;
  position: relative;
  display: block;
  aspect-ratio: var(--cookie-consent-cover-aspect-ratio, 16/9);
  max-inline-size: var(--cookie-consent-iframe-template-max-width);
  min-block-size: 300px;
  margin-inline: auto;
  padding-block-end: var(--cookie-consent-iframe-template-bottom-whitespace, 4rem);
}
template.-pending-consent::before {
  content: attr(title);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  inline-size: 100%;
  block-size: 100%;
  padding: 2rem 4rem;
  color: var(--cookie-consent-iframe-template-color, inherit);
  text-align: center;
  background-color: var(--cookie-consent-iframe-template-background-color);
  background-image: var(--cookie-consent-cover-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

dialog::backdrop {
  display: none;
}

.cookie-consent {
  background-color: var(--color-infocard);
  border: 0;
  border-radius: 0.4rem;
  z-index: 9999;
  padding: 2rem;
  position: fixed;
  bottom: 0;
  transform: none;
  margin: 0;
  left: auto;
  top: auto;
  border: 0.3rem solid var(--color-purple);
}
@media (--screen-large) {
  .cookie-consent {
    padding: 2em;
  }
}

.cookie-consent__title {
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  font-size: 2.5rem;
  line-height: 1.15;
  margin-bottom: 2rem;
}
@media (--screen-medium) {
  .cookie-consent__title {
    font-size: 2.8rem;
  }
}
@media (--screen-large) {
  .cookie-consent__title {
    font-size: 3.5rem;
    margin-bottom: 3rem;
  }
}

.cookie-consent__inner, .cookie-consent__options .form__label.-checkbox {
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  font-size: 1.6rem;
  line-height: 1.5;
}
@media (--screen-medium) {
  .cookie-consent__inner, .cookie-consent__options .form__label.-checkbox {
    font-size: 1.8rem;
  }
}
@media (--screen-large) {
  .cookie-consent__inner, .cookie-consent__options .form__label.-checkbox {
    font-size: 2.1rem;
  }
}
.cookie-consent__inner a, .cookie-consent__options .form__label.-checkbox a {
  outline: none;
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  text-decoration: underline !important;
}
.cookie-consent__inner a:hover, .cookie-consent__options .form__label.-checkbox a:hover {
  text-decoration: none !important;
}
.cookie-consent__inner p, .cookie-consent__options .form__label.-checkbox p {
  margin-bottom: 2rem;
}
@media (--screen-large) {
  .cookie-consent__inner p, .cookie-consent__options .form__label.-checkbox p {
    margin-bottom: 3.2rem;
  }
}

.cookie-consent__options .form__label.-checkbox::before,
.cookie-consent__options .form__label.-checkbox::after {
  top: 0.6rem;
}

.cookie-consent__buttons {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.cookie-consent__buttons .button {
  margin: 0.5rem;
  font-size: 1.6rem;
}
@media (--screen-medium-large) {
  .cookie-consent__buttons .button {
    margin: 1rem;
    font-size: 1.8rem;
  }
}
.cookie-consent__buttons[hidden] {
  display: none;
}

.cookie-consent__option-description {
  order: 3;
  font-size: 1.8rem;
  padding: 1rem 0 0 3.2rem;
}

.h-hidden {
  display: none;
}

template.-has-iframe {
  display: block;
  width: 100%;
  padding-top: 56.25%;
  background-color: var(--color-infocard);
  cursor: pointer;
  position: relative;
  margin-bottom: 3rem;
}
@media (--screen-mini-small) {
  template.-has-iframe {
    width: calc(100% + 4rem);
  }
}
.page-detail__content__info template.-has-iframe, .event-detail__content template.-has-iframe {
  max-width: 75rem;
  margin: 0 auto 3rem;
}
@media (--screen-mini-small) {
  .page-detail__content__info template.-has-iframe, .event-detail__content template.-has-iframe {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
@media (--screen-large) {
  .page-detail__content__info template.-has-iframe, .event-detail__content template.-has-iframe {
    padding-top: 42.1875rem;
  }
}
template.-has-iframe::before {
  content: attr(title);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  box-sizing: border-box;
  line-height: 1.5;
  color: var(--color-purple);
  font-size: 1.8rem;
  text-transform: none;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
}

.cta__container {
  padding: 0 0 10rem;
}
.cta__container.-myab {
  padding: 2rem 0 4rem;
}
.cta__container.-center {
  padding: 4rem 0;
  text-align: center;
}
.cta__container.-bottom {
  padding: 0 0 3rem;
}
.cta__container.-mobile {
  display: block;
}
@media (--screen-full) {
  .cta__container.-mobile {
    display: none;
  }
}
.cta__container.-desktop {
  display: none;
}
@media (--screen-full) {
  .cta__container.-desktop {
    display: block;
  }
}

.cta {
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  text-decoration: none;
  flex-direction: column;
  background-color: var(--color-general);
}
@media (--screen-full) {
  .cta {
    flex-direction: row;
  }
}
.cta.-fun {
  background-color: var(--color-fun);
}
.cta.-family {
  background-color: var(--color-family);
}
.cta.-well-informed {
  background-color: var(--color-well-informed);
}
.cta.-rebellious {
  background-color: var(--color-rebellious);
}
.cta:hover, .cta:focus {
  background-color: var(--color-general-bg);
}
.cta:hover.-fun, .cta:focus.-fun {
  background-color: var(--color-fun-bg);
}
.cta:hover.-family, .cta:focus.-family {
  background-color: var(--color-family-bg);
}
.cta:hover.-well-informed, .cta:focus.-well-informed {
  background-color: var(--color-well-informed-bg);
}
.cta:hover.-rebellious, .cta:focus.-rebellious {
  background-color: var(--color-rebellious-bg);
}

.cta__title {
  max-width: 48rem;
  margin: 4rem 2rem;
  font-size: 2.6rem;
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  text-align: center;
  line-height: 1.3;
}
@media (--screen-small-large) {
  .cta__title {
    margin: 4rem;
  }
}
@media (--screen-full) {
  .cta__title {
    margin: 0 0 0 6rem;
    text-align: left;
    font-size: 2.8rem;
    max-width: 56rem;
  }
}

.cta__update {
  margin: 0 0 3rem;
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.6rem;
}
@media (--screen-full) {
  .cta__update {
    margin: 0 5rem 0 auto;
  }
}

.cta__list {
  margin: 0 0 3rem;
  list-style: none;
  padding: 0;
  display: block;
}
@media (--screen-full) {
  .cta__list {
    margin: 0 5rem 0 auto;
  }
}
.cta__list .cta__list__item {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  background: var(--color-purple);
  border-radius: 50%;
  text-align: center;
  line-height: 5rem;
  margin-right: 1.5rem;
  font-size: 0;
}
.cta__list .cta__list__item:last-child {
  margin-right: 0;
}

.cta__button {
  padding: 5rem 2rem;
  background: url("../images/general.svg") no-repeat 45% 55%;
  background-size: 250rem 250rem;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
}
@media (--screen-full) {
  .cta__button {
    margin-left: auto;
    width: auto;
    padding: 5rem 4rem;
  }
}
@media (--screen-full) {
  .cta__button {
    padding: 5rem 6rem;
    margin-left: 0;
  }
}
.cta.-fun .cta__button {
  background-image: url("../images/fun.svg");
}
.cta.-family .cta__button {
  background-image: url("../images/family.svg");
}
.cta.-rebellious .cta__button {
  background-image: url("../images/rebellious.svg");
}
.cta.-well-informed .cta__button {
  background-image: url("../images/well-informed.svg");
}
.cta__button .button {
  white-space: nowrap;
}

.dropdown {
  margin: 0;
  padding: 0;
  list-style: none;
}
.page-header .dropdown, .dropdown.-inline {
  display: inline-block;
}
.content-cards__container .dropdown {
  margin-bottom: 3rem;
}
.filter__entry .dropdown {
  margin: 0;
}
.dropdown.-language {
  text-transform: uppercase;
  margin: 0 1rem 0 1.5rem;
  display: none;
}
@media (--screen-large) {
  .dropdown.-language {
    display: inline-block;
  }
}

.dropdown__panel {
  position: absolute;
  background: var(--color-white);
  border-radius: 0.5rem;
  margin: 0;
  padding: 1rem 0;
  list-style: none;
  min-width: 15rem;
  max-width: 26rem;
  max-height: 0;
  overflow: hidden;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s ease, max-height 0.3s ease;
  z-index: -1;
  border: 0.1rem solid var(--color-border);
  transform: translateY(3rem);
}
.page-header .dropdown__panel {
  right: 0;
  left: auto;
}
@media (--screen-all) {
  .page-header .dropdown__panel {
    left: 0;
  }
}
@media (--screen-all) {
  .dropdown__panel {
    left: 0;
  }
}
.dropdown__panel.-button {
  transform: translateY(6rem);
}
.-language .dropdown__panel {
  min-width: 7rem;
}
.dropdown__panel li.-hidden {
  display: none;
}
@media (any-hover: none), (hover: none), (hover: hover) and (pointer: coarse) {
  .dropdown__panel.-open {
    opacity: 1;
    z-index: 3;
    max-height: none;
  }
}

.dropdown__trigger {
  position: relative;
  cursor: pointer;
}
.dropdown__trigger:after {
  content: "";
  width: 100%;
  height: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.page-header .dropdown__trigger a {
  margin-right: 0;
  padding-right: 0;
}
@media (hover: hover) and (pointer: fine) {
  .dropdown__trigger:hover:after {
    height: 6rem;
  }
  .dropdown__trigger:hover .dropdown__panel {
    opacity: 1;
    z-index: 3;
    max-height: none;
  }
}
.dropdown__trigger .icon::before {
  margin-left: 0.4rem;
}

.dropdown__link {
  display: block;
  padding: 1rem 1.5rem;
  text-decoration: none;
  font-size: 1.6rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  cursor: pointer;
}
.dropdown__link:hover, .dropdown__link:focus {
  background: var(--color-general);
}

.dropdown__group {
  background: var(--color-purple);
  color: var(--color-general);
  font-size: 1.6rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  text-align: center;
  padding: 1rem 1.5rem;
  cursor: default;
}

.event-video__container {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 3rem;
}
@media (--screen-mini-small) {
  .event-video__container {
    width: calc(100% + 4rem);
  }
}
.page-detail__content__info .event-video__container, .event-detail__content .event-video__container {
  max-width: 75rem;
  margin: 0 auto 3rem;
}
@media (--screen-mini-small) {
  .page-detail__content__info .event-video__container, .event-detail__content .event-video__container {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
@media (--screen-large) {
  .page-detail__content__info .event-video__container, .event-detail__content .event-video__container {
    padding-top: 42.1875rem;
  }
}
.event-video__container button {
  outline: none;
}

.event-video, .event-video__cover {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  z-index: 0;
  padding: 0;
}

.event-video__cover {
  z-index: 1;
  text-align: center;
  cursor: pointer;
}
.event-video__cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.event-video__cover .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  border: 0.7rem solid var(--color-general);
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  margin: -4.7rem 0 0 -4.7rem;
  line-height: 8rem;
  transition: opacity 0.3s ease;
  text-indent: 1rem;
}
@media (--screen-full) {
  .event-video__cover .icon {
    border: 1rem solid var(--color-general);
    width: 12rem;
    height: 12rem;
    margin: -7rem 0 0 -7rem;
    line-height: 12rem;
    text-indent: 1.5rem;
  }
}
.event-detail__content.-family .event-video__cover .icon {
  border-color: var(--color-family);
}
.event-detail__content.-fun .event-video__cover .icon {
  border-color: var(--color-fun);
}
.event-detail__content.-rebellious .event-video__cover .icon {
  border-color: var(--color-rebellious);
}
.event-detail__content.-well-informed .event-video__cover .icon {
  border-color: var(--color-well-informed);
}
.event-video__cover:hover .icon, .event-video__cover:focus .icon {
  opacity: 0.6;
}
.event-video__container.-playing .event-video__cover {
  display: none;
}

.event-video {
  display: none;
}
.event-video__container.-playing .event-video {
  display: block;
}

.fb-video {
  margin-bottom: 3rem;
}
.page-detail__content__info .fb-video, .event-detail__content .fb-video {
  max-width: 75rem;
  margin: 0 auto 3rem;
}
@media (--screen-mini-small) {
  .page-detail__content__info .fb-video, .event-detail__content .fb-video {
    display: inline-block;
    width: calc(100% + 4rem) !important;
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
.fb-video span, .fb-video iframe {
  width: 100% !important;
}

.event-detail__header {
  position: relative;
}
.event-detail__header .site-container {
  position: relative;
  z-index: 1;
}

.event-detail__bg {
  background-color: var(--color-general);
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.3s ease;
}
.event-detail__bg::after {
  content: "";
  position: absolute;
  z-index: 0;
  top: -250rem;
  left: 50%;
  margin-left: -250rem;
  width: 500rem;
  height: 500rem;
  background-image: url("../images/general.svg");
  background-size: 500rem 500rem;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center;
  will-change: transform;
  transition: background-image 0.3s ease;
  background-position: center center;
  animation-name: scale;
}
.event-detail__header.-fun .event-detail__bg {
  background-color: var(--color-fun);
}
.event-detail__header.-fun .event-detail__bg::after {
  background-image: url("../images/fun.svg");
}
.event-detail__header.-family .event-detail__bg {
  background-color: var(--color-family);
}
.event-detail__header.-family .event-detail__bg::after {
  background-image: url("../images/family.svg");
}
.event-detail__header.-rebellious .event-detail__bg {
  background-color: var(--color-rebellious);
}
.event-detail__header.-rebellious .event-detail__bg::after {
  background-image: url("../images/rebellious.svg");
}
.event-detail__header.-well-informed .event-detail__bg {
  background-color: var(--color-well-informed);
}
.event-detail__header.-well-informed .event-detail__bg::after {
  background-image: url("../images/well-informed.svg");
}

@keyframes scale {
  from {
    transform: scale(0.75);
  }
  to {
    transform: scale(0.85);
  }
}
.event-detail__header__info {
  position: relative;
  z-index: 1;
  font: 0/0 sans-serif;
}
.event-detail__header__info img {
  width: 100%;
  min-height: 35rem;
  max-height: 60rem;
  object-fit: cover;
}
.event-detail__header__info::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(48, 0, 41, 0) 45.49%, rgba(48, 0, 41, 0.5) 96.74%);
}

.event-detail__header__info__content {
  padding: 2rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 2.8rem;
  color: var(--color-white);
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (--screen-large) {
  .event-detail__header__info__content {
    max-width: calc(100% - 34rem);
  }
}
@media (--screen-full) {
  .event-detail__header__info__content {
    padding: 5rem 4rem;
  }
}
.event-detail__header__info__content h1 {
  color: var(--color-white) !important;
  font-family: var(--font-general);
  font-size: 4.2rem !important;
  line-height: 1 !important;
  margin: 0 0 1rem !important;
}
@media (--screen-large) {
  .event-detail__header__info__content h1 {
    font-size: 6.8rem !important;
    margin: 0 0 2rem !important;
  }
}
.event-detail__header.-fun .event-detail__header__info__content h1 {
  font-family: var(--font-fun);
  letter-spacing: 0.05rem;
}
.event-detail__header.-family .event-detail__header__info__content h1 {
  font-family: var(--font-family);
  letter-spacing: -0.3rem;
}
.event-detail__header.-rebellious .event-detail__header__info__content h1 {
  font-family: var(--font-rebellious);
  letter-spacing: -0.4rem;
}
.event-detail__header.-well-informed .event-detail__header__info__content h1 {
  font-family: var(--font-well-informed);
}
.event-detail__header__info__content h2 {
  color: var(--color-white) !important;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular) !important;
  font-size: 2.1rem !important;
  line-height: 1.15 !important;
  margin: 0 !important;
}
@media (--screen-large) {
  .event-detail__header__info__content h2 {
    font-size: 2.8rem !important;
  }
}
.event-detail__header__info__content h3 {
  color: var(--color-white) !important;
  text-transform: uppercase !important;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular) !important;
  font-size: 1.8rem !important;
  line-height: 1.15 !important;
  margin: 0 0 1rem !important;
}
@media (--screen-large) {
  .event-detail__header__info__content h3 {
    font-size: 3rem !important;
    margin: 0 0 2rem !important;
  }
}

.event-detail__header__label {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.7rem;
  color: var(--color-general);
  padding: 0.8rem 1.2rem;
  line-height: 1.2;
  text-decoration: none;
  transition: opacity 0.3s ease;
  display: none;
}
@media (--screen-large) {
  .event-detail__header__label {
    display: block;
  }
}
.event-detail__header__label:hover, .event-detail__header__label:focus {
  text-decoration: underline;
  opacity: 0.6;
}

.event-detail__label .button {
  margin-top: 0.5rem;
}
@media (--screen-large) {
  .event-detail__label {
    display: none;
  }
}

.event-detail__content {
  padding-bottom: 4rem;
}
@media (--screen-large) {
  .event-detail__content {
    padding-bottom: 10rem;
  }
}
.event-detail__content .site-container {
  display: flex;
  flex-direction: column;
}
@media (--screen-large) {
  .event-detail__content .site-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.event-detail__content__info {
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  order: 2;
  box-sizing: border-box;
}
@media (--screen-mini-medium) {
  .event-detail__content.-project .event-detail__content__info {
    padding: 2rem 0 0;
  }
}
@media (--screen-large) {
  .event-detail__content__info {
    order: 1;
    padding: 6rem 0 0;
  }
}
@media (--screen-full) {
  .event-detail__content__info {
    padding: 6rem 2rem 0;
    margin: 0 0 0 4rem;
  }
}
.event-detail__content__info a {
  color: var(--color-purple);
  text-decoration: underline;
  font-family: var(--font-general);
  font-weight: var(--font-weight-medium);
}
.event-detail__content__info a:hover, .event-detail__content__info a:focus {
  text-decoration: none;
}
.event-detail__content.-fun .event-detail__content__info a {
  color: var(--color-fun);
}
.event-detail__content.-family .event-detail__content__info a {
  color: var(--color-family);
}
.event-detail__content.-rebellious .event-detail__content__info a {
  color: var(--color-rebellious);
}
.event-detail__content.-well-informed .event-detail__content__info a {
  color: var(--color-well-informed);
}

.event-detail__content__aside {
  width: 100%;
  max-width: 85rem;
  margin: 0 auto 2rem auto;
  z-index: 1;
  background: var(--color-general);
  order: 1;
}
.event-detail__content.-family .event-detail__content__aside {
  background: var(--color-family-bg);
}
.event-detail__content.-fun .event-detail__content__aside {
  background: var(--color-fun-bg);
}
.event-detail__content.-rebellious .event-detail__content__aside {
  background: var(--color-rebellious-bg);
}
.event-detail__content.-well-informed .event-detail__content__aside {
  background: var(--color-well-informed-bg);
}
@media (--screen-large) {
  .event-detail__content__aside {
    position: sticky;
    top: 9rem;
    margin: -6.5rem 0 0 7rem;
    align-self: flex-start;
    order: 2;
    max-width: 34rem;
  }
}
@media (--screen-full) {
  .event-detail__content__aside {
    top: 9rem;
    margin: -9rem 0 0 7rem;
  }
}
.event-detail__content__aside.-no-button {
  margin-top: 0;
}

.event-detail__ticket-block {
  padding: 0.5rem 2rem;
  font-size: 1.8rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
}
@media (--screen-full) {
  .event-detail__ticket-block {
    padding: 1.5rem 4.5rem;
  }
}
.event-detail__ticket-block small {
  font-size: 1.6rem;
  opacity: 0.4;
}
.event-detail__ticket-block small.-info {
  font-size: 1.2rem;
  padding-left: 2.7rem;
  display: block;
}
.event-detail__ticket-block dl {
  margin: 0;
  padding: 1.5rem 0;
  border-bottom: 0.1rem dotted var(--color-border-dark);
  display: flex;
  flex-wrap: wrap;
}
.event-detail__ticket-block dl:last-child {
  border-bottom: 0;
}
.event-detail__ticket-block dl dt {
  flex: 1 1 calc(100% - 6rem);
}
.event-detail__ticket-block dl dt.-full {
  width: 100%;
}
.event-detail__ticket-block dl dt.-info {
  font-weight: var(--font-weight-medium);
}
.event-detail__ticket-block dl dt .icon {
  width: 2.7rem;
  top: -0.3rem;
}
.event-detail__ticket-block dl dd {
  margin: 0;
  flex: 0 0 6rem;
  font-weight: var(--font-weight-medium);
  text-align: right;
}

.event-detail__ticket-button {
  height: 6.5rem;
  background: var(--color-purple);
  border: 0;
  text-align: center;
  line-height: 6.5rem;
  display: block;
  color: var(--color-general);
  font-size: 2.5rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  text-decoration: none;
  transition: opacity 0.3s ease;
}
.event-detail__content.-family .event-detail__ticket-button {
  color: var(--color-family);
}
.event-detail__content.-fun .event-detail__ticket-button {
  color: var(--color-fun);
}
.event-detail__content.-rebellious .event-detail__ticket-button {
  color: var(--color-rebellious);
}
.event-detail__content.-well-informed .event-detail__ticket-button {
  color: var(--color-well-informed);
}
@media (--screen-full) {
  .event-detail__ticket-button {
    height: 9rem;
    line-height: 9rem;
    font-size: 3.2rem;
  }
}
.event-detail__ticket-button:hover, .event-detail__ticket-button:focus {
  opacity: 0.6;
}
.event-detail__ticket-button.-not-available {
  pointer-events: none;
}
.event-detail__ticket-button.-small {
  font-size: 2.1rem;
}
@media (--screen-medium-large) {
  .event-detail__ticket-button.-small {
    font-size: 2.3rem;
  }
}
.event-detail__ticket-button.-smaller {
  font-size: 1.8rem;
}
@media (--screen-medium-large) {
  .event-detail__ticket-button.-smaller {
    font-size: 2.1rem;
  }
}

.event-detail__ticket-block__location {
  color: var(--color-purple);
  text-decoration: underline;
}
.event-detail__ticket-block__location:hover, .event-detail__ticket-block__location:focus {
  text-decoration: none;
}

.event-detail__slogan {
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  line-height: 1.15;
  font-size: 2.6rem;
}
@media (--screen-large) {
  .event-detail__slogan {
    font-size: 3.5rem;
  }
}

.event-detail__intro {
  max-height: 16rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 1.5rem;
}
.event-detail__intro::after {
  transition: all 1s ease;
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  display: block;
  opacity: 1;
  width: 100%;
  height: 3rem;
  background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0.3));
}
.event-detail__intro.-active {
  max-height: none;
}
.event-detail__intro.-active::after {
  opacity: 0;
  display: none;
}

.event-detail__logos {
  display: flex;
  align-items: center;
  margin: 2.5rem 0;
  flex-wrap: wrap;
}
.event-detail__logos img {
  inline-size: auto;
  max-width: 100%;
  max-height: 7.5rem;
  margin: 0 1rem 1rem 0;
}

.event-detail__content #relive {
  display: block;
  padding-top: 8rem;
  margin-top: -8rem;
}
.event-detail__content #media, .event-detail__content #artists, .event-detail__content #photos {
  padding-top: 14rem;
  margin-top: -14rem;
}
.event-detail__content #media.-top, .event-detail__content #artists.-top, .event-detail__content #photos.-top {
  margin-top: -8rem;
}

.faq-container {
  margin-block: 2.5rem;
}

.button, .button a {
  display: inline-block;
  background: var(--color-purple);
  border: 0;
  color: var(--color-general) !important;
  font-size: 1.8rem;
  line-height: 1.15;
  font-family: var(--font-general) !important;
  font-weight: var(--font-weight-regular) !important;
  border-radius: 3rem;
  padding: 1.5rem 2rem !important;
  text-decoration: none !important;
  transition: all 0.3s ease;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: none;
}
.button:hover, .button:focus, .info-card a:hover .button, .info-card a:focus .button, .cta__container a.cta:hover .button, .cta__container a.cta:focus .button, .button a:hover, .button a:focus, .info-card a:hover .button a, .info-card a:focus .button a, .cta__container a.cta:hover .button a, .cta__container a.cta:focus .button a {
  background: var(--color-purple-hover);
  color: var(--color-general) !important;
}
.button:hover .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .button:focus .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .info-card a:hover .button .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .info-card a:focus .button .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .cta__container a.cta:hover .button .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .cta__container a.cta:focus .button .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .button a:hover .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .button a:focus .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .info-card a:hover .button a .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .info-card a:focus .button a .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .cta__container a.cta:hover .button a .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .cta__container a.cta:focus .button a .icon:not(.-arrow-down):not(.-arrow-up):not(.-download) {
  transform: translateX(0.5rem);
}
.button:disabled, .button[disabled], .button a:disabled, .button a[disabled] {
  opacity: 0.4;
  pointer-events: none;
}
.button.-general, .button a.-general {
  background: var(--color-general);
  color: var(--color-purple) !important;
}
.event-detail__content.-family .button.-general, .event-detail__content.-family .button a.-general {
  background: var(--color-family);
}
.event-detail__content.-fun .button.-general, .event-detail__content.-fun .button a.-general {
  background: var(--color-fun);
}
.event-detail__content.-rebellious .button.-general, .event-detail__content.-rebellious .button a.-general {
  background: var(--color-rebellious);
}
.event-detail__content.-well-informed .button.-general, .event-detail__content.-well-informed .button a.-general {
  background: var(--color-well-informed);
}
.button.-general:hover, .button.-general:focus, .content-card:not(.-concert):hover .button.-general, .content-card:not(.-concert):focus .button.-general, .button a.-general:hover, .button a.-general:focus, .content-card:not(.-concert):hover .button a.-general, .content-card:not(.-concert):focus .button a.-general {
  background: var(--color-general-hover);
  color: var(--color-purple) !important;
}
.event-detail__content .button.-general:hover, .event-detail__content .button.-general:focus, .event-detail__content .content-card:not(.-concert):hover .button.-general, .event-detail__content .content-card:not(.-concert):focus .button.-general, .event-detail__content .button a.-general:hover, .event-detail__content .button a.-general:focus, .event-detail__content .content-card:not(.-concert):hover .button a.-general, .event-detail__content .content-card:not(.-concert):focus .button a.-general {
  background: var(--color-purple);
  color: var(--color-general) !important;
}
.event-detail__content.-family .button.-general:hover, .event-detail__content.-family .button.-general:focus, .event-detail__content.-family .content-card:not(.-concert):hover .button.-general, .event-detail__content.-family .content-card:not(.-concert):focus .button.-general, .event-detail__content.-family .button a.-general:hover, .event-detail__content.-family .button a.-general:focus, .event-detail__content.-family .content-card:not(.-concert):hover .button a.-general, .event-detail__content.-family .content-card:not(.-concert):focus .button a.-general {
  color: var(--color-family) !important;
}
.event-detail__content.-fun .button.-general:hover, .event-detail__content.-fun .button.-general:focus, .event-detail__content.-fun .content-card:not(.-concert):hover .button.-general, .event-detail__content.-fun .content-card:not(.-concert):focus .button.-general, .event-detail__content.-fun .button a.-general:hover, .event-detail__content.-fun .button a.-general:focus, .event-detail__content.-fun .content-card:not(.-concert):hover .button a.-general, .event-detail__content.-fun .content-card:not(.-concert):focus .button a.-general {
  color: var(--color-fun) !important;
}
.event-detail__content.-rebellious .button.-general:hover, .event-detail__content.-rebellious .button.-general:focus, .event-detail__content.-rebellious .content-card:not(.-concert):hover .button.-general, .event-detail__content.-rebellious .content-card:not(.-concert):focus .button.-general, .event-detail__content.-rebellious .button a.-general:hover, .event-detail__content.-rebellious .button a.-general:focus, .event-detail__content.-rebellious .content-card:not(.-concert):hover .button a.-general, .event-detail__content.-rebellious .content-card:not(.-concert):focus .button a.-general {
  color: var(--color-rebellious) !important;
}
.event-detail__content.-well-informed .button.-general:hover, .event-detail__content.-well-informed .button.-general:focus, .event-detail__content.-well-informed .content-card:not(.-concert):hover .button.-general, .event-detail__content.-well-informed .content-card:not(.-concert):focus .button.-general, .event-detail__content.-well-informed .button a.-general:hover, .event-detail__content.-well-informed .button a.-general:focus, .event-detail__content.-well-informed .content-card:not(.-concert):hover .button a.-general, .event-detail__content.-well-informed .content-card:not(.-concert):focus .button a.-general {
  color: var(--color-well-informed) !important;
}
.button.-general:hover .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .button.-general:focus .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .content-card:not(.-concert):hover .button.-general .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .content-card:not(.-concert):focus .button.-general .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .button a.-general:hover .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .button a.-general:focus .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .content-card:not(.-concert):hover .button a.-general .icon:not(.-arrow-down):not(.-arrow-up):not(.-download), .content-card:not(.-concert):focus .button a.-general .icon:not(.-arrow-down):not(.-arrow-up):not(.-download) {
  transform: translateX(0.5rem);
}
.button[data-theme=general], .button a[data-theme=general] {
  background: var(--color-general);
  color: var(--color-purple) !important;
}
.button[data-theme=general]:hover, .button[data-theme=general]:focus, .button a[data-theme=general]:hover, .button a[data-theme=general]:focus {
  background: var(--color-general-hover);
  color: var(--color-purple) !important;
}
.button[data-theme=family], .button a[data-theme=family] {
  background: var(--color-family);
  color: var(--color-purple) !important;
}
.button[data-theme=family][data-style=outline], .button a[data-theme=family][data-style=outline] {
  background-color: transparent;
  border-color: var(--color-family);
  color: var(--color-family) !important;
}
.button[data-theme=family]:hover, .button[data-theme=family]:focus, .button a[data-theme=family]:hover, .button a[data-theme=family]:focus {
  background: var(--color-family-bg);
  color: var(--color-purplefamily) !important;
}
.button[data-theme=family]:hover[data-style=outline], .button[data-theme=family]:focus[data-style=outline], .button a[data-theme=family]:hover[data-style=outline], .button a[data-theme=family]:focus[data-style=outline] {
  background-color: var(--color-family);
  color: var(--color-purple) !important;
}
.button[data-theme=fun], .button a[data-theme=fun] {
  background: var(--color-fun);
  color: var(--color-purple) !important;
}
.button[data-theme=fun][data-style=outline], .button a[data-theme=fun][data-style=outline] {
  background-color: transparent;
  border-color: var(--color-fun);
  color: var(--color-purple) !important;
}
.button[data-theme=fun]:hover, .button[data-theme=fun]:focus, .button a[data-theme=fun]:hover, .button a[data-theme=fun]:focus {
  background: var(--color-fun-bg);
  color: var(--color-purple) !important;
}
.button[data-theme=fun]:hover[data-style=outline], .button[data-theme=fun]:focus[data-style=outline], .button a[data-theme=fun]:hover[data-style=outline], .button a[data-theme=fun]:focus[data-style=outline] {
  background-color: var(--color-fun);
  color: var(--color-purple) !important;
}
.button[data-theme=rebellious], .button a[data-theme=rebellious] {
  background: var(--color-rebellious);
  color: var(--color-purple) !important;
}
.button[data-theme=rebellious][data-style=outline], .button a[data-theme=rebellious][data-style=outline] {
  background-color: transparent;
  border-color: var(--color-rebellious);
  color: var(--color-rebellious) !important;
}
.button[data-theme=rebellious]:hover, .button[data-theme=rebellious]:focus, .button a[data-theme=rebellious]:hover, .button a[data-theme=rebellious]:focus {
  background: var(--color-rebellious-bg);
  color: var(--color-purple) !important;
}
.button[data-theme=rebellious]:hover[data-style=outline], .button[data-theme=rebellious]:focus[data-style=outline], .button a[data-theme=rebellious]:hover[data-style=outline], .button a[data-theme=rebellious]:focus[data-style=outline] {
  background-color: var(--color-rebellious);
  color: var(--color-general) !important;
}
.button[data-theme=well-informed], .button a[data-theme=well-informed] {
  background: var(--color-well-informed);
  color: var(--color-purple) !important;
}
.button[data-theme=well-informed][data-style=outline], .button a[data-theme=well-informed][data-style=outline] {
  border-color: var(--color-well-informed);
  color: var(--color-purple) !important;
}
.button[data-theme=well-informed]:hover, .button[data-theme=well-informed]:focus, .button a[data-theme=well-informed]:hover, .button a[data-theme=well-informed]:focus {
  background: var(--color-well-informed-bg);
  color: var(--color-purple) !important;
}
.button[data-theme=well-informed]:hover[data-style=outline], .button[data-theme=well-informed]:focus[data-style=outline], .button a[data-theme=well-informed]:hover[data-style=outline], .button a[data-theme=well-informed]:focus[data-style=outline] {
  background-color: var(--color-well-informed);
  color: var(--color-purple) !important;
}
[data-block-type=button-row][data-theme] .button, [data-block-type=button-row][data-theme] .button a {
  box-shadow: 0 0.5rem 0.5rem 0 var(--color-purple-hover);
}
[data-block-type=button-row][data-theme=general] .button, [data-block-type=button-row][data-theme=general] .button a {
  box-shadow: none;
}
.button.-full, .button[data-size=large], .button a.-full, .button a[data-size=large] {
  display: block;
  width: 100%;
}
.button.-outline, .button[data-style=outline], .button a.-outline, .button a[data-style=outline] {
  background-color: transparent;
  border: 0.1rem solid var(--color-purple);
  color: var(--color-purple) !important;
}
.button.-outline:hover, .button.-outline:focus, .button[data-style=outline]:hover, .button[data-style=outline]:focus, .button a.-outline:hover, .button a.-outline:focus, .button a[data-style=outline]:hover, .button a[data-style=outline]:focus {
  background-color: var(--color-purple);
  color: var(--color-general) !important;
}
.button .icon, .button a .icon {
  margin-left: 1rem;
  transition: all 0.3s ease;
}
.button.-square, .button a.-square {
  border-radius: 0;
  padding: 0.8rem 1.2rem;
  line-height: 1.2;
  font-size: 1.7rem;
}
.button.-social, .button a.-social {
  background-color: transparent;
  border: 0.2rem solid var(--color-purple);
  color: var(--color-purple) !important;
  padding: 1.5rem 8rem !important;
  text-align: left;
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  border-radius: 10rem;
  font-size: 1.6rem;
  line-height: 1.3;
}
.button.-social::before, .button a.-social::before {
  content: "";
  display: block;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: var(--color-purple);
}
.button.-social.-facebook::before, .button a.-social.-facebook::before {
  background: var(--color-facebook);
}
.button.-social.-spotify::before, .button a.-social.-spotify::before {
  background: none;
}
.button.-social small, .button a.-social small {
  display: block;
  color: #AB94A4 !important;
  font-size: 1.6rem;
  padding-top: 0.5rem;
}
.button.-social:hover, .button.-social:focus, .button a.-social:hover, .button a.-social:focus {
  background-color: var(--color-purple-hover);
  color: var(--color-general) !important;
}
.button.-social .button__status, .button a.-social .button__status {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  inline-size: unset;
  block-size: unset;
}
.button.-social .button__icon, .button a.-social .button__icon {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  width: 4rem;
  height: 4rem;
  margin: 0;
  text-align: center;
  line-height: 4rem;
}

div.button {
  padding: 0 !important;
  background: none;
  color: var(--color-purple) !important;
  margin: 1rem 0 3.2rem;
}
p + div.button {
  margin-top: 0;
}
div.button + p {
  margin-top: 0;
}

.faq-item {
  border: 0 solid var(--color-faq);
  border-block-end-width: 0.1rem;
}

.faq-item__title, h3.faq-item__title {
  cursor: pointer;
  position: relative;
  display: block;
  margin: 0;
  padding: 1.7rem 4rem 1.6rem 0;
  font-size: 2.5rem;
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  line-height: 1.44;
  text-align: start;
}
.faq-item__title::after, h3.faq-item__title::after {
  content: "";
  position: absolute;
  inset-block-start: 50%;
  inset-inline-end: 0.5rem;
  transform: translateY(-50%);
  display: block;
  inline-size: 2.2rem;
  block-size: 2.2rem;
  background-color: var(--color-purple);
  mask-image: url("../icon/arrow-down.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
}
.faq-item.-open .faq-item__title::after, .faq-item.-open h3.faq-item__title::after {
  transform: translateY(-50%) rotate(180deg);
}

.faq-item__content {
  display: none;
  margin: 0;
  padding-block-end: 1.7rem;
  font-size: 1.8rem;
}
.faq-item.-open .faq-item__content, [data-gjs-type=wrapper] .faq-item__content {
  display: block;
}
.faq-item__content > *:first-child {
  margin-block-start: 0;
}
.faq-item__content > *:last-child {
  margin-block-end: 0;
}

.page-detail__content__info .faq {
  max-width: 75rem;
  margin: 0 auto 4rem auto;
}

.faq__list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 0.1rem solid var(--color-border-dark);
}

.faq__list__item {
  margin: 0;
  border-bottom: 0.1rem solid var(--color-border-dark);
  padding: 3rem 0;
}

.faq-item__link {
  text-decoration: none !important;
  outline: none;
}

.faq-item__trigger {
  display: none;
}

.faq-item__question {
  display: block;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 2.5rem;
  color: var(--color-purple);
  cursor: pointer;
  transition: opacity 0.3s ease;
  padding-left: 5rem;
  position: relative;
}
.faq-item__question:hover, .faq-item__question:focus {
  opacity: 0.6;
}

.faq-item__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.6s ease;
}
.faq-item__content :not(li):first-child {
  margin-top: 0;
  padding-top: 0;
}
.faq-item__content :not(li):last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.faq-item.-open .faq-item__content, [data-gjs-type=wrapper] .faq-item__content {
  max-height: none;
}

.faq-item__button {
  background: transparent;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: -0.2rem;
  left: 0;
  outline: none;
  transition: all 0.3s ease;
  text-align: center;
  font: 0/4rem sans-serif;
}
.faq-item__trigger:checked + .faq-item__question .faq-item__button {
  transform: rotate(180deg);
}

figure.image {
  margin: 2rem 0;
}
figure.image figcaption {
  font-size: 1.6rem;
  text-align: left;
}
figure.image img {
  max-width: 100%;
  max-height: 75rem;
}

.file-element {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 1rem;
  border-bottom: 0.1rem solid var(--color-faq);
}

.file-element__data {
  margin: 0;
}

.file-element__name {
  margin: 0;
  font-size: 2.5rem;
  font-weight: var(--font-weight-normal);
  line-height: 1.44;
  letter-spacing: 0.04rem;
}

.file-element__meta {
  display: block;
  font-size: 1.2rem;
  line-height: 1.5;
  color: var(--color-title);
}
.file-element__meta span {
  margin-inline-end: 1rem;
}
.file-element__meta span:last-child {
  margin-inline-end: 0;
}

.file-element__file {
  margin-inline-start: 2rem;
}
.file-element__file .icon {
  margin: 0 1rem 0 0;
}

.file-list {
  margin: 3rem 0 5rem;
  padding: 0;
  list-style: none;
  border: 0 solid var(--color-border);
  border-block-start-width: 0.2rem;
  border-block-end-width: 0.2rem;
}

.file-list > li {
  margin: 0;
  padding: 0;
  border: 0 solid var(--color-border);
  border-block-end-width: 0.2rem;
}
.file-list > li:last-child {
  border-block-end-width: 0;
}

.filter {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.filter.-active {
  max-height: none;
  overflow: visible;
  padding-bottom: 1rem;
}
@media (--screen-jumbotron) {
  .filter {
    margin-bottom: 3rem;
    max-height: none;
    overflow: visible;
  }
}

.filter__toggle {
  margin-bottom: 2rem;
  margin-right: 2rem;
}
@media (--screen-jumbotron) {
  .filter__toggle {
    display: none;
  }
}

.filter__checkbox {
  display: none;
}
.filter__checkbox:checked + .dropdown__link {
  background-color: var(--color-purple);
  color: var(--color-general);
}

.filter__entry {
  margin: 0 1rem 1rem 0;
  white-space: nowrap;
  display: inline-block;
}
@media (--screen-medium-large) {
  .filter__entry {
    margin: 0 1.5rem 1.5rem 0;
  }
}
@media (--screen-mini-jumbotron) {
  .filter__entry.-ja {
    order: 3;
  }
  .filter__entry.-free {
    order: 3;
  }
  .filter__entry.-genre {
    order: 1;
    margin-right: 1rem !important;
  }
  .filter__entry.-month {
    order: 2;
  }
}
.filter__entry:last-child {
  margin-right: 0;
}

.filter__col {
  margin: 0 0 1rem 0;
}
@media (--screen-mini-jumbotron) {
  .filter__col {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (--screen-medium-large) {
  .filter__col {
    margin: 0 2rem 2rem 0;
  }
}
.filter__col.-auto {
  flex-basis: 100%;
  order: -1;
}
@media (--screen-jumbotron) {
  .filter__col.-auto {
    position: absolute;
    right: 0;
    order: 2;
    margin-right: 0;
  }
}

.filter__options {
  clear: both;
  width: 100%;
}
@media (--screen-mini-jumbotron) {
  .filter__col .filter__options {
    display: none;
  }
}
.filter__options.-bottom {
  margin-bottom: 2rem;
}
@media (--screen-jumbotron) {
  .filter__options.-bottom {
    display: none;
  }
}

.filter__label {
  display: inline-flex;
  background: var(--color-general);
  border: 0;
  color: var(--color-purple);
  font-size: 1.8rem;
  line-height: 1.5;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  border-radius: 3rem;
  padding: 1.5rem 2rem;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  align-items: center;
}
.filter__label.-switch {
  padding: 0;
}
.filter__label .icon.-arrow-down {
  margin-left: 1rem;
}
.filter__label .icon.-heart {
  margin-right: 0.6rem;
}
.filter__label .filter__label__on, .filter__label .filter__label__off {
  padding: 1.5rem;
  border-radius: 3rem;
  transition: all 0.3s ease;
}
@media (--screen-medium-large) {
  .filter__label .filter__label__on, .filter__label .filter__label__off {
    padding: 1.5rem 2rem;
  }
}
.filter__label .filter__label__off {
  background: var(--color-purple);
  color: var(--color-general);
}
.filter__label .filter__label__off:hover {
  background: var(--color-purple-hover);
}
.filter__checkbox:checked + .filter__label {
  background: var(--color-purple);
  color: var(--color-general);
}
.filter__checkbox:checked + .filter__label.-switch {
  background: var(--color-general);
  color: var(--color-purple);
}
.filter__checkbox:checked + .filter__label.-switch .filter__label__on {
  background: var(--color-purple);
  color: var(--color-general);
}
.filter__checkbox:checked + .filter__label.-switch .filter__label__on:hover {
  background: var(--color-purple-hover);
}
.filter__checkbox:checked + .filter__label.-switch .filter__label__off {
  background: var(--color-general);
  color: var(--color-purple);
}
.filter__checkbox:checked + .filter__label.-switch .filter__label__off:hover {
  background: var(--color-general-hover);
}
.filter__label:hover, .filter__label:focus, .dropdown__trigger:hover .filter__label {
  background: var(--color-general-hover);
}

.filter__link {
  display: inline-block;
  padding: 0;
  margin: 0 1.5rem 0 0;
  color: var(--color-purple);
  border: 0;
  background: none;
  text-decoration: none;
  transition: opacity 0.3s ease;
  cursor: pointer;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  line-height: 1.5;
  font-size: 1.6rem;
  opacity: 0.4;
}
.filter__link.-all {
  opacity: 1;
}
.filter__options .filter__link.-all {
  display: none;
}
@media (--screen-jumbotron) {
  .filter__options .filter__link.-all {
    display: inline-block;
  }
}
@media (--screen-jumbotron) {
  .filter__link.-mobile {
    display: none;
  }
}
.filter__link:hover, .filter__link:focus {
  opacity: 1;
}
.filter__link:hover.-all, .filter__link:focus.-all {
  opacity: 0.55;
}
.filter__link .icon {
  margin-right: 0.3rem;
}

@font-face {
  font-family: "Graebenbach";
  src: url("Graebenbach/Graebenbach-Bold.eot");
  src: url("Graebenbach/Graebenbach-Bold.eot?#iefix") format("embedded-opentype"), url("Graebenbach/Graebenbach-Bold.woff") format("woff"), url("Graebenbach/Graebenbach-Bold.ttf") format("truetype");
  font-weight: var(--font-weight-bold);
  font-style: normal;
}
@font-face {
  font-family: "Graebenbach";
  src: url("Graebenbach/Graebenbach-Medium.eot");
  src: url("Graebenbach/Graebenbach-Medium.eot?#iefix") format("embedded-opentype"), url("Graebenbach/Graebenbach-Medium.woff") format("woff"), url("Graebenbach/Graebenbach-Medium.ttf") format("truetype");
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
@font-face {
  font-family: "Graebenbach";
  src: url("Graebenbach/Graebenbach-Regular.eot");
  src: url("Graebenbach/Graebenbach-Regular.eot?#iefix") format("embedded-opentype"), url("Graebenbach/Graebenbach-Regular.woff") format("woff"), url("Graebenbach/Graebenbach-Regular.ttf") format("truetype");
  font-weight: var(--font-weight-regular);
  font-style: normal;
}
@font-face {
  font-family: "Harbour";
  src: url("Harbour/Harbour-Bold.woff2") format("woff2"), url("Harbour/Harbour-Bold.woff") format("woff");
  font-weight: var(--font-weight-bold);
  font-style: normal;
}
@font-face {
  font-family: "MediaSans";
  src: url("MediaSans/MediaSans-Ultra.woff2") format("woff2"), url("MediaSans/MediaSans-Ultra.woff") format("woff");
  font-weight: var(--font-weight-extra-bold);
  font-style: normal;
}
@font-face {
  font-family: "Souvenir";
  src: url("Souvenir/Souvenir-Light.woff2") format("woff2"), url("Souvenir/Souvenir-Light.woff") format("woff");
  font-weight: var(--font-weight-light);
  font-style: normal;
}
@font-face {
  font-family: "Spoof";
  src: url("Spoof/Spoof-Medium.eot");
  src: url("Spoof/Spoof-Medium.eot?#iefix") format("embedded-opentype"), url("Spoof/Spoof-Medium.woff2") format("woff2"), url("Spoof/Spoof-Medium.woff") format("woff");
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
.row[data-block-type=form] {
  padding-block: 5rem;
  background-color: var(--color-white);
  background-position: center;
  background-size: cover;
}
@media (--screen-large) {
  .row[data-block-type=form] {
    padding-block: 10rem;
  }
}
.row[data-block-type=form][data-theme=fun] {
  background-image: url("../../images/bg-fun.png");
}
.row[data-block-type=form][data-theme=family] {
  background-image: url("../../images/bg-family.png");
}
.row[data-block-type=form][data-theme=rebellious] {
  background-image: url("../../images/bg-rebellious.png");
}
.row[data-block-type=form][data-theme=well-informed] {
  background-image: url("../../images/bg-well-informed.png");
}
.row[data-block-type=form] .row__inner {
  box-sizing: border-box;
}
.row[data-block-type=form][data-theme=fun] .row__inner, .row[data-block-type=form][data-theme=family] .row__inner, .row[data-block-type=form][data-theme=rebellious] .row__inner, .row[data-block-type=form][data-theme=well-informed] .row__inner {
  background-color: var(--color-white);
  padding: 2.5rem;
}

.myab-login .form {
  margin-top: 4rem;
}
.form fieldset {
  border: 0;
  margin: 0 0 1rem;
  padding: 0;
}
.form fieldset legend {
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-medium);
  font-size: 1.9rem;
  text-align: left;
  padding: 0;
  margin: 0 0 2rem;
}
.form .special-password {
  display: none;
}

.form__link {
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.8rem;
  transition: opacity 0.3s ease;
  display: inline-block;
}
.form__link:hover, .form__link:focus {
  opacity: 0.6;
}
.button + .form__link {
  margin-top: 1rem;
}

.form__title {
  margin: 0 0 3rem;
  font-size: 3.5rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
}
@media (--screen-large) {
  .form__title {
    font-size: 4.3rem;
  }
}

.form__actions {
  padding-top: 2rem;
}
@media (--screen-large) {
  .form__actions.delete__account_buttons {
    margin: 0 -6rem;
  }
}
.form__actions.delete__account_buttons .button {
  margin: 1rem;
}

.form__actions__spacer {
  padding: 2rem 0;
}
.form__actions__spacer.-line {
  border-top: 0.1rem dotted var(--color-border-dark);
  margin: 4rem -2rem 0;
}
@media (--screen-large) {
  .form__actions__spacer.-line {
    margin: 4rem -7rem 0;
  }
}

.form__checkbox {
  border: 0;
  display: none;
}
.form__checkbox + .form__label {
  position: relative;
  margin-bottom: 1rem;
  white-space: normal !important;
}
.form__checkbox + .form__label::before, .form__checkbox + .form__label::after {
  content: "";
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  left: 0;
}
.form__checkbox + .form__label::before {
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
  box-shadow: inset 0 0 0 0.2rem var(--color-purple);
  border-radius: 0.2rem;
}
.page-footer__newsletter__input .form__checkbox + .form__label::before {
  box-shadow: inset 0 0 0 0.2rem var(--color-general);
}
.form__checkbox + .form__label::after {
  transition: opacity 0.3s ease, transform 0.2s ease;
  color: var(--color-purple);
  z-index: 1;
  opacity: 0;
  transform: scale(2);
  background-image: url(check.svg);
  background-size: 1.2rem 1rem;
  background-position: center center;
}
@supports (mask-repeat: no-repeat) {
  .form__checkbox + .form__label::after {
    background: currentColor;
    mask-image: url(check.svg);
    mask-size: 1.2rem 1rem;
    mask-repeat: no-repeat;
    mask-position: center center;
  }
}
.form__checkbox:checked + .form__label::before {
  box-shadow: inset 0 0 0 0.2rem var(--color-purple);
  background-color: var(--color-white);
}
.page-footer__newsletter__input .form__checkbox:checked + .form__label::before {
  box-shadow: inset 0 0 0 0.2rem var(--color-general);
}
.form__checkbox:checked + .form__label::after {
  opacity: 1;
  transform: scale(1);
}
.form__checkbox:disabled + .form__label::before {
  background-color: var(--color-general);
}
.form__checkbox:disabled + .form__label::before, .form__checkbox:disabled + .form__label::after {
  opacity: 0.4;
}

.form__entry {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.form__entry.-checkbox, .form__entry:has(.form__checkbox) {
  margin-top: 4rem;
}
.form__entry.-checkbox + .form__entry.-checkbox, .form__entry.-checkbox + .form__entry:has(.form__checkbox), .form__entry:has(.form__checkbox) + .form__entry.-checkbox, .form__entry:has(.form__checkbox) + .form__entry:has(.form__checkbox) {
  margin-top: 2rem;
}
.page-footer__newsletter__input .form__entry.-checkbox, .newsletter__input .form__entry.-checkbox, .page-footer__newsletter__input .form__entry:has(.form__checkbox), .newsletter__input .form__entry:has(.form__checkbox) {
  margin: 1rem 0 0;
}
.form__entry.-radio {
  margin-top: 4rem;
}
.form__entry.-radio div {
  text-align: left;
}
.form__entry.-inline {
  margin-top: 2rem;
}
.form__entry.-hidden {
  display: none;
}

.form__field, .form__select select {
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 0;
  -webkit-appearance: none !important;
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 1.5rem 0.1rem;
  vertical-align: baseline;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.6rem;
  line-height: 1.5;
  color: var(--color-purple);
  background: transparent;
  border: solid var(--color-black);
  border-width: 0 0 0.1rem;
  outline: none;
}

input {
  cursor: pointer;
}

.form__select {
  background-image: url(select.svg);
  background-position: center right;
  background-repeat: no-repeat;
}
.form__entry .form__select {
  order: 2;
}
.form__field:focus {
  outline: none;
  border-color: var(--color-black);
}
.form__field::placeholder {
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  opacity: 0.5;
}
.form__field.-textarea {
  resize: vertical;
}
.form__field.-page-search {
  padding: 1.5rem 2.5rem;
  border: 0;
  background: var(--color-white);
  border-radius: 2.5rem;
  height: 5rem;
  max-width: calc(100% - 7rem);
  margin-right: 1.5rem;
}
.page-search.-inline .form__field.-page-search {
  border: 0.1rem solid var(--color-purple);
}
.form__field.-newsletter {
  border: dashed var(--color-general);
  border-width: 0 0 0.1rem;
  color: var(--color-general);
}
.newsletter .form__field.-newsletter {
  border-color: var(--color-purple);
  color: var(--color-purple);
}
.form__entry.-invalid .form__field {
  border-color: var(--color-red);
  outline: none;
  box-shadow: none;
}
.form__field.-has-value ~ .form__label, .form__field[data-component="form/hasValue"]:focus ~ .form__label, .form__field[type=date] ~ .form__label {
  font-size: 1.2rem;
  transform: translateY(-0.5rem);
}
.form__field.-select {
  background-image: url(select.svg);
  background-position: center right;
  background-repeat: no-repeat;
}
.form__field.-file {
  order: 2;
  border: 0;
}
.form__field.-file ~ .form__label {
  position: relative;
  top: auto;
  margin-bottom: 1.5rem;
}
.form__field.-radio {
  display: none;
}
.form__field.-radio + label {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 0 0 0 3.2rem;
  margin-right: 4rem;
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: left;
  opacity: 1;
}
.form__field.-radio + label::before, .form__field.-radio + label::after {
  content: "";
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  left: 0;
}
.form__field.-radio + label::before {
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
  box-shadow: inset 0 0 0 0.2rem var(--color-purple);
  border-radius: 50%;
}
.form__field.-radio + label::after {
  background-color: var(--color-purple);
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
}
.form__field.-radio:checked + label::before {
  box-shadow: inset 0 0 0 0.2rem var(--color-purple);
  background-color: var(--color-white);
}
.form__field.-radio:checked + label::after {
  opacity: 1;
  transform: scale(0.6);
}

.form__label {
  order: 2;
  display: none;
  cursor: pointer;
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  opacity: 0.5;
  font-size: 1.6rem;
  line-height: 1;
  padding: 0 0 0.5rem;
  transition: all 0.2s ease;
}
.form__label.-placeholder-label {
  order: 1;
  transform: translateY(2rem);
  position: absolute;
  top: 0;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.form__label.-placeholder-label.-active {
  font-size: 1.2rem;
  transform: translateY(-0.5rem);
}
.form__label.-radio, .form__label.-radio-button, .form__label.-radio-group, .form__label.-checkbox, .form__entry:has(.form__checkbox) .form__label {
  display: block;
  padding: 0 0 0 3.2rem;
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: left;
  opacity: 1;
}
.form__entry:has(.form__checkbox) .form__label {
  order: 2;
  position: relative;
  transform: unset;
  top: unset;
}
.form__label.-radio-group {
  order: -1;
  padding: 0 0 1rem;
}
.form__label.-select {
  pointer-events: none;
}
.form__label.-no-style {
  display: initial;
  font: inherit;
  color: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
.form__label.-pointer {
  cursor: pointer;
}
.form__field + .form__label {
  display: block;
}
.form__entry .form__entry div + .form__label {
  display: block;
  position: relative;
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.8rem;
  opacity: 1;
  transform: none;
  margin: 2rem 0;
}
.page-footer__newsletter__input .form__label {
  color: var(--color-general);
  font-size: 1.4rem;
  margin-bottom: 0;
}
.page-footer__newsletter__input .form__label a {
  color: var(--color-general);
  text-decoration: underline;
}
.newsletter__input .form__label {
  margin-top: 1rem;
  font-size: 1.4rem;
}
.newsletter__input .form__label a {
  color: var(--color-purple);
}
.form__entry .form__entry .form__select + .form__label {
  font-weight: var(--font-weight-medium);
  font-size: 1.9rem;
  margin: 0;
}

[readonly] + label {
  cursor: default;
}

.form__message {
  font-size: 1.6rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-error);
  display: block;
  text-align: left;
  margin-top: 1rem;
  box-sizing: border-box;
  order: 10;
}
.form__message a {
  color: var(--color-error) !important;
  font-weight: var(--font-weight-regular) !important;
}
.-fun .form__message {
  color: var(--color-fun);
}
.-fun .form__message a {
  color: var(--color-fun);
}
.-family .form__message {
  color: var(--color-family);
}
.-family .form__message a {
  color: var(--color-family);
}
.-well-informed .form__message, .poll__content .form__message {
  color: var(--color-well-informed);
}
.-well-informed .form__message a, .poll__content .form__message a {
  color: var(--color-well-informed);
}
.form__message.-error {
  color: var(--color-error);
}
.form__message.-error a {
  color: var(--color-error);
}
.newsletter .form__message {
  background: var(--color-purple);
  padding: 1.5rem;
  border-radius: 2.5rem;
  text-align: center;
  position: absolute;
  top: calc(100% + 0.5rem);
  width: 100%;
}
.form__message::before {
  content: "";
  background-image: url("error.svg");
  display: inline-block;
  width: 1.9rem;
  height: 1.1rem;
  margin-right: 0.7rem;
}
@supports (mask-repeat: no-repeat) {
  .form__message::before {
    mask-image: url("error.svg");
    mask-size: 1.9rem 1.1rem;
    background: currentColor;
  }
}
.form__entry.-checkbox .form__message, .form__entry:has(.form__checkbox) .form__message {
  margin-bottom: 2rem;
}
.alert.-error .form__message {
  margin-top: 0;
  text-align: center;
}

.form__radio {
  border: 0;
  display: none;
}
.form__radio + .form__label {
  position: relative;
  margin-bottom: 1rem;
  white-space: normal !important;
}
.form__radio + .form__label::before, .form__radio + .form__label::after {
  content: "";
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}
.form__radio + .form__label::before {
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
  box-shadow: inset 0 0 0 0.2rem var(--color-purple);
}
.form__radio + .form__label::after {
  transition: opacity 0.3s ease, transform 0.2s ease;
  z-index: 1;
  opacity: 0;
  transform: scale(1);
  width: 1rem;
  height: 1rem;
  top: 0.5rem;
  left: 0.5rem;
  background: var(--color-purple);
}
.form__radio:checked + .form__label::before {
  box-shadow: inset 0 0 0 0.2rem var(--color-purple);
  background-color: var(--color-white);
}
.form__radio:checked + .form__label::after {
  opacity: 1;
  transform: scale(1);
}
.form__radio:disabled + .form__label::before {
  background-color: var(--color-general);
}
.form__radio:disabled + .form__label::before, .form__radio:disabled + .form__label::after {
  opacity: 0.4;
}

html {
  font-size: 62.5%;
  line-height: 1;
  box-sizing: border-box;
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
  background: var(--color-white);
  touch-action: manipulation;
}
html.zebrix, html.zebrix body {
  width: 100%;
  height: 100%;
}

.icon {
  position: relative;
  vertical-align: middle;
  font: 0/0 sans-serif;
  display: inline-block;
}
.icon.-hidden {
  display: none;
}
.icon::before {
  content: "";
  transition: background 0.3s ease;
  display: inline-block;
  width: 2.8rem;
  height: 2.8rem;
  vertical-align: middle;
  background-color: var(--color-purple);
  background-size: 2.8rem 2.8rem;
}
@supports (mask-repeat: no-repeat) {
  .icon::before {
    background: var(--color-purple);
    mask-repeat: no-repeat;
    mask-size: 2.8rem 2.8rem;
  }
}
.icon.-facebook::before {
  width: 1.5rem;
  background-image: url(facebook.svg);
  background-size: 1.5rem 2.8rem;
}
.cta__list .icon.-facebook::before {
  background-color: var(--color-general);
}
.event-detail__content__aside .icon.-facebook::before {
  width: 1.2rem;
  height: 2.2rem;
  background-size: 1.2rem 2.2rem;
}
.artist__socials__item .icon.-facebook::before {
  width: 1.3rem;
  height: 2.5rem;
  background-size: 1.3rem 2.5rem;
}
.button.-social .icon.-facebook::before {
  background-color: var(--color-white);
}
@supports (mask-repeat: no-repeat) {
  .icon.-facebook::before {
    mask-image: url(facebook.svg);
    mask-size: 1.5rem 2.8rem;
  }
  .cta__list .icon.-facebook::before {
    background: var(--color-general);
  }
  .event-detail__content__aside .icon.-facebook::before {
    mask-size: 1.2rem 2.2rem;
  }
  .artist__socials__item .icon.-facebook::before {
    mask-size: 1.3rem 2.5rem;
  }
  .button.-social .icon.-facebook::before {
    background: var(--color-white);
  }
}
.icon.-twitter::before {
  width: 2.8rem;
  height: 2.5rem;
  background-image: url(twitter.svg);
  background-size: 2.8rem 2.5rem;
}
.artist__socials__item .icon.-twitter::before {
  width: 2.6rem;
  height: 2.2rem;
  background-size: 2.6rem 2.2rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-twitter::before {
    mask-image: url(twitter.svg);
    mask-size: 2.8rem 2.5rem;
  }
  .artist__socials__item .icon.-twitter::before {
    mask-size: 2.6rem 2.2rem;
  }
}
.icon.-instagram::before {
  background-image: url(instagram.svg);
}
.artist__socials__item .icon.-instagram::before {
  width: 2.5rem;
  height: 2.5rem;
  background-size: 2.5rem 2.5rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-instagram::before {
    mask-image: url(instagram.svg);
  }
  .artist__socials__item .icon.-instagram::before {
    mask-size: 2.5rem 2.5rem;
  }
}
.icon.-youtube::before {
  background-image: url(youtube.svg);
}
@supports (mask-repeat: no-repeat) {
  .icon.-youtube::before {
    mask-image: url(youtube.svg);
  }
}
.icon.-spotify::before {
  background-image: url(spotify.svg);
}
.cta__list .icon.-spotify::before {
  background-color: var(--color-general);
}
.artist__socials__item .icon.-spotify::before {
  width: 2.5rem;
  height: 2.5rem;
  background-size: 2.5rem 2.5rem;
}
.button.-social .icon.-spotify::before {
  width: 4rem;
  height: 4rem;
  background-size: 4rem 4rem;
  background-color: var(--color-spotify);
}
@supports (mask-repeat: no-repeat) {
  .icon.-spotify::before {
    mask-image: url(spotify.svg);
  }
  .cta__list .icon.-spotify::before {
    background: var(--color-general);
  }
  .artist__socials__item .icon.-spotify::before {
    mask-size: 2.5rem 2.5rem;
  }
  .button.-social .icon.-spotify::before {
    mask-size: 4rem 4rem;
    background: var(--color-spotify);
  }
}
.icon.-website::before {
  background-image: url(website.svg);
}
.artist__socials__item .icon.-website::before {
  width: 2.5rem;
  height: 2.5rem;
  background-size: 2.5rem 2.5rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-website::before {
    mask-image: url(website.svg);
  }
  .artist__socials__item .icon.-website::before {
    mask-size: 2.5rem 2.5rem;
  }
}
.icon.-bandcamp::before {
  background-image: url(bandcamp.svg);
}
.artist__socials__item .icon.-bandcamp::before {
  width: 2.5rem;
  height: 2.5rem;
  background-size: 2.5rem 2.5rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-bandcamp::before {
    mask-image: url(bandcamp.svg);
  }
  .artist__socials__item .icon.-bandcamp::before {
    mask-size: 2.5rem 2.5rem;
  }
}
.icon.-deezer::before {
  background-image: url(deezer.svg);
}
.artist__socials__item .icon.-deezer::before {
  width: 2.5rem;
  height: 2.5rem;
  background-size: 2.5rem 2.5rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-deezer::before {
    mask-image: url(deezer.svg);
  }
  .artist__socials__item .icon.-deezer::before {
    mask-size: 2.5rem 2.5rem;
  }
}
.icon.-soundcloud::before {
  background-image: url(soundcloud.svg);
}
.artist__socials__item .icon.-soundcloud::before {
  width: 2.5rem;
  height: 2.5rem;
  background-size: 2.5rem 2.5rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-soundcloud::before {
    mask-image: url(soundcloud.svg);
  }
  .artist__socials__item .icon.-soundcloud::before {
    mask-size: 2.5rem 2.5rem;
  }
}
.icon.-tiktok::before {
  background-image: url(tiktok.svg);
}
.artist__socials__item .icon.-tiktok::before {
  width: 2.5rem;
  height: 2.5rem;
  background-size: 2.5rem 2.5rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-tiktok::before {
    mask-image: url(tiktok.svg);
  }
  .artist__socials__item .icon.-tiktok::before {
    mask-size: 2.5rem 2.5rem;
  }
}
.icon.-search::before {
  width: 1.6rem;
  height: 1.6rem;
  background-image: url(search.svg);
  background-size: 1.6rem 1.6rem;
}
.page-header.-myab .icon.-search::before, .page-nav__header .icon.-search::before {
  background-color: var(--color-general);
}
@supports (mask-repeat: no-repeat) {
  .icon.-search::before {
    mask-image: url(search.svg);
    mask-size: 1.6rem 1.6rem;
  }
  .page-header.-myab .icon.-search::before, .page-nav__header .icon.-search::before {
    background: var(--color-general);
  }
}
.icon.-cross::before {
  width: 1.1rem;
  height: 1.1rem;
  background-image: url(cross.svg);
  background-size: 1.1rem 1.1rem;
}
.audio-player .icon.-cross::before {
  background-color: var(--color-purple);
  width: 2rem;
  height: 2rem;
  background-size: 2rem 2rem;
}
.filter__link .icon.-cross::before {
  background-color: var(--color-purple);
}
.audio-player:hover .icon.-cross::before, .audio-player:focus .icon.-cross::before {
  background-color: var(--color-general);
}
@supports (mask-repeat: no-repeat) {
  .icon.-cross::before {
    mask-image: url(cross.svg);
    mask-size: 1.1rem 1.1rem;
  }
  .audio-player .icon.-cross::before {
    background: var(--color-purple);
    mask-size: 2rem 2rem;
  }
  .filter__link .icon.-cross::before {
    background: var(--color-purple);
  }
  .audio-player:hover .icon.-cross::before, .audio-player:focus .icon.-cross::before {
    background: var(--color-general);
  }
}
.icon.-arrow-left::before, .icon.-arrow-right::before {
  width: 2.1rem;
  height: 2.1rem;
  background-image: url(arrow-right.svg);
  background-size: 2.1rem 2.1rem;
}
.button .icon.-arrow-left::before, .content-cards__arrow .icon.-arrow-left::before, .image-list__arrow .icon.-arrow-left::before, .content-card__info__summary .icon.-arrow-left::before, .button .icon.-arrow-right::before, .content-cards__arrow .icon.-arrow-right::before, .image-list__arrow .icon.-arrow-right::before, .content-card__info__summary .icon.-arrow-right::before {
  width: 1.4rem;
  height: 1.4rem;
  background-size: 1.4rem 1.4rem;
  background-color: var(--color-general);
}
.content-cards__arrow .icon.-arrow-left::before, .jumbotron__arrow .icon.-arrow-left::before, .image-list__arrow .icon.-arrow-left::before, .content-card__info__summary .icon.-arrow-left::before, .content-cards__arrow .icon.-arrow-right::before, .jumbotron__arrow .icon.-arrow-right::before, .image-list__arrow .icon.-arrow-right::before, .content-card__info__summary .icon.-arrow-right::before {
  width: 1.2rem;
  height: 1.2rem;
  background-size: 1.2rem 1.2rem;
}
.content-cards__container.-purple .content-cards__arrow .icon.-arrow-left::before, .content-card__info__summary .icon.-arrow-left::before, .carrousel__footer .icon.-arrow-left::before, .agora-header__info .icon.-arrow-left::before, .agenda__footer .icon.-arrow-left::before, .content-cards__container.-purple .content-cards__arrow .icon.-arrow-right::before, .content-card__info__summary .icon.-arrow-right::before, .carrousel__footer .icon.-arrow-right::before, .agora-header__info .icon.-arrow-right::before, .agenda__footer .icon.-arrow-right::before {
  background-color: var(--color-purple);
}
.button.-general .icon.-arrow-left::before, .button.-general .icon.-arrow-right::before {
  background-color: var(--color-purple);
}
@supports (mask-repeat: no-repeat) {
  .icon.-arrow-left::before, .icon.-arrow-right::before {
    mask-image: url(arrow-right.svg);
    mask-size: 2.1rem 2.1rem;
  }
  .button .icon.-arrow-left::before, .content-cards__arrow .icon.-arrow-left::before, .image-list__arrow .icon.-arrow-left::before, .content-card__info__summary .icon.-arrow-left::before, .button .icon.-arrow-right::before, .content-cards__arrow .icon.-arrow-right::before, .image-list__arrow .icon.-arrow-right::before, .content-card__info__summary .icon.-arrow-right::before {
    background: var(--color-general);
    mask-size: 1.4rem 1.4rem;
  }
  .button.-general .icon.-arrow-left::before, .button.-general .icon.-arrow-right::before {
    background: var(--color-purple);
  }
  .content-cards__arrow .icon.-arrow-left::before, .jumbotron__arrow .icon.-arrow-left::before, .image-list__arrow .icon.-arrow-left::before, .content-card__info__summary .icon.-arrow-left::before, .content-cards__arrow .icon.-arrow-right::before, .jumbotron__arrow .icon.-arrow-right::before, .image-list__arrow .icon.-arrow-right::before, .content-card__info__summary .icon.-arrow-right::before {
    mask-size: 1.2rem 1.2rem;
  }
  .content-cards__container.-purple .content-cards__arrow .icon.-arrow-left::before, .content-card__info__summary .icon.-arrow-left::before, .carrousel__footer .icon.-arrow-left::before, .agora-header__info .icon.-arrow-left::before, .agenda__footer .icon.-arrow-left::before, .content-cards__container.-purple .content-cards__arrow .icon.-arrow-right::before, .content-card__info__summary .icon.-arrow-right::before, .carrousel__footer .icon.-arrow-right::before, .agora-header__info .icon.-arrow-right::before, .agenda__footer .icon.-arrow-right::before {
    background: var(--color-purple);
  }
}
.icon.-arrow-left::before {
  transform: rotate(180deg);
}
.icon.-arrow-down::before, .icon.-arrow-up::before {
  width: 1.6rem;
  height: 1.5rem;
  background-image: url(arrow-down.svg);
  background-size: 1.6rem 1.5rem;
}
.button.-general .icon.-arrow-down::before, .filter__label .icon.-arrow-down::before, .faq-item__button .icon.-arrow-down::before, .page-header__link .icon.-arrow-down::before, .button.-general .icon.-arrow-up::before, .filter__label .icon.-arrow-up::before, .faq-item__button .icon.-arrow-up::before, .page-header__link .icon.-arrow-up::before {
  background-color: var(--color-purple);
}
.button:hover .icon.-arrow-down::before, .button:focus .icon.-arrow-down::before, .page-header.-myab .icon.-arrow-down::before, .button:hover .icon.-arrow-up::before, .button:focus .icon.-arrow-up::before, .page-header.-myab .icon.-arrow-up::before {
  background-color: var(--color-general);
}
.event-detail__content.-general .button:hover .icon.-arrow-down::before, .event-detail__content.-general .button:focus .icon.-arrow-down::before, .event-detail__content.-general .page-header.-myab .icon.-arrow-down::before, .event-detail__content.-general .button:hover .icon.-arrow-up::before, .event-detail__content.-general .button:focus .icon.-arrow-up::before, .event-detail__content.-general .page-header.-myab .icon.-arrow-up::before {
  background-color: var(--color-general);
}
.event-detail__content.-family .button:hover .icon.-arrow-down::before, .event-detail__content.-family .button:focus .icon.-arrow-down::before, .event-detail__content.-family .page-header.-myab .icon.-arrow-down::before, .event-detail__content.-family .button:hover .icon.-arrow-up::before, .event-detail__content.-family .button:focus .icon.-arrow-up::before, .event-detail__content.-family .page-header.-myab .icon.-arrow-up::before {
  background-color: var(--color-family);
}
.event-detail__content.-fun .button:hover .icon.-arrow-down::before, .event-detail__content.-fun .button:focus .icon.-arrow-down::before, .event-detail__content.-fun .page-header.-myab .icon.-arrow-down::before, .event-detail__content.-fun .button:hover .icon.-arrow-up::before, .event-detail__content.-fun .button:focus .icon.-arrow-up::before, .event-detail__content.-fun .page-header.-myab .icon.-arrow-up::before {
  background-color: var(--color-fun);
}
.event-detail__content.-rebellious .button:hover .icon.-arrow-down::before, .event-detail__content.-rebellious .button:focus .icon.-arrow-down::before, .event-detail__content.-rebellious .page-header.-myab .icon.-arrow-down::before, .event-detail__content.-rebellious .button:hover .icon.-arrow-up::before, .event-detail__content.-rebellious .button:focus .icon.-arrow-up::before, .event-detail__content.-rebellious .page-header.-myab .icon.-arrow-up::before {
  background-color: var(--color-rebellious);
}
.event-detail__content.-well-informed .button:hover .icon.-arrow-down::before, .event-detail__content.-well-informed .button:focus .icon.-arrow-down::before, .event-detail__content.-well-informed .page-header.-myab .icon.-arrow-down::before, .event-detail__content.-well-informed .button:hover .icon.-arrow-up::before, .event-detail__content.-well-informed .button:focus .icon.-arrow-up::before, .event-detail__content.-well-informed .page-header.-myab .icon.-arrow-up::before {
  background-color: var(--color-well-informed);
}
.button .icon.-arrow-down::before, .button .icon.-arrow-up::before {
  background-color: var(--color-general);
}
@supports (mask-repeat: no-repeat) {
  .icon.-arrow-down::before, .icon.-arrow-up::before {
    mask-image: url(arrow-down.svg);
    mask-size: 1.6rem 1.5rem;
  }
  .button.-general .icon.-arrow-down::before, .filter__label .icon.-arrow-down::before, .faq-item__button .icon.-arrow-down::before, .page-header__link .icon.-arrow-down::before, .button.-general .icon.-arrow-up::before, .filter__label .icon.-arrow-up::before, .faq-item__button .icon.-arrow-up::before, .page-header__link .icon.-arrow-up::before {
    background: var(--color-purple);
  }
  .button:hover .icon.-arrow-down::before, .button:focus .icon.-arrow-down::before, .page-header.-myab .icon.-arrow-down::before, .button:hover .icon.-arrow-up::before, .button:focus .icon.-arrow-up::before, .page-header.-myab .icon.-arrow-up::before {
    background: var(--color-general);
  }
  .event-detail__content.-general .button:hover .icon.-arrow-down::before, .event-detail__content.-general .button:focus .icon.-arrow-down::before, .event-detail__content.-general .page-header.-myab .icon.-arrow-down::before, .event-detail__content.-general .button:hover .icon.-arrow-up::before, .event-detail__content.-general .button:focus .icon.-arrow-up::before, .event-detail__content.-general .page-header.-myab .icon.-arrow-up::before {
    background: var(--color-general);
  }
  .event-detail__content.-family .button:hover .icon.-arrow-down::before, .event-detail__content.-family .button:focus .icon.-arrow-down::before, .event-detail__content.-family .page-header.-myab .icon.-arrow-down::before, .event-detail__content.-family .button:hover .icon.-arrow-up::before, .event-detail__content.-family .button:focus .icon.-arrow-up::before, .event-detail__content.-family .page-header.-myab .icon.-arrow-up::before {
    background: var(--color-family);
  }
  .event-detail__content.-fun .button:hover .icon.-arrow-down::before, .event-detail__content.-fun .button:focus .icon.-arrow-down::before, .event-detail__content.-fun .page-header.-myab .icon.-arrow-down::before, .event-detail__content.-fun .button:hover .icon.-arrow-up::before, .event-detail__content.-fun .button:focus .icon.-arrow-up::before, .event-detail__content.-fun .page-header.-myab .icon.-arrow-up::before {
    background: var(--color-fun);
  }
  .event-detail__content.-rebellious .button:hover .icon.-arrow-down::before, .event-detail__content.-rebellious .button:focus .icon.-arrow-down::before, .event-detail__content.-rebellious .page-header.-myab .icon.-arrow-down::before, .event-detail__content.-rebellious .button:hover .icon.-arrow-up::before, .event-detail__content.-rebellious .button:focus .icon.-arrow-up::before, .event-detail__content.-rebellious .page-header.-myab .icon.-arrow-up::before {
    background: var(--color-rebellious);
  }
  .event-detail__content.-well-informed .button:hover .icon.-arrow-down::before, .event-detail__content.-well-informed .button:focus .icon.-arrow-down::before, .event-detail__content.-well-informed .page-header.-myab .icon.-arrow-down::before, .event-detail__content.-well-informed .button:hover .icon.-arrow-up::before, .event-detail__content.-well-informed .button:focus .icon.-arrow-up::before, .event-detail__content.-well-informed .page-header.-myab .icon.-arrow-up::before {
    background: var(--color-well-informed);
  }
  .button .icon.-arrow-down::before, .button .icon.-arrow-up::before {
    background: var(--color-general);
  }
}
.icon.-arrow-up::before {
  transform: rotate(180deg);
}
.icon.-hand:before {
  width: 1.9rem;
  height: 1.1rem;
  background-image: url(hand.svg);
  background-size: 1.9rem 1.1rem;
  background-color: var(--color-error);
}
.-fun .icon.-hand:before {
  background-color: var(--color-fun);
}
.-family .icon.-hand:before {
  background-color: var(--color-family);
}
.-well-informed .icon.-hand:before {
  background-color: var(--color-well-informed);
}
.content-card .content-card__info__ticketlink .icon.-hand:before, .jumbotron__event__tickets .icon.-hand:before, .carrousel__content .icon.-hand:before {
  width: 4rem;
  height: 2.1rem;
  background-size: 4rem 2.1rem;
  background-color: var(--color-purple);
}
.content-cards__container.-purple .content-card .content-card__info__ticketlink .icon.-hand:before, .carrousel__content .icon.-hand:before {
  background-color: var(--color-general);
}
.event-detail__ticket-button .icon.-hand:before {
  width: 3.3rem;
  height: 1.7rem;
  background-size: 3.3rem 1.7rem;
  background-color: var(--color-general);
}
@media (--screen-full) {
  .event-detail__ticket-button .icon.-hand:before {
    width: 4rem;
    height: 2.1rem;
    background-size: 4rem 2.1rem;
  }
}
.event-detail__content.-family .event-detail__ticket-button .icon.-hand:before {
  background-color: var(--color-family);
}
.event-detail__content.-fun .event-detail__ticket-button .icon.-hand:before {
  background-color: var(--color-fun);
}
.event-detail__content.-rebellious .event-detail__ticket-button .icon.-hand:before {
  background-color: var(--color-rebellious);
}
.event-detail__content.-well-informed .event-detail__ticket-button .icon.-hand:before {
  background-color: var(--color-well-informed);
}
.event-detail__ticket-button.-small .icon.-hand:before, .event-detail__ticket-button.-smaller .icon.-hand:before {
  background-size: 3.3rem 1.7rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-hand:before {
    mask-image: url(hand.svg);
    mask-size: 1.9rem 1.1rem;
    background: var(--color-error);
  }
  .-fun .icon.-hand:before {
    background: var(--color-fun);
  }
  .-family .icon.-hand:before {
    background: var(--color-family);
  }
  .-well-informed .icon.-hand:before {
    background: var(--color-well-informed);
  }
  .content-card .content-card__info__ticketlink .icon.-hand:before, .jumbotron__event__tickets .icon.-hand:before, .carrousel__content .icon.-hand:before {
    background: var(--color-purple);
    mask-size: 4rem 2.1rem;
  }
  .content-cards__container.-purple .content-card .content-card__info__ticketlink .icon.-hand:before, .carrousel__content .icon.-hand:before {
    background: var(--color-general);
  }
  .event-detail__ticket-button .icon.-hand:before {
    background: var(--color-general);
    mask-size: 3.3rem 1.7rem;
  }
  @media (--screen-full) {
    .event-detail__ticket-button .icon.-hand:before {
      mask-size: 4rem 2.1rem;
    }
  }
  .event-detail__content.-family .event-detail__ticket-button .icon.-hand:before {
    background: var(--color-family);
  }
  .event-detail__content.-fun .event-detail__ticket-button .icon.-hand:before {
    background: var(--color-fun);
  }
  .event-detail__content.-rebellious .event-detail__ticket-button .icon.-hand:before {
    background: var(--color-rebellious);
  }
  .event-detail__content.-well-informed .event-detail__ticket-button .icon.-hand:before {
    background: var(--color-well-informed);
  }
  .event-detail__ticket-button.-small .icon.-hand:before, .event-detail__ticket-button.-smaller .icon.-hand:before {
    mask-size: 3.3rem 1.7rem;
  }
}
.icon.-heart::before {
  width: 2.6rem;
  height: 2.3rem;
  background-image: url(heart.svg);
  background-size: 2.6rem 2.3rem;
}
.artist__favorite .icon.-heart::before {
  background-color: var(--color-white);
}
@media (--screen-mini-medium) {
  .artist__favorite .icon.-heart::before {
    width: 2.1rem;
    height: 1.8rem;
    background-size: 2.1rem 1.8rem;
  }
}
.artist__favorite.-favorite .icon.-heart::before, .artist__favorite:hover .icon.-heart::before, .artist__favorite:focus .icon.-heart::before {
  background-color: var(--color-general);
}
.filter__label .icon.-heart::before {
  width: 1.8rem;
  height: 1.6rem;
  background-color: var(--color-purple);
}
.filter__checkbox:checked + .filter__label .icon.-heart::before {
  background-color: var(--color-general);
}
@supports (mask-repeat: no-repeat) {
  .icon.-heart::before {
    mask-image: url(heart.svg);
    mask-size: 2.6rem 2.3rem;
  }
  .artist__favorite .icon.-heart::before {
    background: var(--color-white);
  }
  @media (--screen-mini-medium) {
    .artist__favorite .icon.-heart::before {
      mask-size: 2.1rem 1.8rem;
    }
  }
  .artist__favorite.-favorite .icon.-heart::before, .artist__favorite:hover .icon.-heart::before, .artist__favorite:focus .icon.-heart::before {
    background: var(--color-general);
  }
  .filter__label .icon.-heart::before {
    mask-size: 1.8rem 1.6rem;
    background: var(--color-purple);
  }
  .filter__checkbox:checked + .filter__label .icon.-heart::before {
    background: var(--color-general);
  }
}
.icon.-win::before {
  width: 3.2rem;
  height: 3.2rem;
  background-image: url(win.svg);
  background-size: 3.2rem 3.2rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-win::before {
    mask-image: url(win.svg);
    mask-size: 3.2rem 3.2rem;
  }
}
.icon.-playlist::before {
  width: 1.8rem;
  height: 2.3rem;
  background-image: url(playlist.svg);
  background-size: 1.8rem 2.3rem;
  margin-left: -0.4rem;
}
.cta__list .icon.-playlist::before {
  background-color: var(--color-general);
}
@supports (mask-repeat: no-repeat) {
  .icon.-playlist::before {
    mask-image: url(playlist.svg);
    mask-size: 1.8rem 2.3rem;
  }
  .cta__list .icon.-playlist::before {
    background: var(--color-general);
  }
}
.icon.-abtv::before {
  width: 1.6rem;
  height: 1.9rem;
  background-image: url(abtv.svg);
  background-size: 1.6rem 1.9rem;
}
.video__cover .icon.-abtv::before {
  width: 4rem;
  height: 4rem;
  background-size: 4rem 4rem;
}
@media (--screen-full) {
  .video__cover .icon.-abtv::before {
    width: 5.8rem;
    height: 5.8rem;
    background-size: 5.8rem 5.8rem;
  }
}
.event-detail__content.-family .video__cover .icon.-abtv::before {
  background-color: var(--color-family);
}
.event-detail__content.-fun .video__cover .icon.-abtv::before {
  background-color: var(--color-fun);
}
.event-detail__content.-rebellious .video__cover .icon.-abtv::before {
  background-color: var(--color-rebellious);
}
.event-detail__content.-well-informed .video__cover .icon.-abtv::before {
  background-color: var(--color-well-informed);
}
@supports (mask-repeat: no-repeat) {
  .icon.-abtv::before {
    mask-image: url(abtv.svg);
    mask-size: 1.6rem 1.9rem;
  }
  .video__cover .icon.-abtv::before {
    mask-size: 4rem 4rem;
  }
  @media (--screen-full) {
    .video__cover .icon.-abtv::before {
      mask-size: 5.8rem 5.8rem;
    }
  }
  .event-detail__content.-family .video__cover .icon.-abtv::before {
    background: var(--color-family);
  }
  .event-detail__content.-fun .video__cover .icon.-abtv::before {
    background: var(--color-fun);
  }
  .event-detail__content.-rebellious .video__cover .icon.-abtv::before {
    background: var(--color-rebellious);
  }
  .event-detail__content.-well-informed .video__cover .icon.-abtv::before {
    background: var(--color-well-informed);
  }
}
.icon.-audio::before {
  width: 2rem;
  height: 1.8rem;
  background-image: url(audio.svg);
  background-size: 2rem 1.8rem;
}
.audio-player .icon.-audio::before {
  background-color: var(--color-purple);
}
.audio-player:hover .icon.-audio::before, .audio-player:focus .icon.-audio::before, .event-detail__header .audio-player .icon.-audio::before {
  background-color: var(--color-general);
}
.event-detail__header.-fun .audio-player .icon.-audio::before {
  background-color: var(--color-fun);
}
@supports (mask-repeat: no-repeat) {
  .icon.-audio::before {
    mask-image: url(audio.svg);
    mask-size: 2rem 1.8rem;
  }
  .audio-player .icon.-audio::before {
    background: var(--color-purple);
  }
  .audio-player:hover .icon.-audio::before, .audio-player:focus .icon.-audio::before, .event-detail__header .audio-player .icon.-audio::before {
    background: var(--color-general);
  }
  .event-detail__header.-family .audio-player .icon.-audio::before {
    background: var(--color-family);
  }
  .event-detail__header.-fun .audio-player .icon.-audio::before {
    background: var(--color-fun);
  }
  .event-detail__header.-rebellious .audio-player .icon.-audio::before {
    background: var(--color-rebellious);
  }
  .event-detail__header.-well-informed .audio-player .icon.-audio::before {
    background: var(--color-well-informed);
  }
}
.icon.-location::before {
  width: 1.8rem;
  height: 2.4rem;
  background-image: url(location.svg);
  background-size: 1.8rem 2.4rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-location::before {
    mask-image: url(location.svg);
    mask-size: 1.8rem 2.4rem;
  }
}
.icon.-download::before {
  width: 1.7rem;
  height: 1.7rem;
  background-image: url(download.svg);
  background-size: 1.7rem 1.7rem;
}
.button .icon.-download::before .button:hover .icon.-download::before, .button:focus .icon.-download::before {
  background-color: var(--color-general) !important;
}
@supports (mask-repeat: no-repeat) {
  .icon.-download::before {
    mask-image: url(download.svg);
    mask-size: 1.7rem 1.7rem;
  }
  .button .icon.-download::before .button:hover .icon.-download::before, .button:focus .icon.-download::before {
    background: var(--color-general) !important;
  }
}
.icon.-select::before {
  width: 2.1rem;
  height: 1.2rem;
  background-image: url(select.svg);
  background-size: 2.1rem 1.2rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-select::before {
    mask-image: url(select.svg);
    mask-size: 2.1rem 1.2rem;
  }
}
.icon.-ticketswap::before {
  width: 2rem;
  height: 1.3rem;
  background-image: url(ticketswap.svg);
  background-size: 2rem 1.3rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-ticketswap::before {
    mask-image: url(ticketswap.svg);
    mask-size: 2rem 1.3rem;
  }
}
.icon.-list::before {
  width: 2.1rem;
  height: 1.6rem;
  background-image: url(list.svg);
  background-size: 2.1rem 1.6rem;
}
@supports (mask-repeat: no-repeat) {
  .icon.-list::before {
    mask-image: url(list.svg);
    mask-size: 2.1rem 1.6rem;
  }
}
.icon.-shuffle::before {
  width: 2.1rem;
  height: 2.1rem;
  background-image: url(shuffle.svg);
  background-size: 2.1rem 2.1rem;
}
.button .icon.-shuffle::before {
  background-color: var(--color-general);
}
.button.-general .icon.-shuffle::before {
  background-color: var(--color-purple);
}
@supports (mask-repeat: no-repeat) {
  .icon.-shuffle::before {
    mask-image: url(shuffle.svg);
    mask-size: 2.1rem 2.1rem;
  }
  .button .icon.-shuffle::before {
    background: var(--color-general);
  }
  .button.-general .icon.-shuffle::before {
    background: var(--color-purple);
  }
}

.iframe {
  margin: 3rem 0 5rem;
}

.iframe__frame {
  inline-size: 100%;
  min-block-size: 45rem;
}

.image-full {
  inline-size: 100%;
  margin-block: 2rem 6rem;
}
.image-full img, .text .image-full img {
  max-height: 100%;
}
.image-full figcaption {
  padding-inline: 1rem;
}

.image-text__image {
  inline-size: 100%;
}

.image-grid {
  margin: 2rem auto !important;
  max-width: 100rem;
}

.image-grid__grid {
  display: block;
  gap: 2rem;
  column-count: 1;
}
[data-columns=two] .image-grid__grid, [data-columns=three] .image-grid__grid, [data-columns=four] .image-grid__grid {
  column-count: 2;
}
.image-grid__grid .image-element {
  margin-bottom: 2rem;
}

.glightbox {
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.glightbox:hover, .glightbox:focus {
  opacity: 0.75;
}

@media (--screen-from-md) {
  .image-grid {
    margin-block: 2rem 3rem;
  }
  .image-grid__grid {
    gap: 3rem;
  }
  .image-grid__grid .image-element {
    margin-bottom: 3rem;
  }
  [data-columns=three] .image-grid__grid {
    column-count: 3;
  }
  [data-columns=four] .image-grid__grid {
    column-count: 4;
  }
}
.image-with-text {
  margin: 1.5rem 0 2.5rem;
}

.image-with-text__text {
  font-size: 2.2rem;
}
.image-with-text__text :first-child {
  margin-block-start: 0;
}
.image-with-text__text :last-child {
  margin-block-end: 0;
}

.image-with-text__image {
  margin-block-end: 3.7rem;
}

@media (--screen-from-sm) {
  .image-with-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .image-with-text.-reverse .image-with-text__image, [data-order=text-image] .image-with-text .image-with-text__image {
    order: 1;
  }
  .image-with-text.-reverse .image-with-text__text, [data-order=text-image] .image-with-text .image-with-text__text {
    order: 0;
  }
  .image-with-text__image, .image-with-text__text {
    flex: 0 0 calc(50% - 1.75rem);
    margin: 0;
  }
}
@media (--screen-from-lg) {
  .image-with-text {
    align-items: center;
  }
  .image-with-text__image, .image-with-text__text {
    flex: 0 0 calc(50% - 2rem);
  }
}
.image-with-text-alt {
  margin-block: 1rem;
}
[data-size=large] .image-with-text-alt {
  background-color: var(--color-purple);
}

.image-with-text-alt__image {
  box-sizing: border-box;
}
[data-size=small] .image-with-text-alt__image {
  padding: 7.5%;
}

.image-with-text-alt__content {
  box-sizing: border-box;
  padding: 2rem;
}
[data-size=small] .image-with-text-alt__content {
  padding: 7.5%;
}

.image-with-text-alt__content-wrapper {
  max-inline-size: 64rem;
  margin: auto;
}
[data-size=large] .image-with-text-alt__content-wrapper {
  color: var(--color-general);
}

.image-with-text-alt__tag {
  display: block;
  margin: 0 0 2.2rem;
  font-size: 1.6rem;
  font-weight: var(--font-weight-normal);
}

.image-with-text-alt__title {
  margin: 0 0 2.2rem;
  font-size: 3.6rem;
  line-height: 1.44;
}
[data-size=large] .image-with-text-alt__title {
  color: var(--color-white);
}

.image-with-text-alt__description {
  margin: 0 0 6rem;
  font-size: 2rem;
  line-height: 1.7;
}

[data-size=large] .image-with-text-alt__button {
  background-color: var(--color-general);
  color: var(--color-purple) !important;
}
[data-size=large] .image-with-text-alt__button:--focus {
  background-color: var(--color-general-hover);
}

@media (--screen-from-md) {
  .image-with-text-alt {
    display: flex;
    align-items: center;
  }
  .image-with-text-alt__image {
    flex: 0 0 50%;
    justify-self: stretch;
  }
  .image-with-text-alt__image img {
    display: block;
    inline-size: 100%;
    block-size: 100%;
    object-fit: cover;
  }
  [data-order=text-first] .image-with-text-alt__image {
    order: 1;
  }
  .image-with-text-alt__content {
    flex: 0 0 50%;
  }
  [data-order=text-first] .image-with-text-alt__content {
    order: 0;
  }
}
.image-list {
  padding: 3rem 0 1rem;
}
@media (--screen-full) {
  .image-list {
    padding: 5rem 0 7rem;
  }
}
.image-list .site-container {
  position: relative;
}

.image-list .image-list__items {
  margin: 3rem 0 !important;
  padding: 0 !important;
}
.image-list .image-list__items img {
  width: 100%;
  max-width: 100vw;
}
@media (--screen-large) {
  .image-list .image-list__items img {
    max-width: 96rem;
  }
}

.image-list__title {
  font-size: 2.9rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  display: block;
}
@media (--screen-large) {
  .image-list__title {
    font-size: 3.5rem;
  }
}
@media (--screen-full) {
  .image-list__title {
    margin-bottom: 2rem;
  }
}

.image-list__arrows {
  position: absolute;
  top: 0.8rem;
  right: 2rem;
}

.image-list__arrows .image-list__arrow {
  background: var(--color-purple);
  border-radius: 50%;
  width: 4.2rem;
  height: 4.2rem;
  display: inline-block;
  cursor: pointer;
  transition: opacity 0.3s ease;
  outline: none;
  border: 0;
  opacity: 1;
  position: static;
  transform: translate(0);
  margin-left: 0.7rem;
}
.image-list__arrows .image-list__arrow::before {
  display: none;
}
.image-list__arrows .image-list__arrow:--focus {
  opacity: 0.5 !important;
  background: var(--color-purple);
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
  z-index: 1;
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}
@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }
  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}
.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

.info-cards__container {
  padding: 4rem 0;
  background: var(--color-infocard);
}
@media (--screen-large) {
  .info-cards__container {
    padding: 10rem 0 2rem;
  }
}
.info-cards__container.-transparent {
  background: none;
  padding: 0 0 4rem;
}
@media (--screen-large) {
  .info-cards__container.-transparent {
    padding: 0 0 10rem;
  }
}
.info-cards__container.-text {
  padding: 4rem 0;
}
.info-cards__container.-bottom {
  padding-bottom: 4rem;
}
.myab__header.-artists .info-cards__container {
  padding: 2rem 0 0;
}
@media (--screen-large) {
  .myab__header.-artists .info-cards__container {
    padding: 6rem 0 0;
  }
}

.info-cards__title {
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  display: block;
  text-align: center;
  font-size: 2.8rem;
  line-height: 1.25;
  margin: 0 auto;
  max-width: 26rem;
}
@media (--screen-large) {
  .info-cards__title {
    font-size: 3.4rem;
    max-width: 100%;
  }
}
.info-cards__container.-transparent .info-cards__title {
  margin-bottom: 1rem;
}

.info-cards__lists {
  margin: 0 0 0 calc(var(--side-padding) * -1);
  padding: 0;
  list-style: none;
}
.info-cards__lists:not(.splide__list) {
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 var(--side-padding);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
}
.info-cards__lists:not(.splide__list) li {
  flex-basis: 39rem;
}
@media (--screen-mini) {
  .info-cards__lists:not(.splide__list) .info-card {
    padding-left: 0;
    padding-right: 0;
  }
  .info-cards__lists:not(.splide__list) .info-card img {
    max-width: 100%;
  }
}
.info-cards__lists.-border {
  margin: 0;
  padding: 0;
}
@media (--screen-large) {
  .info-cards__lists.-border {
    display: flex;
    justify-content: center;
  }
}
@media (--screen-full) {
  .info-cards__lists.-border {
    justify-content: space-between;
  }
}
.info-cards__lists.-border li {
  padding: 1.5rem 0;
}
@media (--screen-large) {
  .info-cards__lists.-border li {
    padding: 1.5rem;
  }
}
.info-cards__lists.-border li:first-child {
  padding-left: 0;
}
.info-cards__lists.-border li:last-child {
  padding-right: 0;
}

.info-card {
  box-sizing: border-box;
  max-width: 39rem;
  text-align: center;
  padding: 3rem 2.5rem;
  margin: 0 auto;
}
@media (--screen-large) {
  .info-card {
    padding: 6rem 2.5rem;
  }
  .info-cards__container.-text .info-card {
    padding: 3rem 2.5rem;
  }
}
.info-card a {
  text-decoration: none;
  color: var(--color-purple);
}
.info-card a img, .info-card a .info-card__title, .info-card a p {
  transition: opacity 0.3s ease;
}
.info-card a:hover img, .info-card a:hover .info-card__title, .info-card a:hover p, .info-card a:focus img, .info-card a:focus .info-card__title, .info-card a:focus p {
  opacity: 0.55;
}
.info-card img {
  vertical-align: bottom;
  margin-bottom: 2rem;
  inline-size: revert-layer;
  block-size: revert-layer;
}
.info-card p {
  margin-bottom: 3rem;
}
.info-card p.-nm {
  margin-bottom: 0;
}
.info-cards__lists.-border .info-card {
  border: 0.1rem dotted var(--color-border-dark);
  height: 100%;
}

.info-card__title {
  display: block;
  text-align: center;
  color: var(--color-purple);
  font-size: 2.8rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  margin: 0 0 1rem;
  transition: opacity 0.3s ease;
}

.splide__list {
  will-change: transform;
}

.splide__pagination {
  display: flex;
  justify-content: center;
  position: static;
  transform: translate(0);
  width: 100%;
  padding: 0 !important;
}
@media (--screen-large) {
  .splide__pagination {
    display: none;
  }
}

.splide__pagination__page {
  width: 1.4rem;
  height: 1.4rem;
  background: var(--color-title);
  border-radius: 50%;
  margin: 0 1.2rem;
  opacity: 1;
}
.splide__pagination__page.is-active {
  background: var(--color-purple) !important;
  transform: scale(1);
}

.splide--fade > .splide__track > .splide__list > .splide__slide {
  height: 100%;
}

.intro {
  max-inline-size: 75rem;
  font-size: 2.5rem;
  line-height: 1.2;
  color: var(--color-purple);
  letter-spacing: 0.04rem;
  margin-bottom: 5rem !important;
}

.jumbotron__container {
  position: relative;
}
@media (--screen-mini-jumbotron) {
  .jumbotron__container {
    background-color: var(--color-purple);
  }
}
@media (--screen-jumbotron) {
  .jumbotron__container {
    margin-bottom: 12rem;
  }
}

.jumbotron__bg {
  background-color: var(--color-general);
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.3s ease;
}
@media (--screen-mini-jumbotron) {
  .jumbotron__bg {
    max-height: 73rem;
  }
}
.jumbotron__bg::after {
  content: "";
  position: absolute;
  z-index: 0;
  top: -100rem;
  left: 50%;
  margin-left: -250rem;
  width: 500rem;
  height: 200rem;
  background-image: url("../images/general.png");
  background-size: 500rem 200rem;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center;
  will-change: transform;
  transform: translateZ(0);
  transition: background-image 0.3s ease;
  background-position: center center;
  background-repeat: no-repeat;
  animation-name: scale;
}
.jumbotron__container.-fun .jumbotron__bg {
  background-color: var(--color-fun);
}
.jumbotron__container.-fun .jumbotron__bg::after {
  background-image: url("../images/fun.png");
}
.jumbotron__container.-family .jumbotron__bg {
  background-color: var(--color-family);
}
.jumbotron__container.-family .jumbotron__bg::after {
  background-image: url("../images/family.png");
}
.jumbotron__container.-rebellious .jumbotron__bg {
  background-color: var(--color-rebellious);
}
.jumbotron__container.-rebellious .jumbotron__bg::after {
  background-image: url("../images/rebellious.png");
}
.jumbotron__container.-well-informed .jumbotron__bg {
  background-color: var(--color-well-informed);
}
.jumbotron__container.-well-informed .jumbotron__bg::after {
  background-image: url("../images/well-informed.png");
}

@keyframes scale {
  from {
    transform: scale(0.75);
  }
  to {
    transform: scale(0.85);
  }
}
.jumbotron {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
}
@media (--screen-jumbotron) {
  .jumbotron {
    flex-direction: row;
    max-height: 64rem;
  }
}

.jumbotron__events {
  margin-bottom: 2rem;
}
@media (--screen-jumbotron) {
  .jumbotron__events {
    margin: 0 0 -9rem 0;
    width: calc(100% - 37rem);
    max-width: 87rem;
  }
}
.jumbotron__events .splide__track {
  height: 100%;
}

.jumbotron__events__list {
  margin: 0;
  height: 100% !important;
  background-color: var(--color-general-bg);
}
.jumbotron__container.-fun .jumbotron__events__list {
  background-color: var(--color-fun);
}
.jumbotron__container.-family .jumbotron__events__list {
  background-color: var(--color-family);
}
.jumbotron__container.-rebellious .jumbotron__events__list {
  background-color: var(--color-rebellious);
}
.jumbotron__container.-well-informed .jumbotron__events__list {
  background-color: var(--color-well-informed);
}

.jumbotron__event {
  font: 0/0 sans-serif;
  display: flex;
  flex-direction: column;
  background-color: var(--color-purple);
  position: relative;
  text-decoration: none;
  height: 100%;
  transition: background-color 0.3s ease;
}
.jumbotron__event:hover img, .jumbotron__event:focus img {
  opacity: 0.6;
}

.jumbotron__event__img {
  flex: 1 1;
  display: block;
  position: relative;
  min-height: 35rem;
}
@media (--screen-jumbotron) {
  .jumbotron__event__img {
    min-height: auto;
  }
}
.jumbotron__event__img img {
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  position: absolute;
  object-fit: cover;
  min-height: 35rem;
}

.jumbotron__event__info {
  color: var(--color-infocard);
  font-size: 2.2rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  flex-basis: 100%;
  width: 100%;
  box-sizing: border-box;
  transition: opacity 0.3s ease;
  background-color: var(--color-purple);
}
@media (--screen-jumbotron) {
  .jumbotron__event__info {
    padding-bottom: 9rem;
    flex: 0 1;
  }
}
.jumbotron__event:hover .jumbotron__event__info, .jumbotron__event:focus .jumbotron__event__info {
  opacity: 0.6;
}

.jumbotron__event__title {
  font-size: 4.2rem;
  line-height: 1.15;
  display: block;
  padding: 2rem;
  white-space: normal;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-general);
  max-width: 76rem;
}
.jumbotron__event.-fun .jumbotron__event__title {
  font-family: var(--font-fun);
  color: var(--color-fun);
  letter-spacing: 0.05rem;
}
.jumbotron__event.-family .jumbotron__event__title {
  font-family: var(--font-family);
  color: var(--color-family);
  letter-spacing: -0.3rem;
}
.jumbotron__event.-rebellious .jumbotron__event__title {
  font-family: var(--font-rebellious);
  color: var(--color-rebellious);
  letter-spacing: -0.4rem;
}
.jumbotron__event.-well-informed .jumbotron__event__title {
  font-family: var(--font-well-informed);
  color: var(--color-well-informed);
}
@media (--screen-jumbotron) {
  .jumbotron__event__title {
    padding: 3rem;
    font-size: 6.4rem;
  }
}
.jumbotron__event__title em {
  display: block;
  font-style: normal;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 2.8rem;
  padding-top: 1rem;
  letter-spacing: 0;
}

.jumbotron__event__date {
  display: block;
  height: 9rem;
  line-height: 9rem;
  box-sizing: border-box;
  padding: 0 2rem;
  font-size: 2.2rem;
  color: var(--color-general);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  position: relative;
  text-transform: uppercase;
}
.jumbotron__event.-fun .jumbotron__event__date {
  color: var(--color-fun);
}
.jumbotron__event.-family .jumbotron__event__date {
  color: var(--color-family);
}
.jumbotron__event.-rebellious .jumbotron__event__date {
  color: var(--color-rebellious);
}
.jumbotron__event.-well-informed .jumbotron__event__date {
  color: var(--color-well-informed);
}
@media (--screen-jumbotron) {
  .jumbotron__event__date {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 3rem;
  }
}

.jumbotron__event__tickets {
  display: block;
  height: 9rem;
  line-height: 9rem;
  box-sizing: border-box;
  padding: 0 2rem;
  background-color: var(--color-general);
  color: var(--color-purple);
  font-size: 2.2rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  position: relative;
  transition: background-color 0.3s ease;
}
.jumbotron__event.-fun .jumbotron__event__tickets {
  background-color: var(--color-fun);
}
.jumbotron__event.-family .jumbotron__event__tickets {
  background-color: var(--color-family);
}
.jumbotron__event.-rebellious .jumbotron__event__tickets {
  background-color: var(--color-rebellious);
}
.jumbotron__event.-well-informed .jumbotron__event__tickets {
  background-color: var(--color-well-informed);
}
@media (--screen-jumbotron) {
  .jumbotron__event__tickets {
    font-size: 3.2rem;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 5rem;
  }
}
@media (--screen-mini-medium) {
  .jumbotron__event__tickets .icon {
    position: absolute;
    right: 2rem;
    top: 3.5rem;
  }
}

.jumbotron__aside {
  font: 0/0 sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (--screen-medium-large) {
  .jumbotron__aside {
    flex-direction: row;
  }
}
@media (--screen-jumbotron) {
  .jumbotron__aside {
    min-width: 32rem;
    max-width: 37rem;
    flex-direction: column;
  }
}
@media (--screen-full) {
  .jumbotron__aside {
    min-width: 37rem;
  }
}
@media (--screen-mini-jumbotron) {
  .jumbotron__aside {
    padding-bottom: 2rem;
  }
}
.jumbotron__aside::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(48, 0, 41, 0.3), rgba(48, 0, 41, 0.3));
  z-index: 1;
}

.jumbotron__aside__item {
  flex-basis: 50%;
  margin: 0 0 2rem 0;
  position: relative;
  background-color: var(--color-purple);
}
@media (--screen-medium-large) {
  .jumbotron__aside__item {
    margin: 0 0 2rem 2rem;
  }
  .jumbotron__aside__item:first-child {
    margin-left: 0;
  }
}
@media (--screen-jumbotron) {
  .jumbotron__aside__item {
    margin: 0;
    max-height: 32rem;
  }
}
.jumbotron__aside__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
  min-height: 28rem;
}
.jumbotron__aside__item:hover img, .jumbotron__aside__item:focus img {
  opacity: 0.6;
}

.jumbotron__aside__item__info {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  padding: 3rem;
  transition: opacity 0.3s ease;
}
.jumbotron__aside__item:hover .jumbotron__aside__item__info, .jumbotron__aside__item:focus .jumbotron__aside__item__info {
  opacity: 0.6;
}

.jumbotron__aside__item__title {
  display: block;
  font-size: 3.5rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  line-height: 1.15;
  color: var(--color-general);
  padding: 2rem 0;
  word-break: break-word;
}
.jumbotron__aside__item__title.-small {
  font-size: 2.8rem;
}
.jumbotron__aside__item__title.-smaller {
  font-size: 2.1rem;
}

.jumbotron__aside__item__extra {
  display: block;
  font-size: 1.6rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  line-height: 1.15;
  color: var(--color-general);
}
.jumbotron__aside__item__extra.-upper {
  text-transform: uppercase;
}

.jumbotron__arrows {
  position: absolute;
  top: 2rem;
  left: 2rem;
  display: none;
}
@media (--screen-jumbotron) {
  .jumbotron__arrows {
    display: block;
  }
}

.jumbotron__arrows .jumbotron__arrow {
  background: var(--color-purple);
  border-radius: 50%;
  width: 4.2rem;
  height: 4.2rem;
  display: inline-block;
  cursor: pointer;
  transition: opacity 0.3s ease;
  outline: none;
  border: 0;
  opacity: 1;
  position: static;
  transform: translate(0);
  margin-right: 0.3rem;
}
.jumbotron__arrows .jumbotron__arrow:--focus {
  opacity: 0.5 !important;
  background: var(--color-purple);
}
.jumbotron__arrows .jumbotron__arrow::before {
  display: none;
}

.splide__arrows.-hide {
  display: none;
}

.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 0;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

.glightbox-container .gslider {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom, .glightbox-container .ginner-container.desc-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.glightbox-container .ginner-container.desc-left, .glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe, .gslide video {
  outline: 0 !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto;
}

.gslide:not(.current) {
  pointer-events: none;
}

.gslide-image {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  display: block;
  padding: 0;
  float: none;
  outline: 0;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  margin: auto;
  min-width: 200px;
}

.desc-bottom .gslide-image img, .desc-top .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img, .desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important;
}

.gslide-video .plyr__poster-enabled.plyr--loading .plyr__poster {
  display: none;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
  margin: auto;
}

.gslide-video::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none;
}

.gslide-video.playing::before {
  display: none;
}

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 75vh;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  background: #fff;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%;
  margin: auto;
}

.gslide-inline .ginlined-content {
  padding: 20px;
  width: 100%;
}

.gslide-inline .dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1;
}

.gslide-external {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%;
}

.gslide-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.desc-bottom .gslide-media, .desc-top .gslide-media {
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gslide-description {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.gslide-description.description-left, .gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom, .gslide-description.description-top {
  margin: 0 auto;
  width: 100%;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description {
  display: none;
}

.glightbox-button-hidden {
  display: none;
}

.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 19px 11px;
  max-width: 100vw !important;
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.75)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.75) 100%);
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  padding-bottom: 50px;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: 700;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: 0.4;
}

.gdesc-open .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 0.4;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.greset {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  -webkit-animation: lightboxLoader 0.8s infinite linear;
  animation: lightboxLoader 0.8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gclose, .gnext, .gprev {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gclose svg, .gnext svg, .gprev svg {
  display: block;
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
}

.gclose.disabled, .gnext.disabled, .gprev.disabled {
  opacity: 0.1;
}

.gclose .garrow, .gnext .garrow, .gprev .garrow {
  stroke: #fff;
}

.gbtn.focused {
  outline: 2px solid #0f3d81;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gclose, .glightbox-closing .gnext, .glightbox-closing .gprev {
  opacity: 0 !important;
}

.glightbox-clean .gslide-description {
  background: #fff;
}

.glightbox-clean .gdesc-inner {
  padding: 22px 20px;
}

.glightbox-clean .gslide-title {
  font-size: 1em;
  font-weight: 400;
  font-family: arial;
  color: #000;
  margin-bottom: 19px;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  font-size: 0.86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}

.glightbox-clean .gclose path, .glightbox-clean .gnext path, .glightbox-clean .gprev path {
  fill: #fff;
}

.glightbox-clean .gprev {
  position: absolute;
  top: -100%;
  left: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gnext {
  position: absolute;
  top: -100%;
  right: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  top: 15px;
  right: 10px;
  position: absolute;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}

.gfadeIn {
  -webkit-animation: gfadeIn 0.5s ease;
  animation: gfadeIn 0.5s ease;
}

.gfadeOut {
  -webkit-animation: gfadeOut 0.5s ease;
  animation: gfadeOut 0.5s ease;
}

.gslideOutLeft {
  -webkit-animation: gslideOutLeft 0.3s ease;
  animation: gslideOutLeft 0.3s ease;
}

.gslideInLeft {
  -webkit-animation: gslideInLeft 0.3s ease;
  animation: gslideInLeft 0.3s ease;
}

.gslideOutRight {
  -webkit-animation: gslideOutRight 0.3s ease;
  animation: gslideOutRight 0.3s ease;
}

.gslideInRight {
  -webkit-animation: gslideInRight 0.3s ease;
  animation: gslideInRight 0.3s ease;
}

.gzoomIn {
  -webkit-animation: gzoomIn 0.5s ease;
  animation: gzoomIn 0.5s ease;
}

.gzoomOut {
  -webkit-animation: gzoomOut 0.5s ease;
  animation: gzoomOut 0.5s ease;
}

@-webkit-keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .glightbox-container .ginner-container.desc-top .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .glightbox-container .ginner-container.desc-top .gslide-image, .glightbox-container .ginner-container.desc-top .gslide-image img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .glightbox-container .ginner-container.desc-left .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .glightbox-container .ginner-container.desc-left .gslide-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .gslide-image img {
    max-height: 97vh;
    max-width: 100%;
  }
  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab;
  }
  .gslide-inline {
    max-height: 95vh;
  }
  .gslide-external {
    max-height: 100vh;
  }
  .gslide-description.description-left, .gslide-description.description-right {
    max-width: 275px;
  }
  .glightbox-open {
    height: auto;
  }
  .goverlay {
    background: rgba(0, 0, 0, 0.92);
  }
  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65);
  }
  .glightbox-clean .description-left .gdesc-inner, .glightbox-clean .description-right .gdesc-inner {
    position: absolute;
    height: 100%;
    overflow-y: auto;
  }
  .glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
    background-color: rgba(0, 0, 0, 0.32);
  }
  .glightbox-clean .gclose:hover, .glightbox-clean .gnext:hover, .glightbox-clean .gprev:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .glightbox-clean .gprev {
    top: 45%;
  }
  .glightbox-clean .gnext {
    top: 45%;
  }
}
@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: 0.7;
    right: 20px;
  }
}
@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}
ul:not([class]) {
  margin: 2rem 2rem !important;
  padding: 0;
}
ul:not([class]) li {
  padding: 0 0 1rem;
  position: relative;
  font-size: 1.8rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
}
ul:not([class]) li ul {
  margin-top: 1rem !important;
}

ol:not([class]) {
  margin: 2rem 0 !important;
  padding: 0;
  list-style: none;
  counter-reset: section;
}
ol:not([class]) li {
  padding: 1rem 0 1rem 3rem;
  position: relative;
  font-size: 1.8rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  counter-increment: step-counter;
  min-height: 3rem;
  border-top: 0.1rem solid var(--color-purple);
}
ol:not([class]) li::before {
  content: counter(step-counter);
  display: block;
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 0.9rem;
  left: 0;
  line-height: 3rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.8rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  text-align: left;
}
.event-detail__content.-family ol:not([class]) li::before {
  background-color: var(--color-family);
}
.event-detail__content.-fun ol:not([class]) li::before {
  background-color: var(--color-fun);
}
.event-detail__content.-rebellious ol:not([class]) li::before {
  background-color: var(--color-rebellious);
}
.event-detail__content.-well-informed ol:not([class]) li::before {
  background-color: var(--color-well-informed);
}
ol:not([class]) li ul {
  margin-top: 1rem !important;
}

.logo {
  position: relative;
  vertical-align: middle;
  font: 0/0 sans-serif;
  display: inline-block;
  width: 13.5rem;
  height: 13.5rem;
  color: var(--color-general);
}
.page-header .logo {
  width: 8rem;
  height: 8rem;
}
@media (--screen-medium-large) {
  .page-header .logo {
    width: 13.5rem;
    height: 13.5rem;
  }
}
.page-footer .logo {
  margin-top: -12rem;
}
@media (--screen-large) {
  .page-footer .logo {
    margin: 0;
    width: 20rem;
    height: 20rem;
  }
}
.page-header.-myab .logo {
  width: 10rem;
  height: 10rem;
}
@media (--screen-medium-large) {
  .page-header.-myab .logo {
    width: 13.5rem;
    height: 13.5rem;
  }
}
.logo::before, .logo:after {
  display: none;
  content: "";
  transition: all 0.3s ease;
  width: 13.5rem;
  height: 13.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: var(--color-purple);
  background-image: url(stroke-general.svg);
  background-size: 13.5rem 13.5rem;
}
@supports (mask-repeat: no-repeat) {
  .logo::before, .logo:after {
    background: var(--color-purple);
    mask-repeat: no-repeat;
    mask-image: url(stroke-general.svg);
    mask-size: 13.5rem 13.5rem;
  }
}
.page-header .logo::before, .page-header .logo:after {
  width: 8rem;
  height: 8rem;
  background-size: 8rem 8rem;
}
@supports (mask-repeat: no-repeat) {
  .page-header .logo::before, .page-header .logo:after {
    mask-size: 8rem 8rem;
  }
}
@media (--screen-medium-large) {
  .page-header .logo::before, .page-header .logo:after {
    width: 13.5rem;
    height: 13.5rem;
    background-size: 13.5rem 13.5rem;
  }
  @supports (mask-repeat: no-repeat) {
    .page-header .logo::before, .page-header .logo:after {
      mask-size: 13.5rem 13.5rem;
    }
  }
}
.page-header.-myab .logo::before, .page-header.-myab .logo:after {
  width: 8rem;
  height: 8rem;
}
@media (--screen-large) {
  .page-footer .logo::before, .page-footer .logo:after {
    width: 20rem;
    height: 20rem;
    background-size: 20rem 20rem;
  }
  @supports (mask-repeat: no-repeat) {
    .page-footer .logo::before, .page-footer .logo:after {
      mask-size: 20rem 20rem;
    }
  }
}
.logo::after {
  z-index: 0;
  background-color: var(--color-general);
  background-image: url(bg-general.svg);
  transform: scale(0.99) translate(-50%, -50%);
}
.page-footer .logo::after {
  background-color: var(--color-purple) !important;
}
@supports (mask-repeat: no-repeat) {
  .logo::after {
    background: var(--color-general);
    mask-image: url(bg-general.svg);
  }
  .page-footer .logo::after {
    background: var(--color-purple) !important;
  }
}

.logo.-stroke::before {
  display: inline-block;
}

.logo.-bg::after,
.page-footer .logo::after {
  display: inline-block;
}

.logo__ab {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: background 0.3s ease;
  display: inline-block;
  width: 9.7rem;
  height: 9.7rem;
  background-color: var(--color-purple);
  background-image: url(ab.svg);
  background-size: 9.7rem 9.7rem;
}
@supports (mask-repeat: no-repeat) {
  .logo__ab {
    background: var(--color-purple);
    mask-repeat: no-repeat;
    mask-image: url(ab.svg);
    mask-size: 9.7rem 9.7rem;
  }
}
.page-header .logo__ab {
  width: 6rem;
  height: 6rem;
  background-size: 6rem 6rem;
}
@supports (mask-repeat: no-repeat) {
  .page-header .logo__ab {
    mask-size: 6rem 6rem;
  }
}
@media (--screen-medium-large) {
  .page-header .logo__ab {
    width: 9.7rem;
    height: 9.7rem;
    background-size: 9.7rem 9.7rem;
  }
  @supports (mask-repeat: no-repeat) {
    .page-header .logo__ab {
      mask-size: 9.7rem 9.7rem;
    }
  }
}
@media (--screen-large) {
  .page-footer .logo__ab {
    width: 13rem;
    height: 13rem;
    background-size: 13rem 13rem;
  }
  @supports (mask-repeat: no-repeat) {
    .page-footer .logo__ab {
      mask-size: 13rem 13rem;
    }
  }
}

.logo.-general::before {
  background-color: var(--color-general);
  background-image: url(stroke-general.svg);
}
@supports (mask-repeat: no-repeat) {
  .logo.-general::before {
    background: var(--color-general);
    mask-image: url(stroke-general.svg);
  }
}
.logo.-general::after {
  background-color: var(--color-general);
  background-image: url(bg-general.svg);
}
.page-header .logo.-general::after {
  background-color: var(--color-purple);
}
@supports (mask-repeat: no-repeat) {
  .logo.-general::after {
    background: var(--color-general);
    mask-image: url(bg-general.svg);
  }
  .page-header .logo.-general::after {
    background: var(--color-purple);
  }
}
.logo.-general .logo__ab {
  background-color: var(--color-general);
}
@supports (mask-repeat: no-repeat) {
  .logo.-general .logo__ab {
    background: var(--color-general);
  }
}

.logo.-well-informed::before {
  background-color: var(--color-well-informed);
  background-image: url(stroke-well-informed.svg);
}
@supports (mask-repeat: no-repeat) {
  .logo.-well-informed::before {
    background: var(--color-well-informed);
    mask-image: url(stroke-well-informed.svg);
  }
}
.logo.-well-informed::after {
  background-color: var(--color-well-informed);
  background-image: url(bg-well-informed.svg);
}
.page-header .logo.-well-informed::after {
  background-color: var(--color-purple);
}
@supports (mask-repeat: no-repeat) {
  .logo.-well-informed::after {
    background: var(--color-well-informed);
    mask-image: url(bg-well-informed.svg);
  }
  .page-header .logo.-well-informed::after {
    background: var(--color-purple);
  }
}
.logo.-well-informed .logo__ab {
  background-color: var(--color-well-informed);
}
@supports (mask-repeat: no-repeat) {
  .logo.-well-informed .logo__ab {
    background: var(--color-well-informed);
  }
}

.logo.-family::before {
  background-color: var(--color-family);
  background-image: url(stroke-family.svg);
}
@supports (mask-repeat: no-repeat) {
  .logo.-family::before {
    background: var(--color-family);
    mask-image: url(stroke-family.svg);
  }
}
.logo.-family::after {
  background-color: var(--color-family);
  background-image: url(bg-family.svg);
}
.page-header .logo.-family::after {
  background-color: var(--color-purple);
}
@supports (mask-repeat: no-repeat) {
  .logo.-family::after {
    background: var(--color-family);
    mask-image: url(bg-family.svg);
  }
  .page-header .logo.-family::after {
    background: var(--color-purple);
  }
}
.logo.-family .logo__ab {
  background-color: var(--color-family);
}
@supports (mask-repeat: no-repeat) {
  .logo.-family .logo__ab {
    background: var(--color-family);
  }
}

.logo.-fun::before {
  background-color: var(--color-fun);
  background-image: url(stroke-fun.svg);
}
@supports (mask-repeat: no-repeat) {
  .logo.-fun::before {
    background: var(--color-fun);
    mask-image: url(stroke-fun.svg);
  }
}
.logo.-fun::after {
  background-color: var(--color-fun);
  background-image: url(bg-fun.svg);
}
.page-header .logo.-fun::after {
  background-color: var(--color-purple);
}
@supports (mask-repeat: no-repeat) {
  .logo.-fun::after {
    background: var(--color-fun);
    mask-image: url(bg-fun.svg);
  }
  .page-header .logo.-fun::after {
    background: var(--color-purple);
  }
}
.logo.-fun .logo__ab {
  background-color: var(--color-fun);
}
@supports (mask-repeat: no-repeat) {
  .logo.-fun .logo__ab {
    background: var(--color-fun);
  }
}

.logo.-rebellious::before {
  background-color: var(--color-rebellious);
  background-image: url(stroke-rebellious.svg);
}
@supports (mask-repeat: no-repeat) {
  .logo.-rebellious::before {
    background: var(--color-rebellious);
    mask-image: url(stroke-rebellious.svg);
  }
}
.logo.-rebellious::after {
  background-color: var(--color-rebellious);
  background-image: url(bg-rebellious.svg);
}
.page-header .logo.-rebellious::after {
  background-color: var(--color-purple);
}
@supports (mask-repeat: no-repeat) {
  .logo.-rebellious::after {
    background: var(--color-rebellious);
    mask-image: url(bg-rebellious.svg);
  }
  .page-header .logo.-rebellious::after {
    background: var(--color-purple);
  }
}
.logo.-rebellious .logo__ab {
  background-color: var(--color-rebellious);
}
@supports (mask-repeat: no-repeat) {
  .logo.-rebellious .logo__ab {
    background: var(--color-rebellious);
  }
}

.logo.-purple::before {
  background-color: var(--color-purple);
  background-image: url(stroke-purple.svg);
}
@supports (mask-repeat: no-repeat) {
  .logo.-purple::before {
    background: var(--color-purple);
    mask-image: url(stroke-purple.svg);
  }
}
.logo.-purple::after {
  background-color: var(--color-purple);
  background-image: url(bg-purple.svg);
}
.page-header .logo.-purple::after {
  background-color: var(--color-purple);
}
@supports (mask-repeat: no-repeat) {
  .logo.-purple::after {
    background: var(--color-purple);
    mask-image: url(bg-purple.svg);
  }
  .page-header .logo.-purple::after {
    background: var(--color-purple);
  }
}
.logo.-purple .logo__ab {
  background-color: var(--color-purple);
}
@supports (mask-repeat: no-repeat) {
  .logo.-purple .logo__ab {
    background: var(--color-purple);
  }
}

main {
  flex: 1 0 auto;
  z-index: 2;
}
html:not([data-scroll="0"]) main, html.-fixed main {
  padding-top: 6.9rem;
}

.marquee {
  font-size: 3rem;
  position: relative;
  z-index: 0;
  display: block;
  font-family: var(--font-general);
  line-height: 1.5;
  color: var(--color-purple-dark);
  text-decoration: none !important;
  letter-spacing: 0.04rem;
}
[data-color=white] .marquee {
  color: var(--color-white);
}

.marquee__background {
  position: absolute;
  z-index: -1;
  inset: 0;
  background-image: url("pattern-rebellious.png");
  background-repeat: repeat-x;
  background-position: center;
  background-size: cover;
}
[data-animation] .marquee__background {
  animation: animated-background 14s infinite linear;
}
[data-theme=fun] .marquee__background {
  background-image: url("pattern-fun.png");
}
[data-theme=family] .marquee__background {
  background-image: url("pattern-family.png");
}
.marquee:--focus .marquee__background {
  animation-play-state: paused;
}
@media (prefers-reduced-motion) {
  .marquee__background {
    animation: none;
  }
}

.marquee__inner {
  display: flex;
  align-items: flex-start;
  min-block-size: 6.5rem !important;
}

.node-marquee__el {
  display: flex !important;
  padding-block: 1.5rem;
}
.node-marquee__el::after {
  content: "•";
  display: inline-block;
  margin-inline: 1rem;
}
.node-marquee__el p {
  max-inline-size: none;
  margin: 0;
}

@keyframes animated-background {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1920px 0;
  }
}
.menu-button {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0 1.5rem 0 0;
  padding: 0;
  overflow: visible;
}

.menu-button:hover {
  opacity: 0.7;
}

.menu-button.-active:hover {
  opacity: 0.7;
}

.menu-button.-active .menu-button__hamburger-inner,
.menu-button.-active .menu-button__hamburger-inner::before,
.menu-button.-active .menu-button__hamburger-inner::after {
  background-color: #000;
}

.menu-button__hamburger {
  width: 1.6rem;
  height: 1.2rem;
  display: inline-block;
  position: relative;
}

.menu-button__hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -0.2rem;
}

.menu-button__hamburger-inner, .menu-button__hamburger-inner::before, .menu-button__hamburger-inner::after {
  width: 1.6rem;
  height: 0.15rem;
  background-color: var(--color-purple);
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.page-header.-myab .menu-button__hamburger-inner, .page-header.-myab .menu-button__hamburger-inner::before, .page-header.-myab .menu-button__hamburger-inner::after {
  background-color: var(--color-general);
}

.menu-button__hamburger-inner::before, .menu-button__hamburger-inner::after {
  content: "";
  display: block;
}

.menu-button__hamburger-inner::before {
  top: -0.6rem;
}

.menu-button__hamburger-inner::after {
  bottom: -0.6rem;
}

/*
* Spin
*/
.menu-button .menu-button__hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.menu-button .menu-button__hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.menu-button .menu-button__hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.menu-button.-active .menu-button__hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.menu-button.-active .menu-button__hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.menu-button.-active .menu-button__hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.myab-login {
  background-image: url(../images/general.svg);
  background-repeat: no-repeat;
  background-position: 50% -124rem;
  background-size: 250rem 250rem;
  padding: 10rem 0 3rem;
}
@media (--screen-large) {
  .myab-login {
    padding: 10rem 0;
  }
}

.myab__box {
  background: var(--color-white);
  margin: 0 auto;
  width: 100%;
  max-width: 54rem;
  padding: 2rem 2rem 0;
  box-sizing: border-box;
  text-align: center;
}
@media (--screen-large) {
  .myab__box {
    padding: 3rem 7rem 0;
  }
}
.myab__box.-padded {
  padding-bottom: 2rem;
}
@media (--screen-large) {
  .myab__box.-padded {
    padding-bottom: 4rem;
  }
}
.myab__box.-top {
  padding-top: 0;
}
.myab__box.-form {
  padding-left: 0;
  padding-right: 0;
}
.myab__box a:not(.form__link) {
  color: var(--color-purple);
  text-decoration: underline;
  font-family: var(--font-general);
  font-weight: var(--font-weight-medium);
}
.myab__box a:not(.form__link):hover, .myab__box a:not(.form__link):focus {
  text-decoration: none;
}
.myab__box.-loading {
  position: relative;
}
.myab__box.-loading::before {
  content: "";
  background: url(refresh.svg);
  display: block;
  width: 10rem;
  height: 10rem;
  background-size: 10rem 10rem;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -5rem 0 0 -5rem;
  z-index: 2;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center center;
  will-change: transform;
  animation-name: turn;
}
@media (--screen-medium-large) {
  .myab__box.-loading::before {
    position: absolute;
  }
}
.myab__box.-loading::after {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--color-general);
  opacity: 0.4;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
@media (--screen-medium-large) {
  .myab__box.-loading::after {
    position: absolute;
  }
}

.myab__box__title {
  display: block;
  text-align: center;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 4.3rem;
  margin-bottom: 3rem;
}

@keyframes turn {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.myab-popup {
  border: 0;
  border-radius: 0.4rem;
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
}
.myab-popup::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
}
.myab-popup.-open {
  display: block;
}
.myab-popup .myab__box {
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
}

.myab__header {
  background-color: var(--color-purple);
  padding: 6rem 0 0;
  margin-bottom: 4rem;
}
@media (--screen-medium-large) {
  .myab__header {
    padding: 10rem 0 0;
    margin-bottom: 8rem;
  }
}
.myab__header.-artists {
  padding: 3rem 0 0;
  margin-bottom: 2rem;
}
@media (--screen-medium-large) {
  .myab__header.-artists {
    padding: 5rem 0 0;
    margin-bottom: 4rem;
  }
}

.myab.-bottom {
  margin-bottom: 4rem;
}
@media (--screen-medium-large) {
  .myab.-bottom {
    margin-bottom: 8rem;
  }
}

.myab__header__title {
  display: block;
  color: var(--color-general);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 4.2rem;
  text-align: center;
  margin: 0 0 3rem;
}
@media (--screen-large) {
  .myab__header__title {
    font-size: 6.8rem;
  }
}
.myab__header__title::after {
  content: "";
  width: 4.2rem;
  height: 4.8rem;
  background-image: url(hand.svg);
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  margin-left: 1.5rem;
}

.myab__nav {
  padding: 0.5rem 0;
  text-align: center;
}

.myab__nav__item {
  font-family: var(--font-general);
  font-weight: var(--font-weight-medium);
  font-size: 1.8rem;
  color: var(--color-purple);
  opacity: 0.3;
  transition: opacity 0.3s ease;
  text-decoration: none;
  margin: 1.5rem;
  display: inline-block;
}
@media (--screen-medium-large) {
  .myab__nav__item {
    font-size: 2.4rem;
  }
}
.myab__nav__item.-active, .myab__nav__item:hover, .myab__nav__item:focus {
  opacity: 1;
}

.myab__alert {
  margin: 2rem 0;
  border: 0.1rem dotted var(--color-border-dark);
  padding: 2rem;
  text-align: center;
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.8rem;
  line-height: 1.5;
}
@media (--screen-medium-large) {
  .myab__alert {
    padding: 5rem;
    margin: 4rem 0;
  }
}
.myab__alert * {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}
.myab__alert.-top {
  margin-top: 0;
}
.myab__alert.-nb {
  margin-top: 0;
  padding: 0;
  border: 0;
}
.myab__alert a {
  outline: none;
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  text-decoration: underline !important;
}
.myab__alert a:hover {
  text-decoration: none !important;
}
.myab__alert a.button {
  text-decoration: none !important;
}

.myab__alert__title {
  font-size: 2.8rem;
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  display: block;
  margin-bottom: 2.5rem;
}

.myab__title {
  font-size: 4.2rem;
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
}

@media (--screen-large) {
  .myab__search {
    display: flex;
    align-items: center;
  }
}
.myab__search .form {
  width: 100%;
  max-width: 45rem;
  position: relative;
  z-index: 1;
  margin-top: 2rem;
}
@media (--screen-large) {
  .myab__search .form {
    margin-left: auto;
    margin-top: 0;
  }
}

.myab__search__input {
  border: 0.1rem solid var(--color-border-dark);
  border-radius: 7rem;
  padding: 2rem 2rem 2rem 5.6rem;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  font-size: 1.6rem;
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  background-image: url(search.svg);
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: 2rem 2rem;
}

.myab__cta {
  background-color: var(--color-rebellious);
  background-size: 200%;
  background-repeat: no-repeat;
  background-position: 52% 50%;
  padding: 4rem 0 0;
}
@media (--screen-full) {
  .myab__cta {
    background-size: cover;
    background-image: url("rebellious.svg");
    padding: 11rem 0;
  }
}

.myab__cta__content {
  margin: 0 auto;
  text-align: center;
  font-size: 1.8rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  line-height: 1.5;
}
@media (--screen-full) {
  .myab__cta__content {
    max-width: 50rem;
  }
}
.myab__cta__content .button {
  margin-top: 2rem;
}
.myab__cta__content p {
  padding: 0 var(--side-padding);
  margin-top: 0;
}
@media (--screen-full) {
  .myab__cta__content p {
    padding: 0;
  }
}

.myab__cta__title {
  font-size: 2.6rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  line-height: 1.15;
  padding: 0 var(--side-padding) 4rem;
  display: block;
}
@media (--screen-full) {
  .myab__cta__title {
    font-size: 3.2rem;
    padding: 0 0 1.5rem;
  }
}

.myab__cta__button {
  padding: 5rem 2rem;
  background: url("../images/rebellious.svg") no-repeat 45% 55%;
  background-size: 250rem 250rem;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
}
@media (--screen-full) {
  .myab__cta__button {
    background: none;
    margin-left: auto;
    width: auto;
    padding: 2rem 4rem 0;
  }
}
.myab__cta__button .button {
  margin: 0;
}

.newsletter__container {
  background: var(--color-general);
  padding: 11rem 0;
  background-size: 285%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
@media (--screen-small) {
  .newsletter__container {
    background-size: 230%;
  }
}
@media (--screen-medium) {
  .newsletter__container {
    background-size: 185%;
  }
}
@media (--screen-large) {
  .newsletter__container {
    background-size: 140%;
  }
}
@media (--screen-full) {
  .newsletter__container {
    background-size: cover;
  }
}
@media (--screen-medium-large) {
  .newsletter__container {
    padding: 14rem 0;
  }
}
.newsletter__container.-rebellious {
  background-color: var(--color-rebellious);
  background-image: url("rebellious.svg");
}
.newsletter__container.-fun {
  background-color: var(--color-fun);
  background-image: url("fun.svg");
}
.newsletter__container.-family {
  background-color: var(--color-family);
  background-image: url("family.svg");
}
.newsletter__container.-well-informed {
  background-color: var(--color-well-informed);
  background-image: url("well-informed.svg");
}

.newsletter {
  max-width: 26rem;
  margin: 0 auto;
  text-align: center;
  font-size: 3.2rem;
  font-family: var(--font-general);
  color: var(--color-purple);
  line-height: 1.15;
}
@media (--screen-small) {
  .newsletter {
    max-width: 32rem;
  }
}
@media (--screen-medium-large) {
  .newsletter {
    max-width: 32rem;
    font-size: 3.5rem;
  }
}
@media (--screen-full) {
  .newsletter {
    max-width: 40rem;
  }
}

.newsletter__input {
  position: relative;
  margin: 1.5rem auto 1rem;
}
.newsletter__input input {
  padding-right: 5rem;
}

.newsletter__button {
  position: absolute;
  top: 1.2rem;
  right: 0;
  width: 3rem;
  height: 3rem;
  border: 0.1rem solid var(--color-purple);
  background-color: var(--color-purple);
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
}
.newsletter__button::before {
  background-color: var(--color-general) !important;
  width: 1.3rem !important;
  height: 1.3rem !important;
  background-size: 1.3rem 1.3rem !important;
}
@supports (mask-repeat: no-repeat) {
  .newsletter__button::before {
    mask-size: 1.3rem 1.3rem !important;
  }
}
.newsletter__button:hover, .newsletter__button:focus {
  background-color: var(--color-general);
  border-color: var(--color-general);
}
.newsletter__button:hover::before, .newsletter__button:focus::before {
  background: var(--color-purple) !important;
}

.newsletter__info {
  display: block;
  padding-top: 2rem;
  font-size: 1.4rem;
  color: var(--color-purple);
}
.newsletter__info a {
  color: var(--color-purple);
  text-decoration: underline;
}
.newsletter__info a:hover, .newsletter__info a:focus {
  text-decoration: none;
}

.oEmbedMedia {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.oEmbedMedia[data-type=video] {
  height: 0;
  padding-bottom: 56.25%; /*16:9*/
}
.oEmbedMedia[data-type=video] iframe {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.other__container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-bottom: 3rem;
}
.page-detail__content__info .other__container, .event-detail__content .other__container {
  max-width: 75rem;
  margin: 0 auto 3rem;
}
.other__container iframe {
  max-width: 100% !important;
}

.page-detail__header {
  position: relative;
}
.page-detail__header .site-container {
  position: relative;
  z-index: 1;
}

.page-detail__header__img {
  display: block;
  width: 100%;
  max-width: 144rem;
  margin: 0 auto 2rem auto;
  height: auto;
  min-height: 35rem;
  max-height: 60rem;
  object-fit: cover;
}
@media (--screen-large) {
  .page-detail__header__img {
    margin: 0 auto 5rem auto;
  }
}

.page-detail__header__title {
  font-weight: var(--font-weight-regular);
  font-family: var(--font-general);
  font-size: 4.2rem;
  line-height: 1.3;
  max-width: 75rem;
  margin: 0 auto 2rem;
  color: var(--color-title);
  text-align: center;
}
@media (--screen-large) {
  .page-detail__header__title {
    margin: 0 auto 5rem;
  }
  .page-detail__header__title + .page-detail__header__info {
    margin-top: -3rem;
  }
}

.page-detail__header__info {
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.7rem;
  color: var(--color-purple);
  opacity: 0.6;
  max-width: 75rem;
  margin: 0 auto 2rem;
  display: block;
}
@media (--screen-large) {
  .page-detail__header__info {
    font-size: 2.1rem;
    margin: 0 auto 5rem;
  }
}
.page-detail__header__info.-center {
  text-align: center;
}

.page-detail__content {
  box-sizing: border-box;
}
.page-detail__content + .page-detail__content {
  margin-top: -2rem;
}
.page-detail__content.--anchor {
  scroll-margin-top: 10rem;
}
@media (--screen-large) {
  .page-detail__content.--anchor {
    scroll-margin-top: 14rem;
  }
}

.page-detail__content__info {
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0;
  color: var(--color-purple);
  font-family: var(--font-general);
  font-size: 1.8rem;
  font-weight: var(--font-weight-regular);
  line-height: 1.2;
}
.page-detail__content__info a:not([class]) {
  color: var(--color-purple);
  text-decoration: underline;
  font-family: var(--font-general);
  font-weight: var(--font-weight-medium);
}
.page-detail__content__info a:not([class]):hover, .page-detail__content__info a:not([class]):focus {
  text-decoration: none;
}

.page-footer {
  flex-shrink: 0;
  color: var(--color-general);
  font-size: 1.8rem;
  line-height: 1.5;
  z-index: 1;
}

.page-footer__social {
  background-color: var(--color-general);
  padding: 4rem 0;
  text-align: center;
}
.page-footer__social.-family {
  background-color: var(--color-family);
}
.page-footer__social.-fun {
  background-color: var(--color-fun);
}
.page-footer__social.-rebellious {
  background-color: var(--color-rebellious);
}
.page-footer__social.-well-informed {
  background-color: var(--color-well-informed);
}

.page-footer__social-item {
  margin: 1.5rem;
  transition: opacity 0.3s ease;
}
@media (--screen-small-large) {
  .page-footer__social-item {
    margin: 2rem;
  }
}
.page-footer__social-item:first-child {
  margin-left: 0;
}
.page-footer__social-item:last-child {
  margin-right: 0;
}
.page-footer__social-item:hover, .page-footer__social-item:focus {
  opacity: 0.6;
}

.page-footer__top {
  background-color: var(--color-purple);
  border-bottom: 0.1rem solid var(--color-border);
  padding: 8rem 0;
  text-align: center;
}
@media (--screen-large) {
  .page-footer__top {
    text-align: left;
  }
}
@media (--screen-large) {
  .page-footer__top .site-container {
    display: flex;
    justify-content: center;
  }
}
@media (--screen-large) {
  .page-footer__top .logo {
    flex-shrink: 0;
    margin-right: 4.5rem;
  }
}
@media (--screen-full) {
  .page-footer__top .logo {
    margin-right: 14.5rem;
  }
}

.page-footer__newsletter {
  font-size: 2.1rem;
  margin-top: 3.7rem;
  flex-basis: 32.5rem;
}

.page-footer__newsletter__input {
  position: relative;
  margin: 1.5rem auto 1rem;
  max-width: 32rem;
}
.page-footer__newsletter__input input {
  padding-right: 5rem;
}

.page-footer__newsletter__button {
  position: absolute;
  top: 1.2rem;
  right: 0;
  width: 3rem;
  height: 3rem;
  border: 0.1rem solid var(--color-general);
  border-radius: 50%;
  background: var(--color-purple);
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.page-footer__newsletter__button::before {
  background-color: var(--color-general) !important;
  width: 1.3rem !important;
  height: 1.3rem !important;
  background-size: 1.3rem 1.3rem !important;
}
@supports (mask-repeat: no-repeat) {
  .page-footer__newsletter__button::before {
    mask-size: 1.3rem 1.3rem !important;
  }
}
.page-footer__newsletter__button:hover, .page-footer__newsletter__button:focus {
  background-color: var(--color-general);
}
.page-footer__newsletter__button:hover::before, .page-footer__newsletter__button:focus::before {
  background: var(--color-purple) !important;
}

.page-footer__newsletter__info {
  font-size: 1.4rem;
  display: inline-block;
}
.page-footer__newsletter__info a {
  color: var(--color-general);
  text-decoration: underline;
}
.page-footer__newsletter__info a:hover, .page-footer__newsletter__info a:focus {
  text-decoration: none;
}
.page-footer__newsletter__info.-success {
  color: var(--color-rebellious);
}

.page-footer__partners {
  background-color: var(--color-purple);
  border-bottom: 0.1rem solid var(--color-border);
  padding: 1.5rem 0;
}

.page-footer__credits {
  background-color: var(--color-purple);
  padding: 1.5rem 0;
  font-size: 1.4rem;
  color: var(--color-general);
}

.page-footer__credits__list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.page-footer__credits__list li {
  padding: 0.5rem 1.5rem;
  display: inline-block;
}

.page-footer__credits-item {
  opacity: 0.6;
  color: var(--color-general);
  text-decoration: none;
  transition: opacity 0.3s ease;
}
.page-footer__credits-item img {
  vertical-align: middle;
  margin-left: 0.3rem;
  inline-size: revert-layer;
  block-size: revert-layer;
}
.page-footer__credits-item.-inventis img {
  margin-top: -0.4rem;
}
.page-footer__credits-item:hover, .page-footer__credits-item:focus {
  opacity: 1;
}

.page-footer__nav {
  flex-basis: 41.5rem;
  margin-right: 3rem;
  display: none;
}
@media (--screen-large) {
  .page-footer__nav {
    display: block;
  }
}

.page-footer__nav-title {
  opacity: 0.6;
}

.page-footer__nav-list {
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
  columns: 2;
}
.page-footer__nav-list li {
  margin: 0 0 1rem;
  padding: 0;
}

.page-footer__nav-item {
  display: inline-block;
  color: var(--color-general);
  font-size: 2.1rem;
  line-height: 1.5;
  text-decoration: none;
  transition: opacity 0.3s ease;
}
.page-footer__nav-item:hover, .page-footer__nav-item:focus {
  opacity: 0.6;
}

.page-header {
  display: block;
  height: 7rem;
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  background-color: var(--color-general);
  transition: background-color 0.3s ease;
  z-index: 3;
}
.page-header .site-container {
  display: flex;
  align-items: center;
  height: 100%;
}
html:not([data-scroll="0"]) .page-header, html.-fixed .page-header {
  position: fixed;
}
.page-header.-fun {
  background-color: var(--color-fun);
}
.page-header.-family {
  background-color: var(--color-family);
}
.page-header.-rebellious {
  background-color: var(--color-rebellious);
}
.page-header.-well-informed {
  background-color: var(--color-well-informed);
}
.page-header.-myab {
  background-color: var(--color-purple);
}

.page-header__right {
  margin-left: auto;
}

.page-header__link {
  font-family: var(--font-general);
  font-size: 1.8rem;
  color: var(--color-purple);
  text-decoration: none;
  margin: 0 1.5rem;
  transition: opacity 0.3s ease;
}
.page-header__link:last-child {
  margin-right: 0;
}
.page-header__link.-search {
  font: 0/0 sans-serif;
  vertical-align: middle;
}
.page-header__link.-program, .page-header__link.-search {
  display: none;
}
@media (--screen-large) {
  .page-header__link.-program, .page-header__link.-search {
    display: inline-block;
  }
}
.page-header__link:hover, .page-header__link:focus {
  opacity: 0.6;
}
.page-header.-myab .page-header__link {
  color: var(--color-general);
}

span.page-header__link {
  margin-right: 0;
}

.page-header__logo {
  position: absolute;
  top: 0.7rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.page-header.-myab .page-header__logo {
  background: var(--color-purple);
  border-radius: 50%;
  padding: 0.5rem;
  box-shadow: inset 0 0 0 0.7rem var(--color-purple), inset 0 0 0 1.4rem var(--color-general);
}

@media (--screen-large) {
  .page-header__left {
    display: flex;
    align-items: center;
  }
}

.page-header__liveurope {
  display: none;
  opacity: 0.6;
  margin-left: 1.5rem;
}
@media (--screen-large) {
  .page-header__liveurope {
    display: inline-block;
  }
}
.page-header__liveurope img {
  inline-size: revert-layer;
  block-size: revert-layer;
}

.page-nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 0 2rem;
}
@media (--screen-full) {
  .page-nav {
    clip-path: circle(0% at 0 0);
    transition: clip-path 1s ease, z-index 4s;
  }
}
.page-nav .site-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page-nav.-active {
  background-color: var(--color-purple);
  overflow-y: auto;
  z-index: 9;
}
@media (--screen-full) {
  .page-nav.-active {
    clip-path: circle(200% at 0% 0%);
    transition: clip-path 1s ease;
  }
}
.page-nav::before, .page-nav::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-purple);
  z-index: -2;
}

.page-nav__header {
  padding-bottom: 1rem;
  display: flex;
  height: 7rem;
  align-items: center;
}
@media (--screen-large) {
  .page-nav__header {
    padding-bottom: 5rem;
  }
}

.page-nav__header, .page-nav__footer {
  flex-shrink: 0;
}

.page-nav__center {
  flex: 1 0 auto;
  padding-bottom: 6rem;
}
@media (--screen-large) {
  .page-nav__center {
    display: flex;
  }
}

.page-nav__close.icon {
  padding: 1.4rem 0;
  display: inline-block;
  margin: 0 6rem 0 0;
  border: 0;
  font-size: 1.8rem;
  color: var(--color-general);
  font-family: var(--font-general);
  cursor: pointer;
  transition: opacity 0.3s ease;
  outline: none;
  background-color: var(--color-purple);
}
.page-nav__close.icon::before {
  background: var(--color-general) !important;
  margin-right: 1.2rem;
}
.page-nav__close.icon:hover, .page-nav__close.icon:focus {
  opacity: 0.8;
}
.page-nav__close.icon:hover::before, .page-nav__close.icon:focus::before {
  background: var(--color-general) !important;
}

.page-nav__language__container {
  margin: 0 1.6rem 0 auto;
  display: inline-block;
  position: relative;
  width: 6rem;
  height: 3rem;
}

.page-nav__language {
  position: absolute;
  margin: 0.2rem 0.1rem;
  padding: 0;
  list-style: none;
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  text-transform: uppercase;
  background: var(--color-purple);
}
.page-nav__footer .page-nav__language {
  display: none;
  left: 2rem;
  margin: 1rem 0 0 0;
}
@media (--screen-mini-small) {
  .page-nav__footer .page-nav__language {
    display: block;
  }
}
.page-nav__language:hover li, .page-nav__language:focus li {
  display: block;
  margin-bottom: 1rem;
}
.page-nav__language li {
  display: none;
}
.page-nav__language li.-active {
  order: -1;
  display: block;
  pointer-events: none;
}
.page-nav__language li.-active .icon::before {
  display: block;
}
.page-nav__language li.-hidden {
  display: none;
}

.page-nav__language__item {
  color: var(--color-general);
  text-decoration: none;
}
.page-nav__language__item .icon::before {
  background: var(--color-general) !important;
  display: none;
  margin-left: 0.4rem;
  margin-top: -0.1rem;
}
.page-nav__language__item:hover, .page-nav__language__item:focus {
  opacity: 0.6;
}

.page__nav-list {
  margin: 0 0 4rem 0;
  padding: 0;
  list-style: none;
}
@media (--screen-large) {
  .page__nav-list {
    margin: 0 20rem 4rem 0;
  }
}
.page__nav-list li {
  padding: 1.5rem 0;
}
@media (--screen-medium-large) {
  .page__nav-list li {
    padding: 2rem 0;
  }
}
.page__nav-list.-sub {
  margin: 1rem 0 3rem;
}
@media (--screen-full) {
  .page__nav-list.-sub {
    margin: 3rem 0;
  }
}

.page__nav-item {
  font-family: var(--font-general);
  font-size: 3.7rem;
  font-weight: var(--font-weight-regular);
  color: var(--color-general);
  text-decoration: none;
  transition: opacity 0.3s ease, color 0.3s ease;
  line-height: 1.2;
}
@media (--screen-full) {
  .page__nav-item {
    font-size: 7rem;
  }
}
.page__nav-item:hover, .page__nav-item:focus {
  opacity: 0.6;
}
.page__nav-list.-sub .page__nav-item {
  color: var(--color-submenu);
  font-size: 2.3rem;
}
@media (--screen-full) {
  .page__nav-list.-sub .page__nav-item {
    font-size: 3.2rem;
  }
}
.page__nav-list.-sub .page__nav-item:hover, .page__nav-list.-sub .page__nav-item:focus {
  opacity: 1;
  color: var(--color-white);
}

.page-nav__footer {
  text-align: right;
  align-self: flex-end;
  width: 100%;
}
.page-nav__footer .liveurope {
  opacity: 0.6;
}
.page-nav__footer .liveurope:hover, .page-nav__footer .liveurope:focus {
  opacity: 1;
}
@media (--screen-medium-large) {
  .page-nav__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }
}

.page-nav__socials {
  border: dashed var(--color-border);
  padding: 2.5rem 0;
  margin-bottom: 3.5rem;
  border-width: 0.1rem 0;
  text-align: center;
}
@media (--screen-medium-large) {
  .page-nav__socials {
    border: 0;
    padding: 0;
    margin: 0;
    text-align: left;
  }
}

.page-nav__social-item {
  transition: opacity 0.3s ease;
  opacity: 0.6;
  margin: 0 3rem 0 0;
}
.page-nav__social-item::before {
  background: var(--color-general) !important;
}
.page-nav__social-item:hover, .page-nav__social-item:focus {
  opacity: 1;
}
.page-nav__social-item:hover::before, .page-nav__social-item:focus::before {
  background: var(--color-general) !important;
}
.page-nav__social-item:last-child {
  margin-right: 0;
}

.page-nav__search {
  padding: 1rem 0;
  position: relative;
  top: 0.1rem;
}

.page-search {
  background-color: var(--color-purple);
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  box-sizing: border-box;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  transform: translateY(-40rem);
}
.page-search::after {
  z-index: -1;
  content: "";
  background-color: var(--color-purple);
  width: 100%;
  height: 200vh;
  opacity: 0.3;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.page-search.-active, .page-search.-inline {
  height: auto;
  transform: translateY(0);
  max-height: 40rem;
  overflow: visible;
}
.page-search.-inline {
  position: relative;
  z-index: 2;
  background: none;
}
.page-search.-inline:after {
  display: none;
}
.page-search .site-container {
  position: relative;
}

.page-search__container, .page-search__container.site-container {
  max-width: 52rem;
  margin: 0 auto;
  padding: 10rem 0;
}
.page-search.-inline .page-search__container, .page-search.-inline .page-search__container.site-container {
  margin: 0 auto;
  padding: 0 0 10rem;
}

.page-search__title {
  display: block;
  font-size: 2.1rem;
  font-family: var(--font-general);
  color: var(--color-general);
  text-align: center;
  margin-bottom: 3rem;
}
@media (--screen-large) {
  .page-search__title {
    font-size: 3.5rem;
  }
}
.page-search.-inline .page-search__title {
  display: none;
}

.page-search__button {
  width: 5rem;
  height: 5rem;
  border: 0.1rem solid var(--color-general);
  border-radius: 50%;
  background: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}
.page-search__button::before {
  background-color: var(--color-general) !important;
}
.page-search__button:hover, .page-search__button:focus {
  background-color: var(--color-general);
}
.page-search__button:hover::before, .page-search__button:focus::before {
  background: var(--color-purple) !important;
}
.page-search.-inline .page-search__button:hover::before, .page-search.-inline .page-search__button:focus::before {
  background-color: var(--color-general) !important;
}
.page-search.-inline .page-search__button {
  background-color: var(--color-purple);
}
.page-search.-inline .page-search__button:hover, .page-search.-inline .page-search__button:focus {
  opacity: 0.6;
}

.page-search__close {
  display: block;
  border: 0;
  padding: 0;
  background: none;
  font-size: 1.8rem;
  color: var(--color-general);
  font-family: var(--font-general);
  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: 0.6;
  position: absolute;
  top: 3rem;
  right: var(--side-padding);
  outline: none;
  height: 2.2rem;
}
.page-search__close::before {
  background: var(--color-general) !important;
  margin-right: 1.2rem;
}
.page-search__close:hover, .page-search__close:focus {
  opacity: 1;
}
.page-search__close:hover::before, .page-search__close:focus::before {
  background: var(--color-general) !important;
}
.main .page-search__close {
  display: none;
}

.page-search__autocomplete {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0;
  background: var(--color-purple);
  transition: all 0.3s ease;
}
.page-search__autocomplete ul {
  display: block;
  max-width: 52rem;
  margin: 2rem auto !important;
  padding: 0 var(--side-padding);
  box-sizing: border-box;
}
.page-search__autocomplete ul li {
  margin: 0;
  padding: 0.5rem 0;
  text-align: left;
  list-style: none;
}
.page-search__autocomplete ul li::before {
  display: none;
}
.page-search__autocomplete a {
  font-size: 1.8rem;
  color: var(--color-general);
  font-family: var(--font-general);
  text-decoration: none;
  transition: all 0.3s ease;
}
.page-search__autocomplete a:hover, .page-search__autocomplete a:focus {
  opacity: 0.6;
}
.page-search.-inline .page-search__autocomplete {
  border: 0.1rem dotted var(--color-border-dark);
  background: var(--color-white);
}
.page-search.-inline .page-search__autocomplete a {
  color: var(--color-purple);
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;
}
.content-cards__container ~ .site-container .pagination .pagination {
  margin-top: -3rem;
}
@media (--screen-large) {
  .content-cards__container ~ .site-container .pagination .pagination {
    margin-top: 0;
  }
}
@media (--screen-full) {
  .content-cards__container ~ .site-container .pagination .pagination {
    margin-top: 4rem;
  }
}

.pagination__container {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: var(--color-white);
  border-radius: 2.5rem;
}
.pagination__container.-desktop {
  display: none;
}
@media (--screen-large) {
  .pagination__container.-desktop {
    display: inline-flex;
  }
}
@media (--screen-large) {
  .pagination__container.-mobile {
    display: none;
  }
}

.pagination__item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 5rem;
  margin: 0 0.5rem;
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 1.5rem;
  line-height: 1;
}
.pagination__item a {
  color: var(--color-purple);
  text-decoration: none;
  cursor: pointer;
}
.pagination__item a:hover {
  text-decoration: underline;
}
.pagination__item:not(.-spacer).-current, .pagination__item:not(.-spacer):hover {
  color: var(--color-purple);
}
.pagination__item:not(.-spacer).-current a, .pagination__item:not(.-spacer):hover a {
  color: var(--color-purple);
}
.pagination__item:not(.-spacer).-current a {
  text-decoration: underline;
}
.pagination__item.-prev, .pagination__item.-next {
  padding: 0;
  margin: 0 2rem;
  position: relative;
  z-index: 1;
  font: 0/0 sans-serif;
  transition: opacity 0.3s ease;
}
.pagination__item.-prev::before, .pagination__item.-next::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 5rem;
  height: 5rem;
  background-color: var(--color-purple);
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  content: "";
}
.pagination__item.-prev a, .pagination__item.-next a {
  position: absolute;
  top: 0;
  left: -1.4rem;
  z-index: 1;
  display: block;
  width: 5rem;
  height: 5rem;
  color: var(--color-general);
}
.pagination__item.-prev::after, .pagination__item.-next::after {
  color: var(--color-general);
  content: "";
  transition: background 0.3s ease;
  display: inline-block;
  width: 2.3rem;
  height: 2.3rem;
  background: url(arrow.svg) center center no-repeat;
  background-size: 2.3rem 2.3rem;
  pointer-events: none;
}
@supports (mask-repeat: no-repeat) {
  .pagination__item.-prev::after, .pagination__item.-next::after {
    background: currentColor;
    mask-image: url(arrow.svg);
    mask-repeat: no-repeat;
    mask-size: 2.3rem 2.3rem;
  }
}
.pagination__item.-prev:hover, .pagination__item.-prev:focus, .pagination__item.-next:hover, .pagination__item.-next:focus {
  opacity: 0.6;
}
.pagination__item.-disabled {
  opacity: 0.2 !important;
  pointer-events: none;
}
.pagination__item.-prev::after {
  transform: rotate(180deg);
}

p {
  margin: 1rem 0 3.2rem;
}

b, strong {
  font-weight: var(--font-weight-bold);
}

.partners-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.partners-list li {
  text-align: center;
  padding: 1rem 0;
  flex-basis: 10rem;
}
@media (--screen-medium-large) {
  .partners-list li {
    flex-basis: 14rem;
  }
}
.partners-list img {
  inline-size: revert-layer;
  block-size: revert-layer;
}

.partners-item {
  box-sizing: border-box;
  padding: 1rem;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
.text .partners-item {
  opacity: 1;
}
@media (--screen-medium-large) {
  .partners-item {
    padding: 1.8rem;
  }
}
@media (--screen-large) {
  .partners-item {
    padding: 1.8rem 2.8rem;
  }
}
.partners-item:hover, .partners-item:focus {
  opacity: 1;
}
.text .partners-item img {
  max-width: 10rem !important;
}

.playlist {
  margin: 0 0 3rem;
}
.page-detail__content__info .playlist {
  max-width: 75rem;
  margin: 0 auto 3rem auto;
}

.poll {
  margin: 0 -2rem 4rem;
  background: var(--color-well-informed);
  padding: 5rem 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: var(--color-well-informed);
  background-image: url("poll.svg");
}
@media (--screen-full) {
  .poll {
    background-size: 125%;
  }
}

.poll__content {
  width: 100%;
  max-width: 86rem;
  margin: 0 auto;
  padding: 0 4rem;
  box-sizing: border-box;
  text-align: center;
}
.poll__content .alert + .button {
  margin-top: 5rem;
}

@media (--screen-large) {
  .poll__answers {
    display: flex;
    justify-content: center;
  }
}
.poll__answers .form__entry {
  margin: 1.5rem;
}
@media (--screen-large) {
  .poll__answers .form__entry {
    flex-basis: 33%;
  }
}
.poll__answers + .button {
  margin-top: 5rem;
}

.poll__tiebreaker {
  padding: 5rem 0;
}
.poll__tiebreaker .form__field {
  border-bottom-style: dashed;
  text-align: center;
  display: block;
  padding: 0.5rem 0.1rem;
  width: 6rem;
  margin: 0 auto;
}
@media (--screen-large) {
  .poll__tiebreaker .form__field {
    display: inline-block;
    margin-left: 1rem;
  }
}
.poll__tiebreaker + .alert {
  margin-top: 0;
}
.poll__tiebreaker input:disabled,
.poll__tiebreaker input[disabled] {
  opacity: 0.4;
  pointer-events: none;
}

.poll__title {
  font-size: 3.5rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
}

.poll__anwser {
  display: inline-block;
  background: var(--color-white-hover);
  border: 0;
  color: var(--color-purple);
  font-size: 1.8rem;
  line-height: 1.5;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  border-radius: 30rem;
  padding: 1.5rem 2rem;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: none;
}
.poll__anwser:disabled, .poll__anwser[disabled] {
  pointer-events: none;
}
.poll__anwser:hover, .poll__anwser:focus {
  background: var(--color-white);
}
.form__radio:checked + .poll__anwser {
  background: var(--color-white);
  color: var(--color-purple);
}

.poll__privacy {
  display: block;
  text-align: center;
  padding-top: 1.5rem;
  opacity: 0.4;
  font-size: 1.2rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
}

.quote {
  margin-block: 3rem !important;
}

.quote__quote {
  margin: 0;
  font-size: 2.5rem;
  font-weight: var(--font-weight-semi-bold);
  line-height: 1.44;
  text-align: center;
  letter-spacing: 0.04rem;
}
.quote__quote::before {
  content: "“";
}
.quote__quote::after {
  content: "”";
}

.quote__author {
  margin-block-start: 2rem;
  font-size: 2.1rem;
  line-height: 1.2;
  text-align: center;
  color: var(--color-title);
}

.read-more__target {
  display: block;
  max-height: 0;
  overflow: hidden;
}
.read-more__target.-active {
  max-height: none;
}

.read-more__trigger.-active {
  display: none;
}
.read-more__target .read-more__trigger {
  margin-top: 4rem;
}
.read-more__target .read-more__trigger:first-child {
  margin-top: 0;
}

.row {
  margin: 0;
  padding-inline: 2rem;
}
.row.-size-full {
  padding: 0;
}
.row[data-margin=none] {
  margin: 0;
}
.row[data-margin=block] {
  margin-block: 4rem;
}
.row[data-margin=top] {
  margin-block: 4rem 0;
}
.row[data-margin=bottom] {
  margin-block: 0 4rem;
}
.row[data-size=small] {
  max-inline-size: 75rem;
  margin-inline: auto;
}
.row[data-block-type=child-pages] {
  background-color: var(--color-infocard);
}

.row__sizer {
  max-inline-size: 100%;
  margin-inline: auto;
}

.row__inner {
  inline-size: 75rem;
  max-inline-size: 100%;
  margin-inline: auto;
}
.pagecontent-row-container.-align-left .row__inner, .row.-align-left .row__inner {
  margin-inline-start: 0;
}
.row.-size-medium .row__inner {
  inline-size: 75rem;
}
.row.-size-wide .row__inner {
  inline-size: 128rem;
}
.row.-size-full .row__inner {
  inline-size: 100%;
}

.search__title {
  color: var(--color-purple);
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  display: block;
  text-align: center;
  font-size: 3.4rem;
  line-height: 1.25;
  margin: 0 0 3rem;
}
@media (--screen-large) {
  .search__title {
    font-size: 4.3rem;
  }
}

.site-container {
  display: block;
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 var(--side-padding);
  box-sizing: border-box;
}
.site-container.-content-cards {
  padding: 0;
}
@media (--screen-large) {
  .site-container.-content-cards {
    padding: 0 var(--side-padding);
  }
}
.site-container.-search {
  padding-top: 4rem;
}
@media (--screen-full) {
  .site-container.-bottom {
    padding-bottom: 10rem;
  }
}
.site-container.-team {
  padding: 0;
}

.slide {
  position: relative;
  display: block;
  aspect-ratio: 16/9;
  inline-size: 100%;
  min-block-size: 50rem;
  max-block-size: calc(100vh - 8rem);
  list-style: none;
}

.splide__track {
  flex: 1;
}

.slide__image {
  display: block;
  inline-size: 100%;
  block-size: 100%;
  max-height: 100% !important;
  object-fit: cover;
  object-position: top;
}

@media (--screen-from-md) {
  [data-gjs-type=wrapper] .slide {
    max-inline-size: 50%;
  }
}
.row[data-block-type=slider]:not([data-amount="3"]) {
  background-color: var(--color-general);
  padding-block: 7.5rem;
}
.row[data-block-type=slider]:not([data-amount="3"]) .splide__pagination {
  display: flex;
  margin-block-start: 4rem;
}
.row[data-block-type=slider]:not([data-amount="3"]) .slider {
  max-inline-size: 100rem;
  margin-inline: auto;
}
@media (--screen-from-xl) {
  .row[data-block-type=slider]:not([data-amount="3"]) .splide__arrow--prev {
    inset-inline-start: -10rem;
  }
  .row[data-block-type=slider]:not([data-amount="3"]) .splide__arrow--next {
    inset-inline-end: -10rem;
  }
}

.row[data-block-type=slider] .slide__image {
  object-fit: contain;
  object-position: center;
}

.slider__title {
  font-weight: var(--font-weight-medium);
  max-inline-size: 128rem;
  margin-inline: auto;
  padding-inline: var(--site-padding);
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s infinite linear;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__play {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  background: #ccc;
  border: 0;
  cursor: pointer;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0.7;
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 2em;
  width: 2em;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: #0bf 3px solid;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: #0bf 3px solid;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 8px;
  opacity: 0.7;
}

.splide__pagination__page.is-active {
  background: #fff;
  z-index: 1;
  transform: scale(1.4);
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: #0bf 3px solid;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: #0bf 3px solid;
  outline-offset: 3px;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: transparent;
}

.splide__slide:focus {
  outline: 0;
}

.splide__slide:focus-visible {
  /* IE does not support outline-offset */
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: #0bf 3px solid;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: #0bf 3px solid;
  }
}
.splide.is-focus-in .splide__slide:focus {
  /* IE does not support outline-offset */
}

@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: #0bf 3px solid;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: #0bf 3px solid;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}
.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: #0bf 3px solid;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: #0bf 3px solid;
  outline-offset: 3px;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%, 0);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotateZ(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotateZ(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

[data-gjs-type=wrapper] .splide {
  visibility: visible;
}

.splide__list {
  block-size: auto;
}
[data-gjs-type=wrapper] .splide__list {
  flex-wrap: wrap;
}

[data-gjs-type=wrapper] .splide__arrows {
  display: none;
}

.splide__arrow {
  inline-size: 4.8rem;
  block-size: 4.8rem;
  border-radius: 50%;
  font-size: 0;
  opacity: 1;
  background-color: var(--color-general);
  transition: background-color 0.2s ease;
}
.splide:has(.slider__title) .splide__arrow {
  transform: translateY(2rem);
}
.splide__arrow::before {
  content: "";
  display: inline-block;
  inline-size: 4.8rem;
  block-size: 4.8rem;
  background-color: var(--color-purple);
  mask-image: url("../icon/arrow-right.svg");
  mask-size: 2.1rem 2.1rem;
  mask-repeat: no-repeat;
  mask-position: center;
}
.splide__arrow:--focus {
  background-color: var(--color-general-hover);
}

.splide__arrow--prev {
  inset-inline-start: 1rem;
}
.splide__arrow--prev::before {
  transform: rotate(180deg);
}

.splide__arrow--next {
  inset-inline-end: 1rem;
}

@media (--screen-from-md) {
  .splide__arrow--prev {
    inset-inline-start: 2rem;
  }
  .splide__arrow--next {
    inset-inline-end: 2rem;
  }
}
.sticky-bar {
  background: var(--color-sticky-purple);
  padding: 2rem;
  display: block;
  text-align: center;
  position: relative;
}
.sticky-bar.-type-1 {
  background: var(--color-sticky-green);
}
@media (--screen-large) {
  .sticky-bar {
    text-align: left;
  }
  .sticky-bar .site-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  .sticky-bar p {
    display: inline-block;
    margin: 0 2rem 1rem 0;
  }
  .sticky-bar .sticky-bar__content {
    margin: 0 4rem 0 2rem;
  }
}
.sticky-bar img {
  inline-size: 4rem;
  block-size: 4rem;
}

.sticky-bar__close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2rem;
  height: 2rem;
  border: 0;
  font: 0/0 sans-serif;
  cursor: pointer;
  background-color: var(--color-purple);
  background-image: url(cross.svg);
  background-size: 2rem 2rem;
  transition: opacity 0.3s ease;
}
@supports (mask-repeat: no-repeat) {
  .sticky-bar__close {
    background: var(--color-purple);
    mask-image: url(cross.svg);
    mask-repeat: no-repeat;
    mask-size: 2rem 2rem;
  }
}
.sticky-bar__close:hover, .sticky-bar__close:focus {
  opacity: 0.6;
}
@media (--screen-large) {
  .sticky-bar__close {
    top: 1.5rem;
    right: 0;
  }
}

figure.table {
  margin: 2rem 0;
  overflow: auto;
  border: 0.1rem solid var(--color-general);
}
.event-detail__content.-family figure.table {
  border-color: var(--color-family);
}
.event-detail__content.-fun figure.table {
  border-color: var(--color-fun);
}
.event-detail__content.-rebellious figure.table {
  border-color: var(--color-rebellious);
}
.event-detail__content.-well-informed figure.table {
  border-color: var(--color-well-informed);
}
figure.table table {
  border: 0;
}
.-teampage figure.table {
  border: 0;
  margin: 2rem 0 3.8rem;
}

table:not([class]) {
  min-width: 100%;
  border: 0.1rem solid var(--color-general);
  border-collapse: collapse;
}
.event-detail__content.-family table:not([class]) {
  border-color: var(--color-family);
}
.event-detail__content.-fun table:not([class]) {
  border-color: var(--color-fun);
}
.event-detail__content.-rebellious table:not([class]) {
  border-color: var(--color-rebellious);
}
.event-detail__content.-well-informed table:not([class]) {
  border-color: var(--color-well-informed);
}
table:not([class]) th, table:not([class]) td {
  color: var(--color-purple);
  font-family: var(--font-general);
  font-size: 1.8rem;
  font-weight: var(--font-weight-regular);
  text-align: left;
  vertical-align: top;
  padding: 1.8rem;
  border: solid var(--color-general);
  border-width: 0.1rem 0 0 0.1rem;
  box-sizing: border-box;
  max-width: 28rem;
}
@media (--screen-mini-small) {
  table:not([class]) th, table:not([class]) td {
    font-size: 1.6rem;
    padding: 0.8rem;
    width: 50%;
  }
}
@media (--screen-mini-medium) {
  table:not([class]) th, table:not([class]) td {
    min-width: 13rem;
  }
}
.event-detail__content.-family table:not([class]) th, .event-detail__content.-family table:not([class]) td {
  border-color: var(--color-family);
}
.event-detail__content.-fun table:not([class]) th, .event-detail__content.-fun table:not([class]) td {
  border-color: var(--color-fun);
}
.event-detail__content.-rebellious table:not([class]) th, .event-detail__content.-rebellious table:not([class]) td {
  border-color: var(--color-rebellious);
}
.event-detail__content.-well-informed table:not([class]) th, .event-detail__content.-well-informed table:not([class]) td {
  border-color: var(--color-well-informed);
}
.-teampage table:not([class]) th, .-teampage table:not([class]) td {
  border: 0;
  padding-top: 0;
  padding-left: 0;
}
@media (--screen-mini-small) {
  .-teampage table:not([class]) th, .-teampage table:not([class]) td {
    padding: 0;
    width: 100%;
    display: block;
  }
}
table:not([class]) th {
  background: var(--color-infocard);
  border-width: 0 0 0.1rem 0.1rem;
  font-weight: var(--font-weight-medium);
}
.event-detail__content.-family table:not([class]) th {
  background: var(--color-family-bg);
}
.event-detail__content.-fun table:not([class]) th {
  background: var(--color-fun-bg);
}
.event-detail__content.-rebellious table:not([class]) th {
  background: var(--color-rebellious-bg);
}
.event-detail__content.-well-informed table:not([class]) th {
  background: var(--color-well-informed-bg);
}
table:not([class]) tr td:first-child, table:not([class]) tr th:first-child {
  border-left: 0;
}
table:not([class]) tr:first-child td, table:not([class]) tr:first-child th {
  border-top: 0;
}
.-teampage table:not([class]) {
  border: 0;
}

.tags-list {
  margin: 6rem 0 2.5rem;
  padding: 0;
  list-style: none;
}
.tags-list li {
  display: inline-block;
  margin: 0 1.5rem 1.5rem 0;
}
.tags-list.-top {
  margin-top: 0;
}

.tag {
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular) !important;
  font-size: 1.4rem;
  color: var(--color-purple) !important;
  text-decoration: none !important;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  padding: 0.8rem 0.9rem 0.8rem 1.2rem;
  border: 0.1rem solid var(--color-general);
  transition: background-color 0.3s ease;
}
.event-detail__content.-family .tag {
  border-color: var(--color-family);
}
.event-detail__content.-fun .tag {
  border-color: var(--color-fun);
}
.event-detail__content.-rebellious .tag {
  border-color: var(--color-rebellious);
}
.event-detail__content.-well-informed .tag {
  border-color: var(--color-well-informed);
}
.tag:hover, .tag:focus {
  background-color: var(--color-general);
}
.event-detail__content.-family .tag:hover, .event-detail__content.-family .tag:focus {
  background-color: var(--color-family);
}
.event-detail__content.-fun .tag:hover, .event-detail__content.-fun .tag:focus {
  background-color: var(--color-fun);
}
.event-detail__content.-rebellious .tag:hover, .event-detail__content.-rebellious .tag:focus {
  background-color: var(--color-rebellious);
}
.event-detail__content.-well-informed .tag:hover, .event-detail__content.-well-informed .tag:focus {
  background-color: var(--color-well-informed);
}

.page-detail__content .team {
  margin: 0 auto 4rem auto;
}

.team__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3rem;
  gap: 3rem;
  row-gap: 3rem;
  list-style-type: none;
  width: 100%;
  padding: 3rem 0;
  border-bottom: 0.1rem dotted var(--color-border-dark);
}
@media (--screen-large) {
  .team__list {
    border-bottom: 0;
    border-top: 0.1rem dotted var(--color-border-dark);
  }
}

.team__list__item {
  flex-basis: 40rem;
}
@media (--screen-large) {
  .team__list__item {
    flex-basis: calc(50% - 1.5rem);
  }
}
@media (--screen-jumbotron) {
  .team__list__item {
    flex-basis: calc(33.3333333333% - 2rem);
  }
}

.team__member {
  display: block;
  text-decoration: none;
  color: var(--color-purple);
  font-weight: var(--font-weight-regular);
  font-size: 2.8rem;
  font-family: var(--font-general);
  line-height: 1.15;
  box-sizing: border-box;
  padding: 1.6rem;
  width: 100%;
  max-width: 40rem;
  transition: all 0.3s ease;
}
.team__member img {
  margin: 0 -1.6rem 1.6rem;
  max-width: calc(100% + 3.2rem);
  transition: all 0.3s ease;
  min-height: 18rem;
  height: auto;
}

.team__member__email {
  display: inline-block;
  min-height: auto !important;
  margin: 0 0 0 0.5rem !important;
  position: relative;
  top: 0.3rem;
  width: 2rem;
  height: 2rem;
  background-size: 2rem 2rem;
  background-image: url(../icon/email.svg);
  transition: all 0.3s ease;
}
@supports (mask-repeat: no-repeat) {
  .team__member__email {
    background: var(--color-purple);
    mask-image: url(../icon/email.svg);
    mask-size: 2rem 2rem;
  }
}

.team__member__function {
  color: var(--color-purple);
  font-weight: var(--font-weight-regular);
  font-size: 1.8rem;
  font-family: var(--font-general);
  display: block;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.team__member__name {
  display: block;
  margin-bottom: 2rem;
}

.team__category {
  display: block;
  margin: 0 0 1.6rem;
  font-size: 3.5rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  color: var(--color-purple);
  line-height: 1.15;
  text-decoration: none;
}
@media (--screen-large) {
  .team__category {
    font-size: 4.3rem;
  }
}

.team__category__trigger {
  display: none;
}

.team__category__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  margin-bottom: 1rem;
}
.team__category__trigger:checked ~ .team__category__content {
  max-height: none;
}
@media (--screen-large) {
  .team__category__content {
    max-height: none;
  }
}

.team__category__question {
  display: block;
  cursor: pointer;
  transition: opacity 0.3s ease;
  padding-left: 5rem;
  position: relative;
}
.team__category__question:hover, .team__category__question:focus {
  opacity: 0.6;
}
@media (--screen-large) {
  .team__category__question:hover, .team__category__question:focus {
    opacity: 1;
  }
}
@media (--screen-large) {
  .team__category__question {
    padding-left: 0;
    cursor: default;
  }
}

.team__category__button {
  background: transparent;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 0.4rem;
  left: 0;
  outline: none;
  transition: all 0.3s ease;
  text-align: center;
  font: 0/4rem sans-serif;
}
.team__category__trigger:checked + .team__category .team__category__question .team__category__button {
  transform: rotate(180deg);
}
@media (--screen-large) {
  .team__category__button {
    display: none;
  }
}

.text {
  margin: 0 0 4rem;
}
.text img {
  max-width: 100%;
  max-height: 75rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.text.-border {
  padding: 4rem 0;
  border-top: 0.1rem dotted var(--color-border-dark);
  border-bottom: 0.1rem dotted var(--color-border-dark);
  margin: 0;
}
.text.-border + .text, .artist__list + .text.-border {
  border-top: 0;
}
@media (--screen-large) {
  .text.-left, .text.-right {
    display: flex;
  }
}
.page-detail__content__info .text {
  margin: 0 auto 4rem auto;
}
.page-detail__content__info .text.-button {
  margin-top: 4rem;
}
.page-detail__content__info.-full .text {
  max-width: 128rem;
}

.text__content {
  flex-basis: 50%;
  margin-bottom: 3rem;
}

.text__img {
  box-sizing: border-box;
  padding: 0;
}
@media (--screen-large) {
  .text__img {
    flex-basis: 50%;
  }
}
.text__img img {
  max-width: 100%;
  max-height: 75rem;
}
.text.-left .text__img {
  order: -1;
}
@media (--screen-large) {
  .text.-left .text__img {
    padding: 0 3rem 0 0;
  }
}
@media (--screen-large) {
  .text.-right .text__img {
    padding: 0 0 0 3rem;
  }
}

.raw-html-embed iframe, .text iframe {
  width: 100%;
}

.text-over-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  block-size: 72rem;
  margin-block: 1rem;
  padding: 2rem;
}
[data-alignment=left] .text-over-image {
  justify-content: left;
}
[data-alignment=right] .text-over-image {
  justify-content: right;
}

.text-over-image__image {
  position: absolute;
  inset: 0;
}
.text-over-image__image img {
  display: block;
  inline-size: 100%;
  block-size: 100%;
  object-fit: cover;
}

.text-over-image__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-inline-size: 90%;
}
[data-alignment=left] .text-over-image__content {
  align-items: flex-start;
}
[data-alignment=right] .text-over-image__content {
  align-items: flex-end;
}

.text-over-image__subtitle {
  margin-block-end: 2.6rem;
  font-size: 1.8rem;
  font-weight: var(--font-weight-normal);
  line-height: 1.55;
  text-align: center;
}
[data-alignment=left] .text-over-image__subtitle {
  text-align: start;
}
[data-alignment=right] .text-over-image__subtitle {
  text-align: end;
}

.text-over-image__title {
  margin: 0;
  font-size: 4.6rem;
  line-height: 1.15;
  text-align: center;
}
[data-alignment=left] .text-over-image__title {
  text-align: start;
}
[data-alignment=right] .text-over-image__title {
  text-align: end;
}

.text-over-image__description {
  margin-block-start: 2.6rem;
  text-align: center;
}
[data-alignment=left] .text-over-image__description {
  text-align: start;
}
[data-alignment=right] .text-over-image__description {
  text-align: end;
}

.text-over-image__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  max-inline-size: 100%;
  margin-block-start: 2.6rem;
}
[data-alignment=left] .text-over-image__buttons {
  justify-content: left;
}
[data-alignment=right] .text-over-image__buttons {
  justify-content: right;
}

@media (--screen-from-sm) {
  .text-over-image {
    padding: 6rem;
  }
}
@media (--screen-from-md) {
  .text-over-image {
    padding: 7rem 14rem;
  }
}
.page__title {
  margin-top: 6rem;
}
@media (--screen-medium-large) {
  .page__title {
    margin-top: 10rem;
  }
}
.page__title.-center {
  text-align: center;
}

h1 {
  margin: 0 0 3rem;
  font-size: 4.2rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-medium);
  color: var(--color-purple);
  line-height: 1;
}
@media (--screen-large) {
  h1 {
    font-size: 6.8rem;
  }
}

h2 {
  margin: 0 0 3rem;
  font-size: 3.5rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-medium);
  color: var(--color-purple);
  line-height: 1.2;
}
@media (--screen-large) {
  h2 {
    font-size: 4.3rem;
  }
}

h3 {
  margin: 0 0 3rem;
  font-size: 2.9rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-medium);
  color: var(--color-purple);
  line-height: 1.2;
}
@media (--screen-large) {
  h3 {
    font-size: 3.5rem;
  }
}

h4 {
  margin: 0 0 3rem;
  font-size: 2.1rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-medium);
  color: var(--color-purple);
  line-height: 1.2;
}
@media (--screen-large) {
  h4 {
    font-size: 2.8rem;
  }
}
h4.-top {
  margin-top: 6rem;
}

h5 {
  margin: 0 0 1.5rem;
  font-size: 1.4rem;
  font-family: var(--font-general);
  font-weight: var(--font-weight-medium);
  color: var(--color-purple);
  text-transform: uppercase;
  letter-spacing: 0.3rem;
}
@media (--screen-large) {
  h5 {
    font-size: 1.8rem;
  }
}

.video__container {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 3rem;
}
@media (--screen-mini-small) {
  .video__container {
    width: calc(100% + 4rem);
  }
}
.page-detail__content__info .video__container, .event-detail__content .video__container {
  max-width: 75rem;
  margin: 0 auto 3rem;
}
@media (--screen-mini-small) {
  .page-detail__content__info .video__container, .event-detail__content .video__container {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
@media (--screen-large) {
  .page-detail__content__info .video__container, .event-detail__content .video__container {
    padding-top: 42.1875rem;
  }
}
.video__container button {
  outline: none;
}

.video, .video__cover {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  z-index: 0;
  padding: 0;
}

.video__cover {
  z-index: 1;
  text-align: center;
  cursor: pointer;
}
.video__cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video__cover .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  border: 0.7rem solid var(--color-general);
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  margin: -4.7rem 0 0 -4.7rem;
  line-height: 8rem;
  transition: opacity 0.3s ease;
  text-indent: 1rem;
}
@media (--screen-full) {
  .video__cover .icon {
    border: 1rem solid var(--color-general);
    width: 12rem;
    height: 12rem;
    margin: -7rem 0 0 -7rem;
    line-height: 12rem;
    text-indent: 1.5rem;
  }
}
.event-detail__content.-family .video__cover .icon {
  border-color: var(--color-family);
}
.event-detail__content.-fun .video__cover .icon {
  border-color: var(--color-fun);
}
.event-detail__content.-rebellious .video__cover .icon {
  border-color: var(--color-rebellious);
}
.event-detail__content.-well-informed .video__cover .icon {
  border-color: var(--color-well-informed);
}
.video__cover:hover .icon, .video__cover:focus .icon {
  opacity: 0.6;
}
.video__container.-playing .video__cover {
  display: none;
}

.video {
  display: none;
}
.video__container.-playing .video {
  display: block;
}

.fb-video {
  margin-bottom: 3rem;
}
.page-detail__content__info .fb-video, .event-detail__content .fb-video {
  max-width: 75rem;
  margin: 0 auto 3rem;
}
@media (--screen-mini-small) {
  .page-detail__content__info .fb-video, .event-detail__content .fb-video {
    display: inline-block;
    width: calc(100% + 4rem) !important;
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
.fb-video span, .fb-video iframe {
  width: 100% !important;
}

.video_pagebuilder {
  position: relative;
  inline-size: 100%;
  block-size: 0;
  margin-block: 1rem 2rem;
  padding-block-end: 56.25%;
}

.video_pagebuilder__frame {
  position: absolute;
  inset: 0;
  inline-size: 100%;
  block-size: 100%;
  background-color: var(--color-general);
  background-image: url("../../images/video-placeholder.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 5.6rem;
}

@media (--screen-from-md) {
  .video_pagebuilder {
    margin-block: 2rem 4rem;
  }
}
@media (--screen-from-lg) {
  .video_pagebuilder {
    margin-block: 3rem 6rem;
  }
}
.wrapper.-purple {
  background-color: var(--color-purple);
}
.wrapper.-general {
  background-color: var(--color-general);
}
.wrapper.-info {
  background-color: var(--color-infocard);
}
.text + .wrapper {
  margin: 0 0 3rem;
}
.page-detail__content__info .wrapper {
  margin: 0 calc(var(--side-padding) * -1);
}
.page-detail__content__info .wrapper + .text {
  margin-top: 3rem;
}
.page-detail__content__info .text + .wrapper {
  margin: 0 calc(var(--side-padding) * -1) 3rem;
}

.agenda {
  display: block;
  margin: 0 auto;
  padding: 4rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: var(--color-general);
}
.agenda .carrousel {
  background-color: var(--color-general);
}

.agenda__masthead {
  padding: 0;
  height: 8%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.agenda__masthead p {
  margin: 0;
}
.agenda__masthead h1 {
  margin: 0;
  font-family: var(--font-rebellious);
  font-size: 7rem;
  letter-spacing: -0.4rem;
  color: var(--color-purple);
  position: relative;
  display: inline-block;
}

.agenda__carrousel {
  height: 90%;
}

.agenda__header {
  text-align: right;
  text-transform: uppercase;
  color: var(--color-purple);
  font-weight: var(--font-weight-regular);
  font-family: var(--font-general);
  font-size: calc(8px + 0.7vw);
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 0.56rem;
}
@media screen and (min-width: 768px) {
  .agenda__header {
    padding-right: 1.44rem;
  }
}

.agenda-item {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 10%;
  overflow: hidden;
  border-bottom: 0.1rem dotted var(--color-border-dark);
}
.agenda-item:last-child {
  border: 0;
}

.agenda-item__content {
  float: left;
  width: 75%;
  height: 100%;
  padding: 0.56rem;
  box-sizing: border-box;
  font-size: 0;
  line-height: 1.25;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .agenda-item__content {
    padding: 0.72rem 1.44rem;
  }
}

.agenda-item__date {
  font-family: var(--font-general);
  font-size: calc(14px + 1vw);
  font-weight: var(--font-weight-regular);
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 0.39rem 0;
}

.agenda-item__title {
  margin: 0;
  display: inline;
  font-family: var(--font-general);
  font-size: calc(21px + 1.5vw);
  line-height: 1;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .agenda-item__title {
    line-height: 1.15;
  }
}

.agenda-item__timing {
  margin: 0;
  display: inline;
  font-family: var(--font-general);
  font-size: calc(14px + 1vw);
  line-height: 1;
  font-weight: var(--font-weight-regular);
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .agenda-item__timing {
    line-height: 1.15;
  }
}

.agenda-item__tickets {
  float: left;
  width: 25%;
  height: 100%;
  padding: 0.56rem;
  text-align: right;
  box-sizing: border-box;
  font-size: calc(8px + 0.7vw);
  color: var(--color-purple);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .agenda-item__tickets {
    padding: 0.72rem 1.44rem;
  }
}

.agenda-item__price {
  font-weight: var(--font-weight-medium);
  color: var(--color-purple);
  font-size: calc(11px + 1vw);
  font-family: var(--font-general);
  display: block;
  margin-bottom: 0.4rem;
}
.agenda-item__price svg {
  width: 1.17rem;
  height: 1.17rem;
  line-height: 1;
  vertical-align: middle;
  position: relative;
  top: -0.17rem;
  display: none;
}
.agenda-item__price.-sold_out, .agenda-item__price.-full {
  color: var(--color-black);
}
.agenda-item__price.-sold_out svg, .agenda-item__price.-full svg {
  display: inline-block;
}

.agenda__footer {
  position: absolute;
  bottom: 4rem;
  right: 4rem;
  font-size: 3rem;
  z-index: 10;
  line-height: 1;
}

.agora__carrousel {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-general);
}
.agora__carrousel .carrousel {
  background-color: var(--color-general);
  top: 15%;
  height: 85%;
  padding: 2rem 4rem;
  box-sizing: border-box;
}

.agora-item {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 25%;
  overflow: hidden;
  border-top: 0.5rem dotted var(--color-border-dark);
  padding: 2rem 0;
  position: relative;
}
.agora-item:first-child {
  border: 0;
}
.agora-item.-h-1, .agora-item.-h-2, .agora-item.-h-3 {
  height: 33.3333%;
}
.agora-item.-h-4 {
  height: 25%;
}
.agora-item.-agenda {
  height: 12.5%;
}

.agora-item__img {
  float: left;
  width: 17%;
  height: 100%;
  background-size: cover;
  box-sizing: border-box;
}
.agora-item__img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.agora-item__content {
  position: relative;
  float: left;
  width: calc(83% - 30rem);
  height: 100%;
  padding: 1rem 4rem;
  box-sizing: border-box;
  font-size: calc(18px + 2vw);
  line-height: 1.25;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  display: flex;
  flex-direction: column;
}
.agora-item.-agenda .agora-item__content {
  width: calc(83% - 20rem);
}

.agora-item__date {
  font-family: var(--font-general);
  font-size: 3rem;
  font-weight: var(--font-weight-regular);
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 2rem;
}
.agora-item__date small {
  display: block;
  margin: 0.56rem 0;
  text-transform: none;
  font-size: calc(13px + 0.6vw);
  line-height: 1;
  font-family: var(--font-general);
}
@media screen and (min-width: 768px) {
  .agora-item__date small {
    position: relative;
    display: inline-block;
    top: -0.17rem;
    margin: 0 0 0 0.56rem;
  }
}
.agora-item__date small svg {
  position: relative;
  top: -0.17rem;
  line-height: 1;
  fill: var(--color-black);
  width: 1.33rem;
  height: 1.33rem;
  vertical-align: middle;
}
.agora-item__date small.-buy, .agora-item__date small.-subscribe_site, .agora-item__date small.-external_sale, .agora-item__date small.-free {
  font-weight: var(--font-weight-medium);
  color: var(--color-purple);
}
.agora-item__date small.-buy svg, .agora-item__date small.-subscribe_site svg, .agora-item__date small.-external_sale svg, .agora-item__date small.-free svg {
  fill: var(--color-purple);
}
.agora-item.-agenda .agora-item__date {
  font-size: 2.5rem;
}

.agora-item__title {
  margin: 1rem 0 0 0;
  font-family: var(--font-general);
  font-size: 4rem;
  line-height: 1;
  font-weight: var(--font-weight-medium);
}
.agora-item.-agenda .agora-item__title {
  font-size: 3.5rem;
}

.agora-item__timing {
  margin: 1rem 0 0 0;
  font-family: var(--font-general);
  font-size: 3rem;
  line-height: 1;
  font-weight: var(--font-weight-regular);
}
.agora-item.-agenda .agora-item__timing {
  font-size: 2.5rem;
}

.agora-item__provider {
  max-width: 20%;
  text-align: right;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.72rem;
}

.agora-item__inner > img {
  max-width: 10rem;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.agora-header {
  padding: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.agora-header__title {
  font-family: var(--font-rebellious);
  font-size: 10rem;
  letter-spacing: -0.4rem;
  margin: 0;
}

.agora-header__info {
  font-size: 3rem;
}

.agore-item__button {
  position: absolute;
  top: 50%;
  right: 0;
  border-radius: 10rem;
  border: 0.2rem solid var(--color-purple);
  width: 30rem;
  text-align: center;
  font-size: 3rem;
  padding: 1rem;
  transform: translateY(-50%);
  box-sizing: border-box;
}
.agore-item__button.-soldout {
  background-color: #ad8e9e;
  border-color: #ad8e9e;
  color: var(--color-general);
}
.agora-item.-agenda .agore-item__button {
  font-size: 2.2rem;
  width: 20rem;
}

.carrousel__wrapper {
  position: relative;
}

.concert__carrousel {
  width: 100%;
  height: 100%;
}

.carrousel {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 1s ease-out;
  opacity: 1;
}
.carrousel.-hidden {
  opacity: 0;
  overflow: hidden;
  z-index: 2;
}
.carrousel.-justannounced::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: auto 100%;
  background-repeat: no-repeat;
  z-index: 10;
}
.carrousel.-justannounced.-family {
  background-color: var(--color-family);
}
.carrousel.-justannounced.-family::after {
  background-image: url("../../images/justannounced-family.gif");
}
.carrousel.-justannounced.-fun {
  background-color: var(--color-fun);
}
.carrousel.-justannounced.-fun::after {
  background-image: url("../../images/justannounced-fun.gif");
}
.carrousel.-justannounced.-rebellious {
  background-color: red;
}
.carrousel.-justannounced.-rebellious::after {
  background-image: url("../../images/justannounced-rebellious.gif");
}
.carrousel.-justannounced.-well-informed {
  background-color: var(--color-well-informed);
}
.carrousel.-justannounced.-well-informed::after {
  background-image: url("../../images/justannounced-wellinformed.gif");
}

.carrousel__cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
}
.carrousel__cover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(25deg, rgba(33, 32, 30, 0.8), rgba(33, 32, 30, 0.5), rgba(33, 32, 30, 0), rgba(33, 32, 30, 0.3));
}
.carrousel__cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carrousel.-justannounced .carrousel__cover {
  width: 100%;
  height: 56%;
  top: 0;
  right: 0;
  left: auto;
  bottom: auto;
}
@media (orientation: landscape) {
  .carrousel.-justannounced .carrousel__cover {
    width: 92%;
    height: 78%;
  }
}

.carrousel__content {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  padding: 5%;
  left: 0;
  bottom: 0;
  font-size: calc(18px + 2vw);
  line-height: 1.25;
  color: var(--color-white);
  text-shadow: 0 0 36px rgba(17, 17, 17, 0.3);
  font-family: var(--font-general);
}
.carrousel__content p {
  margin-top: 0;
}
.carrousel.-justannounced .carrousel__content {
  z-index: 3;
  padding: 0;
  height: 39%;
}
@media (orientation: landscape) {
  .carrousel.-justannounced .carrousel__content {
    height: calc(22% + 11rem);
  }
}

.concert__date {
  color: var(--color-white);
  font-family: var(--font-general);
  font-size: calc(24px + 2vw);
  font-weight: var(--font-weight-regular);
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
}
.concert__date small {
  display: block;
  margin: 0.56rem 0;
  text-transform: none;
  font-size: calc(22px + 1vw);
  line-height: 1;
  font-family: var(--font-general);
}
@media screen and (min-width: 768px) {
  .concert__date small {
    position: relative;
    margin: 0;
    display: inline-block;
    top: -0.56rem;
    margin-left: 0.56rem;
  }
}
.concert__date small svg {
  line-height: 1;
  fill: var(--color-white);
  width: 2.28rem;
  height: 2.28rem;
  vertical-align: middle;
}
.carrousel.-justannounced .concert__date {
  font-size: 5rem;
  margin: 0;
  padding: 2rem 5rem;
  color: var(--color-purple);
  background-color: var(--color-purple);
  display: inline-block;
  height: 11rem;
  box-sizing: border-box;
}
@media (orientation: landscape) {
  .carrousel.-justannounced .concert__date {
    text-align: center;
    font-size: 7rem;
    line-height: 7rem;
    margin-left: 17.5rem;
  }
}
@media (orientation: landscape) {
  .carrousel.-fun .concert__date {
    color: var(--color-fun);
  }
}
@media (orientation: landscape) {
  .carrousel.-family .concert__date {
    color: var(--color-family);
  }
}
@media (orientation: landscape) {
  .carrousel.-rebellious .concert__date {
    color: red;
  }
}
@media (orientation: landscape) {
  .carrousel.-well-informed .concert__date {
    color: var(--color-well-informed);
  }
}

.concert__title {
  margin: 2.5rem 0 0;
  color: var(--color-white);
  font-family: var(--font-general);
  font-size: calc(29px + 6vw);
  line-height: 1;
  font-weight: var(--font-weight-medium);
}
.carrousel.-justannounced .concert__title {
  color: var(--color-purple);
  font-weight: var(--font-weight-regular);
  font-size: 8rem;
  margin: 3rem 0 0 0;
  padding: 0;
  overflow: hidden;
  display: flex;
}
.carrousel.-justannounced .concert__title span {
  display: none;
}
.carrousel.-justannounced .concert__title span:first-child {
  display: inline-block;
}
@media (orientation: landscape) {
  .carrousel.-justannounced .concert__title span {
    display: inline-block;
  }
}
@media (orientation: landscape) {
  .carrousel.-justannounced .concert__title {
    margin: 0;
    font-size: 10rem;
    transform: translate(0, 3rem);
  }
}
.concert__title.-fun, .carrousel.-fun .concert__title {
  font-family: var(--font-fun);
  letter-spacing: 0.05rem;
}
.concert__title.-family, .carrousel.-family .concert__title {
  font-family: var(--font-family);
  letter-spacing: -0.3rem;
}
.concert__title.-rebellious, .carrousel.-rebellious .concert__title {
  font-family: var(--font-rebellious);
  letter-spacing: -0.4rem;
}
.concert__title.-well-informed, .carrousel.-well-informed .concert__title {
  font-family: var(--font-well-informed);
}

.concert__title__inner {
  padding: 0 5rem;
}
@media (orientation: landscape) {
  .concert__title__inner span {
    white-space: nowrap;
  }
}
@media (orientation: landscape) {
  .concert__title__inner {
    padding: 0 10rem 0 0;
    display: flex;
    line-height: 17rem;
    vertical-align: middle;
    animation-name: marquee;
    animation-duration: 120s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 0.1s;
    animation-fill-mode: both;
    gap: 10rem;
    white-space: nowrap;
    transform: translateX(0);
  }
  .concert__title__inner[data-speed="1"] {
    animation-duration: 50s;
  }
  .concert__title__inner[data-speed="2"] {
    animation-duration: 60s;
  }
  .concert__title__inner[data-speed="3"], .concert__title__inner[data-speed="4"], .concert__title__inner[data-speed="5"], .concert__title__inner[data-speed="6"] {
    animation-duration: 70s;
  }
}

.concert__timing {
  margin: 0;
  color: var(--color-white);
  font-family: var(--font-general);
  font-size: calc(24px + 2vw);
  line-height: 1;
  font-weight: var(--font-weight-regular);
}
@media screen and (min-width: 768px) {
  .concert__timing {
    line-height: 1.15;
  }
}

.concert__slogan {
  margin: 0;
  color: var(--color-white);
  padding-top: 1.11rem;
  width: 70%;
}
@media screen and (min-width: 768px) {
  .concert__slogan {
    padding-top: 2.22rem;
  }
}

.concert__button {
  position: absolute;
  bottom: 6rem;
  right: 6rem;
  border-radius: 10rem;
  border: 0.5rem solid var(--color-white);
  width: 25%;
  text-align: center;
  font-size: 5rem;
  padding: 1rem;
}

.concert__aside {
  margin: 0;
  padding-top: 1.11rem;
}
@media screen and (min-width: 768px) {
  .concert__aside {
    padding-top: 2.22rem;
  }
}
.concert__aside > img {
  max-width: 10rem;
  height: auto;
  object-fit: cover;
}

.carrousel__video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: none;
}
@media (orientation: portrait) {
  .carrousel__video.-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .carrousel__video.-landscape {
    display: block;
  }
}
.carrousel__video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top left;
}

.carrousel__footer {
  position: absolute;
  bottom: 5rem;
  left: 5rem;
  right: 5rem;
  color: var(--color-purple);
  justify-content: space-between;
  display: flex;
}
@media (orientation: landscape) {
  .carrousel__footer {
    display: none;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.carrousel__title {
  font-size: 8rem;
  padding: 2rem 5rem;
  color: var(--color-purple);
  background-color: var(--color-purple);
  position: absolute;
  line-height: 1.25;
  z-index: 12;
  top: 6rem;
  left: 0;
  width: 65rem;
  box-sizing: border-box;
}
.carrousel.-justannounced.-family .carrousel__title {
  font-family: var(--font-family);
  color: var(--color-family);
  font-size: 9rem;
  line-height: 1;
}
.carrousel.-justannounced.-fun .carrousel__title {
  font-family: var(--font-fun);
  color: var(--color-fun);
  letter-spacing: 0.05rem;
}
.carrousel.-justannounced.-rebellious .carrousel__title {
  font-family: var(--font-rebellious);
  letter-spacing: -0.4rem;
  color: red;
  font-size: 9rem;
  line-height: 1;
}
.carrousel.-justannounced.-well-informed .carrousel__title {
  font-family: var(--font-well-informed);
  color: var(--color-well-informed);
  font-size: 11rem;
  line-height: 1;
}

.pagination-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  position: fixed;
  bottom: 4rem;
  left: 0;
  width: 100%;
}

.pagination-list__item {
  display: inline-block;
  border-radius: 50%;
  background: var(--color-purple);
  width: 1rem;
  height: 1rem;
  margin: 0.5rem;
  font-size: 0;
  opacity: 0.25;
  pointer-events: none;
}
.pagination-list__item.-active {
  opacity: 1;
}

.schedule {
  background-color: var(--color-purple);
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 10vh 0 0 0;
  box-sizing: border-box;
}
.schedule::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.schedule.-family::after {
  background-image: url("../../images/schedule-family.png");
  background-size: cover;
}
.schedule.-fun::after {
  background-image: url("../../images/schedule-fun.png");
  background-size: cover;
}
.schedule.-rebellious::after {
  background-image: url("../../images/schedule-rebellious.png");
  background-size: cover;
}
.schedule.-well-informed::after {
  background-image: url("../../images/schedule-wellinformed.png");
  background-size: cover;
}
.schedule .carrousel {
  background: var(--color-purple);
}

.schedule__masthead {
  text-align: center;
  padding: 2.5rem;
  height: 10%;
  box-sizing: border-box;
  overflow: hidden;
}
.schedule__masthead p {
  margin-top: 0;
}
.schedule__masthead h1 {
  margin: 0;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  font-size: 7rem;
  color: var(--color-purple);
  text-align: center;
  position: relative;
  display: inline-block;
}
.schedule.-family .schedule__masthead h1 {
  font-family: var(--font-family);
  color: var(--color-family);
  letter-spacing: -0.3rem;
}
.schedule.-fun .schedule__masthead h1 {
  font-family: var(--font-fun);
  color: var(--color-fun);
  letter-spacing: 0.05rem;
}
.schedule.-rebellious .schedule__masthead h1 {
  font-family: var(--font-rebellious);
  color: var(--color-rebellious);
  letter-spacing: -0.4rem;
}
.schedule.-well-informed .schedule__masthead h1 {
  font-family: var(--font-well-informed);
  color: var(--color-well-informed);
}

.schedule__carrousel {
  height: 99%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.schedule__carrousel .carrousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.schedule-item {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  width: 74%;
  margin: 0 0 0 20rem;
  padding: 2.5rem 0;
  overflow: hidden;
  border-bottom: 0.1rem dotted var(--color-border-dark);
}
.schedule.-family .schedule-item {
  border-bottom: 0.7rem dotted var(--color-family);
}
.schedule.-fun .schedule-item {
  border-bottom: 0.7rem dotted var(--color-fun);
}
.schedule.-rebellious .schedule-item {
  border-bottom: 0.7rem dotted var(--color-rebellious);
}
.schedule.-well-informed .schedule-item {
  border-bottom: 0.7rem dotted var(--color-well-informed);
}
.schedule-item:last-child {
  border: 0;
}

.schedule-item__content {
  float: left;
  width: 74%;
  box-sizing: border-box;
  font-size: 0;
  line-height: 1;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.schedule-item__title {
  margin: 0;
  display: inline;
  font-family: var(--font-general);
  font-size: 5rem;
  line-height: 1;
  font-weight: var(--font-weight-regular);
}
.schedule.-family .schedule-item__title {
  color: var(--color-family);
}
.schedule.-fun .schedule-item__title {
  color: var(--color-fun);
}
.schedule.-rebellious .schedule-item__title {
  color: var(--color-rebellious);
}
.schedule.-well-informed .schedule-item__title {
  color: var(--color-well-informed);
}

.schedule-item__time {
  float: left;
  width: 15%;
  line-height: 1;
  text-align: left;
  box-sizing: border-box;
  font-family: var(--font-general);
  font-size: 5rem;
  font-weight: var(--font-weight-regular);
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.schedule.-family .schedule-item__time {
  color: var(--color-family);
}
.schedule.-fun .schedule-item__time {
  color: var(--color-fun);
}
.schedule.-rebellious .schedule-item__time {
  color: var(--color-rebellious);
}
.schedule.-well-informed .schedule-item__time {
  color: var(--color-well-informed);
}

.series {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.series__masthead {
  text-align: center;
  padding: 1.44rem;
  height: 25%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.series__masthead img {
  margin: 0 auto;
  width: auto;
  height: 95%;
}

.series__carrousel {
  height: 75%;
}

.series-item {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 25%;
  overflow: hidden;
  border-bottom: 0.1rem dotted var(--color-border-dark);
}
.series-item:last-child {
  border: 0;
}
.series-item.-h-1, .series-item.-h-2, .series-item.-h-3 {
  height: 33.3333%;
}

.series-item__content {
  float: left;
  width: 75%;
  height: 100%;
  padding: 0.56rem;
  box-sizing: border-box;
  font-size: 0;
  line-height: 1.25;
  font-family: var(--font-general);
  font-weight: var(--font-weight-regular);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .series-item__content {
    padding: 0.72rem 1.44rem;
  }
}

.series-item__date {
  font-family: var(--font-general);
  font-size: calc(14px + 1vw);
  font-weight: var(--font-weight-regular);
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 0.39rem 0;
}

.series-item__title {
  margin: 0;
  display: inline;
  font-family: var(--font-general);
  font-size: calc(21px + 1.5vw);
  line-height: 1;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .series-item__title {
    line-height: 1.15;
  }
}

.series-item__timing {
  margin: 0;
  display: inline;
  font-family: var(--font-general);
  font-size: calc(14px + 1vw);
  line-height: 1;
  font-weight: var(--font-weight-regular);
}
@media screen and (min-width: 768px) {
  .series-item__timing {
    line-height: 1.15;
  }
}

.series-item__tickets {
  float: left;
  width: 25%;
  height: 100%;
  padding: 0.56rem;
  text-align: right;
  box-sizing: border-box;
  font-size: calc(8px + 0.7vw);
  color: var(--color-purple);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .series-item__tickets {
    padding: 0.72rem 1.44rem;
  }
}

.series-item__price {
  font-weight: var(--font-weight-medium);
  color: var(--color-purple);
  font-size: calc(11px + 1vw);
  font-family: var(--font-general);
  display: block;
  margin-bottom: 0.39rem;
}
.series-item__price svg {
  width: 1.17rem;
  height: 1.17rem;
  line-height: 1;
  vertical-align: middle;
  position: relative;
  top: -0.16rem;
  fill: var(--color-purple);
}
.series-item__price.-sold_out, .series-item__price.-full, .series-item__price.-cancelled {
  color: var(--color-black);
  font-weight: var(--font-weight-regular);
}
.series-item__price.-sold_out svg, .series-item__price.-full svg, .series-item__price.-cancelled svg {
  fill: var(--color-black);
}

a {
  color: var(--default-text-color);
}

hr {
  position: relative;
  margin-block: 5rem !important;
  padding-block: 1rem;
  border: none;
}
hr::after {
  content: "";
  position: absolute;
  inset-block-start: 50%;
  inset-inline: 0;
  transform: translateY(-50%);
  display: block;
  inline-size: 100%;
  block-size: 0.1rem;
  background-color: var(--color-title);
}

figcaption {
  margin-block-start: 0.8rem;
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--color-purple);
}

@media (--screen-from-sm) {
  figcaption {
    margin-block-start: 1rem;
    font-size: 1.2rem;
  }
}
figure {
  margin: 0;
  padding: 0;
}

figure.image {
  margin-block: 1.5rem 3rem;
}
figure.image img {
  display: block;
  inline-size: 100%;
}

img {
  inline-size: 100%;
  block-size: auto;
}

@media (--screen-from-sm) {
  figure.image {
    margin-block: 2rem 4rem;
  }
}
@media (--screen-from-xxl) {
  figure.image {
    margin-block: 3rem 6rem;
  }
}
figure.table {
  margin-block: 1.5rem 3rem;
}

:where(table:not([class])), .table {
  overflow: hidden;
  table-layout: fixed;
  border-collapse: collapse;
  inline-size: 100%;
  margin-block: 1rem 3.2rem;
  font-size: 1.4rem;
  line-height: 1.7;
  color: var(--color-purple);
  border-radius: 0.4rem 0.4rem 0 0;
}
:where(table:not([class])) td, :where(table:not([class])) th, .table td, .table th {
  padding: 0.8rem 1.6rem;
  vertical-align: top;
  border: 0.1rem solid var(--color-border);
}
:where(table:not([class])) th, .table th {
  font-size: 1.1rem;
  font-weight: var(--font-weight-semi-bold);
  line-height: 1.6;
  color: var(--color-purple-hover);
  text-align: start;
  text-transform: uppercase;
  background-color: var(--color-general);
}

@media (--screen-from-sm) {
  figure.table {
    margin-block: 2rem 4rem;
  }
}
@media (--screen-from-xxl) {
  figure.table {
    margin-block: 3rem 6rem;
  }
}
.title-1, h1:not([class]), .title-2, h2:not([class]), .title-3, h3:not([class]), .title-4, h4:not([class]), .title-5, h5:not([class]), .title-6, h6:not([class]) {
  margin-block: 0.75rem 1rem;
  font-weight: var(--font-weight-medium);
  line-height: 1.2;
  color: var(--color-purple);
}
.title-1 strong, h1:not([class]) strong, [data-block-type=form] .title-1, [data-block-type=form] h1:not([class]), .title-2 strong, h2:not([class]) strong, [data-block-type=form] .title-2, [data-block-type=form] h2:not([class]), .title-3 strong, h3:not([class]) strong, [data-block-type=form] .title-3, [data-block-type=form] h3:not([class]), .title-4 strong, h4:not([class]) strong, [data-block-type=form] .title-4, [data-block-type=form] h4:not([class]), .title-5 strong, h5:not([class]) strong, [data-block-type=form] .title-5, [data-block-type=form] h5:not([class]), .title-6 strong, h6:not([class]) strong, [data-block-type=form] .title-6, [data-block-type=form] h6:not([class]) {
  font-weight: var(--font-weight-semi-bold);
}

.title-1, h1:not([class]) {
  font-size: 3.3rem;
}

.title-2, h2:not([class]) {
  font-size: 2.7rem;
}

.title-3, h3:not([class]) {
  font-size: 2.1rem;
}

.title-4, h4:not([class]) {
  font-size: 1.9rem;
}

.title-5, h5:not([class]) {
  font-size: 1.8rem;
}

.title-6, h6:not([class]) {
  font-size: 1.6rem;
}

@media (--screen-from-sm) {
  .title-1, h1:not([class]) {
    font-size: 4.6rem;
  }
  .title-2, h2:not([class]) {
    font-size: 3.2rem;
  }
  .title-3, h3:not([class]) {
    font-size: 2.4rem;
  }
  .title-4, h4:not([class]) {
    font-size: 2.2rem;
  }
  .title-5, h5:not([class]) {
    font-size: 2rem;
  }
  .title-6, h6:not([class]) {
    font-size: 1.8rem;
  }
}
p {
  max-inline-size: 75rem;
  margin-block: 1rem 2.4rem;
}

svg * {
  fill: currentcolor;
}

.svg-icon {
  display: inline-block;
  inline-size: 100%;
  block-size: 100%;
  fill: currentcolor;
}