.artist__list {
    border-top: .1rem dotted var(--color-border-dark);
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        border-bottom: .1rem dotted var(--color-border-dark);
    }

     &.-search {
         margin: 4rem 0;
     }
}

.artist {
    padding: 2rem 0 1rem;
    display: flex;
    align-items: center;
    position: relative;
    flex-wrap: wrap;

    @media (--screen-large) {
        padding: 2rem 0;
    }
}

.artist__favorite {
    background: var(--color-black);
    width: 4rem;
    height: 4rem;
    display: block;
    border-radius: 50%;
    text-align: center;
    line-height: 4rem;
    margin-right: 1rem;
    flex: 0 0 4rem;
    position: relative;
    top: -.1rem;
    cursor: pointer;
    outline: none;
    border: none;

    @media (--screen-large) {
        width: 5rem;
        height: 5rem;
        line-height: 5rem;
        flex: 0 0 5rem;
        margin-right: 2rem;
    }

    .event-detail__content.-family & {
        &.-favorite .icon.-heart::before, &:hover .icon.-heart::before, &:focus .icon.-heart::before {
            background: var(--color-family);
        }
    }

    .event-detail__content.-fun & {
        &.-favorite .icon.-heart::before, &:hover .icon.-heart::before, &:focus .icon.-heart::before {
            background: var(--color-fun);
        }
    }

    .event-detail__content.-rebellious & {
        &.-favorite .icon.-heart::before, &:hover .icon.-heart::before, &:focus .icon.-heart::before {
            background: var(--color-rebellious);
        }
    }

    .event-detail__content.-well-informed & {
        &.-favorite .icon.-heart::before, &:hover .icon.-heart::before, &:focus .icon.-heart::before {
            background: var(--color-well-informed);
        }
    }

    .event-detail__content.-general & {
        &.-favorite .icon.-heart::before, &:hover .icon.-heart::before, &:focus .icon.-heart::before {
            background: var(--color-general);
        }
    }

    .artist__list.-search & {
        background: var(--color-purple);
        transition: background-color .3s ease;

        &:hover,
        &:focus {
            background: var(--color-general);
        }
    }

    .artist-toggle & {
        transition: opacity .3s ease;

        &:hover,
        &:focus {
            opacity: .6;
        }
    }
}

.artist__name {
    font-size: 1.7rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);
    flex: 1 1;

    @media (--screen-large) {
        font-size: 2.8rem;
    }
}

.artist__socials {
    padding: 0;
    list-style: none;
    background: var(--color-white);
    margin: 0 0 0 5rem;
    flex-basis: 100%;

    @media (--screen-large) {
        margin: 0 0 0 7rem;
    }

    @media (--screen-full) {
        position: absolute;
        right: 0;
        border-left: .1rem dotted var(--color-border-dark);
        margin: 0 0 0 2rem;
    }

    li {
        border: 0;
        display: inline-block;
        padding: 1rem 2rem 1rem 0;

        @media (--screen-large) {
            padding: 2rem 3rem 0 0;
        }

        @media (--screen-full) {
            padding: 2rem 3rem 2rem 0;
        }

        &:first-child {
            padding-left: 0;

            @media (--screen-full) {
                padding-left: 3rem;
            }
        }
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -6.1rem;
        bottom: 0;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
        width: 6rem;
        height: 100%;
    }
}

.artist__socials__item {
    transition: opacity .3s ease;

    &:hover,
    &:focus {
        opacity: .6;
    }
}
