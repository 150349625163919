.card {
    flex: 0 1 45rem;
    text-decoration: none !important;
    box-sizing: border-box;

    [data-theme='fun'] &,
    [data-theme='family'] &,
    [data-theme='rebellious'] &,
    [data-theme='well-informed'] & {
        padding: 3rem;
        background-color: var(--color-white);
    }

    &:not([href]), &[href=""], &[href="#"] {
        pointer-events: none;

        // only in pagebuilder
        [data-gjs-type='wrapper'] & {
            pointer-events: auto;
        }
    }
}

.card__figure {
    transition: opacity .3s ease;

    a.card:--focus & {
        opacity: .55;
    }
}

.card__info {
    padding: 1.6rem 0 0 0;
    text-align: center;
    transition: opacity .3s ease;

    a.card:--focus & {
        opacity: .55;
    }
}

.card__title, h3.card__title {
    margin: 0;
    font-weight: var(--font-weight-bold);
}

.card__description {
    margin-block: 1.6rem 0;
}

.card__button {
    margin-block: 3.2rem 0;

    [data-block-type='child-pages'] & {
        display: none;
    }
}

@media (--screen-from-md) {
    // Show card smaller
    .card {
        flex: 0 1 36.7rem;
    }
}
